import { Box, Image, Input, InputProps } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { handleRouterUrl } from 'utils/globals'

export const SearchInput: React.FC<InputProps> = (props) => {
  const router = useRouter()

  const [searchStr, setSearchStr] = useState('')

  useEffect(() => {
    const searchQueryStr = router.query?.search
    if (searchQueryStr && typeof searchQueryStr === 'string') {
      setSearchStr(searchQueryStr)
    }
  }, [router])

  const handleSearchInputChange = (searchStr: string) => {
    const queryKey = 'search'
    const removeQueryKeys: string[] = searchStr === '' ? ['search'] : []
    handleRouterUrl({
      router,
      queryObj: { [queryKey]: searchStr },
      removeQueryKeys,
    })
  }

  return (
    <Box pos="relative" flex={1} maxW={{ base: '300px', xl: '373px' }} {...props}>
      <Image
        src="/images/icons/search.svg"
        alt="search icon"
        pos="absolute"
        top="50%"
        right="17px"
        zIndex={10}
        transform="translateY(-50%)"
        onClick={() => handleSearchInputChange(searchStr)}
        cursor="pointer"
      />
      <Input
        h="33px"
        pl={3}
        pr="42px"
        fontSize="md"
        lineHeight="16px"
        placeholder="Search by Item Name"
        color="black"
        bg="white"
        borderRadius="none"
        _placeholder={{ color: 'blackAlpha.200' }}
        value={searchStr}
        onChange={(e) => {
          const { value } = e.currentTarget
          const alphanumeric = /^[a-zA-Z0-9\s]*$/
          if (!alphanumeric.test(value)) return
          setSearchStr(value)
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleSearchInputChange(searchStr)
          }
        }}
      />
    </Box>
  )
}
