/* eslint-disable react/display-name */
import { forwardRef } from 'react'
import { Button, ButtonProps } from '../Button'

interface IconButtonProps extends ButtonProps {
  icon: React.ReactNode
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ label, children, icon, ...props }, ref) => (
    <Button
      _focus={{ boxShadow: 'none' }}
      type="button"
      size="auto"
      variant="none"
      ref={ref}
      {...props}
    >
      {icon}
      {label || children}
    </Button>
  )
)
