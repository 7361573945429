import { PremiumCurrencyBalance } from './v2/PremiumCurrencyBalance'
import { UserToken } from './v2/UserToken'

type UserType = 'end-user' | 'primary-seller'

export type UserStatus = 'active' | 'inactive' | 'suspended'

export type UserStatusReason =
  | 'chargeback_detected'
  | 'country_of_residence'
  | 'forbidden_ip'
  | 'generic'
  | undefined
  | null

// Using enum to match back-end code
export enum KycStatus {
  NOT_STARTED = 'not-started',
  INIT = 'init',
  PENDING = 'pending',
  RETRY = 'retry',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum VIPTier {
  NON_VIP = 'non_vip',
  EPIC = 'epic',
  COMMON = 'common',
  TRANSCENDENT = 'transcendent',
  UNCOMMON = 'uncommon',
  UNIQUE = 'unique',
  RARE = 'rare',
  LEGENDARY = 'legendary',
  MYTHIC = 'mythic',
  EXOTIC = 'exotic',
  EXALTED = 'exalted',
}

export interface UsersVIPTierResponse {
  tier: VIPTier
  tierName: string
  discount: number
}

export interface User {
  id: string
  email: string
  username: string
  type: UserType
  status: UserStatus
  status_reason: UserStatusReason
  createdAt: string
  updatedAt: string
  balance: number
  withdrawableBalance: number
  premiumCurrencyBalances: PremiumCurrencyBalance[]
  userTokens: UserToken[]
  phoneNumber: string
  phoneNumberStatus: 'validated' | 'not-validated' | null
  totpVerified: 'verified' | 'unverified' | null
  recoveryCodesGenerated: boolean
  pendingTotalSettlement: number
  pendingTotalSettlementOpenloot: number
  on_boarding_country_code: string
  hasCountryAndDob: boolean
  vipTier: UsersVIPTierResponse
  kyc: {
    status: KycStatus
    sessionUrl: string | null
    verificationStatus: string | null
    identityVerificationResult: string | null
    rejectionReason: string | null
    firstName: string | null
    lastName: string | null
  }
  permissions: string[]
  roles: string[]
  isAdminLogin?: boolean
}

export interface LinkedAccount {
  provider: string
  externalAccount: string | null
  username: string | null
  oldestCantBeUnlinked: boolean | null
}
