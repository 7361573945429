import { RefObject, useEffect, useRef } from 'react'

export const useClickOutside = (
  onClickOutside: () => void,
  blockRefs?: RefObject<HTMLElement>[]
) => {
  const ref = useRef<HTMLInputElement>(null)
  const handleClickOutside = (event: MouseEvent) => {
    const isBlockedRefClicked = blockRefs?.some(
      (ref) => ref.current && ref.current.contains(event.target as Node)
    )

    if (
      !isBlockedRefClicked &&
      ref.current &&
      event.target &&
      !ref.current.contains(event.target as Node)
    ) {
      onClickOutside()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return [ref]
}
