import { ComponentStyleConfig } from '@chakra-ui/react'

const Text: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: 'body',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  sizes: {
    inherit: {
      fontSize: 'inherit',
      lineHeight: 'inherit',
    },
    xs: {
      // 12px
      fontSize: '0.75rem',
      // 16px
      lineHeight: '1rem',
    },
    sm: {
      // 14px
      fontSize: '0.875rem',
      // 16px
      lineHeight: '1rem',
    },
    md: {
      // 18px
      fontSize: '1.125rem',
      // 22px
      lineHeight: '1.375rem',
    },
    lg: {
      // 20px
      fontSize: '1.25rem',
      // 25px
      lineHeight: '1.5625rem',
    },
    lg2: {
      // 20px
      fontSize: '1.375rem',
      // 25px
      lineHeight: '1.5625rem',
    },
    xl: {
      // 24px, 26px, 30px
      fontSize: ['1.5rem', null, null, '1.625rem', null, '1.875rem'],
      lineHeight: ['1.8125rem', null, null, '2rem', null, '2.3125rem'],
    },
    xxl: {
      // 36px
      fontSize: '2.25rem',
      lineHeight: '2rem',
    },
    xxxl: {
      // 50px
      fontSize: '3.125rem',
      lineHeight: '3.43rem',
    },
  },
}

export default Text
