import { Center, Flex, Image, Text, VStack } from '@chakra-ui/react'
import { Accordian, Button, DropdownButtonMenu } from 'components/atoms'
import { useFilterMenu } from 'hooks/useFilterMenu'
import { GAMES_HIDDEN } from '../../../constants'

const gameFilterImgs: Record<
  string,
  {
    imgUrl: string
    logoUrl: string
  }
> = {
  '56a149cf-f146-487a-8a1c-58dc9ff3a15c': {
    imgUrl: '/images/games/big-time/filters.png',
    logoUrl: '/images/games/big-time/filters-logo.svg',
  },
  '8c99b874-677f-4086-ae45-880b6be38618': {
    imgUrl: '/images/games/war-park.png',
    logoUrl: '/images/games/war-park-logo.svg',
  },
  '3af107fb-59f4-4859-b1f6-60a46b6a52bf': {
    imgUrl: '/images/games/boss-fighters/filters.png',
    logoUrl: '/images/games/boss-fighters/filters-logo.svg',
  },
  '65b9a00c-d4f1-4ae2-b735-23904bcb2249': {
    imgUrl: '/images/games/worldshards/filters.png',
    logoUrl: '/images/games/worldshards/filters-logo.svg',
  },
  '8c6bfff6-98fa-435d-bd18-c58a8ef02abe': {
    imgUrl: '/images/games/reign-of-terror/filters.png',
    logoUrl: '/images/games/reign-of-terror/filters-logo.svg',
  },
  'f6395b4c-5849-47f0-b4ad-1c202f929fae': {
    imgUrl: '/images/games/shatterpoint/filters.png',
    logoUrl: '/images/games/shatterpoint/filters-logo.svg',
  },
  '2a05cde9-39fd-4e50-aaba-5777cbc8cd30': {
    imgUrl: '/images/games/desolation/filters.png',
    logoUrl: '/images/games/desolation/filters-logo.svg',
  },
}

interface GameFilterProps {
  gameFilterType: 'single-select' | 'single-thumbnail-select' | 'multi-thumbnails'
  showAllGames: boolean
}

export const GameFilter: React.FC<GameFilterProps> = ({ gameFilterType, showAllGames }) => {
  const { gameFilter, handleGameFilterChange } = useFilterMenu()

  const filteredGameFilter = showAllGames
    ? gameFilter
    : gameFilter.filter((game) => !GAMES_HIDDEN.includes(game.id))

  if (gameFilterType === 'single-select')
    return (
      <Flex gridGap="12px" direction="column">
        <Text fontFamily="Montserrat" fontSize="16px" fontWeight="700" lineHeight="24px">
          Games
        </Text>
        <DropdownButtonMenu
          label="Select Game"
          options={filteredGameFilter.map(({ id, name, isSelected }) => ({
            name,
            value: id,
            isSelected,
          }))}
          onChange={(id) => handleGameFilterChange(id as string, true, true)}
        />
      </Flex>
    )

  return (
    <Accordian label="Game" accordionPanelProps={{ p: 0 }}>
      <VStack mt="34px" spacing={6}>
        {filteredGameFilter.map(({ id, isSelected, name }) => (
          <Button
            key={id}
            variant="none"
            size="full"
            h="128px"
            pos="relative"
            rounded="md"
            overflow="hidden"
            border="2px"
            borderColor={isSelected ? 'whiteAlpha.900' : 'whiteAlpha.200'}
            shadow="gameCard"
            cursor="pointer"
            onClick={() =>
              handleGameFilterChange(id, !isSelected, gameFilterType === 'single-thumbnail-select')
            }
            _hover={{
              '.game-filter__img': !isSelected
                ? {
                    width: '110%',
                    height: '110%',
                  }
                : {},
            }}
          >
            <Image
              src={gameFilterImgs[id]?.imgUrl}
              alt="game"
              pos="absolute"
              w="full"
              h="full"
              objectFit="cover"
              objectPosition={{ base: '50% 70%', md: 'center' }}
              maxW="none"
              className="game-filter__img"
              transition="all .5s"
            />
            <Center
              pos="absolute"
              inset={0}
              bg={isSelected ? 'blackAlpha.200' : 'blackAlpha.800'}
              transition="all .5s"
              _hover={{
                bg: 'blackAlpha.200',
              }}
            >
              <VStack spacing={1}>
                <Image
                  maxW="none"
                  src={gameFilterImgs[id]?.logoUrl}
                  maxWidth="150px"
                  maxHeight="65px"
                  alt={`${name} logo`}
                />
                <Image
                  src="/images/icons/check-rounded-gray.svg"
                  alt="check rounded icon"
                  display={isSelected ? 'block' : 'none'}
                  position="absolute"
                  bottom="14px"
                />
              </VStack>
            </Center>
          </Button>
        ))}
      </VStack>
    </Accordian>
  )
}
