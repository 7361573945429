import { VStack } from '@chakra-ui/react'
import { Checkbox, RarityBadge, Accordian } from 'components/atoms'
import { useFilterMenu } from 'hooks/useFilterMenu'

export const RarityFilter: React.FC = () => {
  const { rarityFilter, handleRarityFilterChange } = useFilterMenu()

  return (
    <Accordian label="Rarity" accordionPanelProps={{ px: 0, pt: 3, pb: 0 }}>
      <VStack spacing={3} alignItems="stretch">
        {rarityFilter.map(({ title, id, isSelected }) => (
          <Checkbox
            key={id}
            type="label-check"
            isChecked={isSelected}
            onChange={(e) => handleRarityFilterChange(id, e.target.checked)}
            sx={{
              '.rarity-badge': {
                bg: isSelected ? `rarity.hover.${id}` : 'none',
              },
            }}
            _hover={{
              '.rarity-badge': {
                bg: `rarity.hover.${id}`,
              },
            }}
          >
            <RarityBadge size="lg" rarity={id} title={title} className="rarity-badge" />
          </Checkbox>
        ))}
      </VStack>
    </Accordian>
  )
}
