import { Center, Flex, Text } from '@chakra-ui/react'

interface CountdownBoxProps {
  label: string
  time: number
  h?: string
  w?: string
}

export const CountdownBox = ({ label, time, h = '20px', w = '22px' }: CountdownBoxProps) => {
  const paddedTime = time < 10 ? `0${time}` : String(time)

  return (
    <Flex direction="column" alignItems="center">
      <Center borderColor="#CDCDCD4D" borderWidth={1} borderRadius="2px" h={h} w={w}>
        <Text lineHeight="14px" fontWeight={500} size="xs">
          {paddedTime}
        </Text>
      </Center>
      <Text mt="3px" color="gray.500" textAlign="center" size="xs">
        {label}
      </Text>
    </Flex>
  )
}
