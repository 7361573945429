import { Image, HStack, UnorderedList, ListItem, Text, Heading, Box } from '@chakra-ui/react'
import Link from 'next/link'

import { User } from 'types/vault/User'
import { Button, ButtonLink } from 'components/atoms'
import { useAnyFeature } from 'hooks/useFeature'
import { useLogoutMutation } from 'hooks/useLogoutMutation'
import { formatMoney } from 'lib/format'

export interface UserMenuProps extends User {
  onClose?: () => void
  px?: number | string
  navItems: {
    name: string
    url: string
  }[]
}

export const UserMenu: React.FC<UserMenuProps> = ({ onClose, username, balance, px, navItems }) => {
  const { mutate } = useLogoutMutation()
  const hasDeposit = useAnyFeature(['depositCrypto', 'depositWire'])
  const handleLogout = () => mutate()

  return (
    <Box px={px || 5} pt="13px" pos="relative" color="menu-content" zIndex="skipLink">
      {onClose && (
        <Button size="auto" variant="none" pos="absolute" top="17px" right="21px" onClick={onClose}>
          <Image src="/images/icons/close.svg" alt="close icon" />
        </Button>
      )}
      <Box textAlign="center">
        <Heading as="h4" fontSize="14px" lineHeight="14px">
          {username}
        </Heading>
        <Link href="/wallet" passHref>
          <Text
            mt="7px"
            size="lg"
            cursor="pointer"
            _hover={{
              fontWeight: 500,
            }}
          >
            {formatMoney(balance)}
          </Text>
        </Link>
        {hasDeposit && (
          <ButtonLink
            href="/wallet/deposits"
            mt="7px"
            variant="dark"
            color="white"
            size="sm"
            py="13px"
            _hover={{
              color: 'whiteAlpha.700',
            }}
          >
            Add Funds
          </ButtonLink>
        )}
      </Box>
      <UnorderedList listStyleType="none" m={0} mt={3}>
        {navItems.map(({ name, url }) => (
          <Link key={name} href={url} passHref>
            <ListItem
              py={3}
              borderTop="1px"
              borderColor="bright.default"
              cursor="pointer"
              opacity={0.9}
              _hover={{ fontWeight: 'bold' }}
              transition="all 0.2s"
            >
              <Text as="a" color="menu-content">
                {name}
              </Text>
            </ListItem>
          </Link>
        ))}
      </UnorderedList>

      <HStack justifyContent="space-between" py={3} borderTop="1px" borderColor="bright.default">
        <Button
          variant="none"
          size="auto"
          fontSize="xs"
          color="gray.500"
          onClick={handleLogout}
          _hover={{ color: 'gray.700' }}
        >
          Log Out
        </Button>
        <Button variant="none" size="auto" fontSize="xs" color="gray.500" onClick={handleLogout}>
          <Image src="/images/icons/logout.svg" alt="logout icon" />
        </Button>
      </HStack>
    </Box>
  )
}
