import { ChakraProvider } from '@chakra-ui/react'
import { Web3ReactProvider } from '@web3-react/core'
import { Layout } from 'components/templates/layout'
import * as countries from 'i18n-iso-countries'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useState } from 'react'
import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { OPENLOOT_CDN_URL, QUERY_CLIENT_CONFIG, SITE_URL } from '../constants'
import { TrackingProvider } from '../context/TrackingContext'
import '../styles/globals.css'
import '../styles/mystery-box-opening.css'
import theme, { themeV2 } from '../theme'
import { getLibrary } from '../utils/web3React'
import OpenlootNotifications from './openlootNotifications'
import ZendeskBot from './_zendesk-bot'

// TODO: Find a better way to register locale
// eslint-disable-next-line
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

if (process.env.NODE_ENV === 'development') {
  /* eslint-disable global-require */
  require('../mocks')
}

const pageImageUrl = `${OPENLOOT_CDN_URL}/open-loot/og-open-loot-metadata-2023.png`
const pageTitle = 'Open Loot NFT Marketplace'
const pageDescription = 'The #1 place to trade game NFTs'

interface FrontendPageProps {
  dehydratedState: DehydratedState
  version: string
}

function MyApp({ Component, pageProps, router }: AppProps<FrontendPageProps>) {
  const [queryClient] = useState(() => new QueryClient(QUERY_CLIENT_CONFIG))

  const isPartnerPortal = router.pathname.startsWith('/partner-portal')

  return (
    <>
      <Head>
        {/* Primary Meta Tags */}
        <meta key="meta:title" name="title" content={pageTitle} />
        <meta key="meta:description" name="description" content={pageDescription} />

        {/* Open Graph / Facebook --> */}
        <meta key="og_type" property="og:type" content="website" />
        <meta key="og_url" property="og:url" content={SITE_URL} />
        <meta key="og_description" property="og:description" content={pageDescription} />
        <meta key="og_image" property="og:image" content={pageImageUrl} />
        <meta key="og_title" property="og:title" content={pageTitle} />

        {/* Twitter */}
        <meta key="twitter_card" property="twitter:card" content="summary_large_image" />
        <meta key="twitter_url" property="twitter:url" content={SITE_URL} />
        <meta key="twitter_description" property="twitter:description" content={pageDescription} />
        <meta key="twitter_image" property="twitter:image" content={pageImageUrl} />
        <meta key="twitter_title" property="twitter:title" content={pageTitle} />
      </Head>
      <ChakraProvider resetCSS theme={isPartnerPortal ? themeV2 : theme}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <TrackingProvider>
              <Layout>
                <Web3ReactProvider getLibrary={getLibrary}>
                  <Component {...pageProps} />
                </Web3ReactProvider>
                <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                <ZendeskBot />
                <OpenlootNotifications />
              </Layout>
            </TrackingProvider>
          </Hydrate>
        </QueryClientProvider>
      </ChakraProvider>
    </>
  )
}

export default MyApp
