import { FormLabel, HStack, Input, InputProps, StackProps, Flex } from '@chakra-ui/react'
import { Span } from './Span'

interface RangeFilterProps extends StackProps {
  fromInputProps?: InputProps
  toInputProps?: InputProps
  inputIcon?: React.ReactNode
  fromPlaceholder?: string
  toPlaceholder?: string
}

export const RangeFilter: React.FC<RangeFilterProps> = ({
  fromInputProps,
  toInputProps,
  inputIcon,
  fromPlaceholder,
  toPlaceholder,
}) => (
  <HStack spacing={0} w="100%" alignItems="stretch" bg="whiteAlpha.100">
    <FormLabel m={0} borderRight="1px" borderColor="whiteAlpha.200" flex={1} px={3} py={1.5}>
      <Span color="white" fontSize="md" lineHeight={5}>
        From
      </Span>

      <Flex alignItems="center">
        {inputIcon}
        <Input
          p={0}
          fontWeight="bold"
          fontSize="md"
          lineHeight={5}
          border="none"
          type="number"
          _focus={{ ouline: 'none', border: 'none' }}
          placeholder={fromPlaceholder}
          h="unset"
          onKeyPress={(event) => {
            if (event.key === '-' || event.key === '.' || event.key === 'e') {
              event.preventDefault()
            }
          }}
          {...fromInputProps}
        />
      </Flex>
    </FormLabel>

    <FormLabel m={0} flex={1} px={3} py={1.5}>
      <Span color="white" fontSize="md" lineHeight={5}>
        to
      </Span>

      <Flex alignItems="center">
        {inputIcon}

        <Input
          p={0}
          fontWeight="bold"
          fontSize="md"
          lineHeight={5}
          border="none"
          type="number"
          _focus={{ ouline: 'none', border: 'none' }}
          placeholder={toPlaceholder}
          h="unset"
          onKeyPress={(event) => {
            if (event.key === '-' || event.key === '.' || event.key === 'e') {
              event.preventDefault()
            }
          }}
          {...toInputProps}
        />
      </Flex>
    </FormLabel>
  </HStack>
)
