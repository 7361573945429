const breakpoints = {
  xs: '30em', // 480px
  sm: '40em', // 640px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280px
  '2xl': '96em', // 1536px
  '3xl': '120rem', // 1920px
  '4xl': '160rem', // 2560px
}

export default breakpoints
