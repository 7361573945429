import { Heading, Link, Text, VStack, Box, Divider, Flex } from '@chakra-ui/react'
import Head from 'next/head'
import { formatHtmlTitle } from 'utils/globals'
import { Container } from './layout'

interface ForbiddenCountryProps {
  heading: string
  content: string
  termsRoute: string
}

export const ForbiddenCountry: React.FC<ForbiddenCountryProps> = ({
  heading,
  content,
  termsRoute,
}) => (
  <>
    <Head>
      <title key="title">{formatHtmlTitle(heading)}</title>
    </Head>
    <Container baseMaxWidth="1280px" mb="24">
      <Flex
        mx="auto"
        maxWidth="992px"
        h="420px"
        flexDirection="column"
        alignItems="center"
        bg="text-cards"
        position="relative"
        py="60px"
        px="3"
      >
        <VStack spacing="20">
          <Box />
          <VStack spacing="4" color="white" maxW="382px">
            <Heading fontWeight="700" fontSize="3xl" textAlign="center">
              {heading}
            </Heading>
            <Divider />
            <Text fontSize="md" textAlign="center">
              {content}{' '}
              <Link href={termsRoute} color="highlight.default">
                Terms & Conditions.
              </Link>
            </Text>
          </VStack>
          <Box />
        </VStack>
      </Flex>
    </Container>
  </>
)
