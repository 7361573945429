import { Box, Heading, SimpleGrid, useMediaQuery } from '@chakra-ui/react'
import { dehydrate, QueryClient } from 'react-query'
import { CardSkeleton } from 'components/atoms'
import { ItemCard } from 'components/organisms'
import { fetchOptions } from 'hooks/useOptions'
import { useSecondaryOptions } from 'hooks/useSecondaryOptions'
import { useTrackEffect } from 'hooks/useTracking'
import { QUERY_CLIENT_CONFIG } from '../../constants'

export const RandomMarketplace = () => {
  const { data, isLoading } = useSecondaryOptions({
    sort: 'price:desc',
  })
  const items = data?.pages[0].items ?? []
  const [isMobile] = useMediaQuery('(max-width: 820px)')
  const spaces = items.slice(0, isMobile ? 6 : 5).sort((a, b) => a.minPrice - b.minPrice)
  useTrackEffect('rdr_home')

  return (
    <Box as="section">
      <Heading as="h3" size="xl" textShadow="heroText" textAlign="center" mb={5}>
        Marketplace
      </Heading>
      <SimpleGrid
        mt={[2.5, 4, 5, 6, 8, '35px']}
        columns={[2, null, 3, null, 4, 5]}
        gap={{ base: 3, md: 5, '2xl': 7, '3xl': '35px' }}
      >
        {spaces.map(({ metadata, minPrice }, index) => (
          <ItemCard key={index} minPrice={minPrice} metadata={metadata} />
        ))}
        {isLoading && <CardSkeleton numCards={5} />}
      </SimpleGrid>
    </Box>
  )
}

export async function getStaticProps() {
  const queryClient = new QueryClient(QUERY_CLIENT_CONFIG)

  await queryClient.prefetchQuery(['primary-options'], () => fetchOptions({ primary: true }))
  await queryClient.prefetchInfiniteQuery(['secondary-options'], () =>
    fetchOptions({ primary: false, tags: [], page: 1 })
  )

  queryClient.setQueryData(['secondary-options'], (data: any) => ({
    ...data,
    pageParams: [],
  }))

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 120,
  }
}
