import BackendApi from 'lib/api/BackendApi'
import { useQuery } from 'react-query'
import { Paginated } from '../types/vault/v2/Paginated'
import { PremiumCurrency } from '../types/vault/v2/PremiumCurrency'
import ServerSideBackendApi from '../lib/api/ServerSideBackendApi'

export const fetchPremiumCurrencyList = (): Promise<Paginated<PremiumCurrency>> =>
  BackendApi.doRequest({
    method: 'GET',
    url: '/v2/market/premium-currencies',
  }).then((res) => res.json())

export const fetchPremiumCurrencyListServerSide = (): Promise<Paginated<PremiumCurrency>> =>
  ServerSideBackendApi.doRequest({
    method: 'GET',
    url: '/v2/market/premium-currencies',
  }).then((res) => res.json())

export const usePremiumCurrencyList = ({ options = {} }) =>
  useQuery(['premium-currencies'], fetchPremiumCurrencyList, options)
