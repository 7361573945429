import AuthApi from 'lib/api/AuthApi'
import { useMutation } from 'react-query'

const fetchVerificationCodeSend = (): Promise<Response> =>
  AuthApi.doRequest({
    method: 'POST',
    url: '/auth/upgrade/start',
  })

export const usePhoneVerifiationStartMutation = () => useMutation(fetchVerificationCodeSend)
