import { Flex, FlexProps, Image, Text } from '@chakra-ui/react'
import { Button } from 'components/atoms/Button'
import { useState } from 'react'

export interface AlertProps extends FlexProps {
  label: string | React.ReactNode
  hideCloseButton?: boolean
  type?: 'caution' | 'warning'
}

export const Alert: React.FC<AlertProps> = ({
  label,
  hideCloseButton,
  type = 'warning',
  ...props
}) => {
  const [showAlert, setShowAlert] = useState(true)

  return (
    <>
      {showAlert && (
        <Flex
          role="alert"
          alignItems="center"
          justifyContent="space-between"
          minH="56px"
          p="10px"
          rounded="md"
          color="black"
          bg={type === 'warning' ? 'alert' : 'toast-deny'}
          {...props}
        >
          <Flex justifyContent="center" alignItems="center">
            <Flex w="24px" h="24px" gridGap="10px" justifyContent="center" alignItems="center">
              <Image
                w="20px"
                h="20px"
                src={
                  type === 'warning' ? '/images/icons/alert.svg' : '/images/icons/alert-caution.svg'
                }
                alt="alert caution icon"
              />
            </Flex>
            {typeof label === 'string' ? (
              <Text px="10px" size="sm">
                {label}
              </Text>
            ) : (
              label
            )}
          </Flex>
          {!hideCloseButton && (
            <Button
              w="32px"
              h="32px"
              variant="none"
              size="auto"
              flexShrink={0}
              onClick={() => setShowAlert(false)}
            >
              <Image src="/images/icons/close-gray.svg" alt="close icon" />
            </Button>
          )}
        </Flex>
      )}
    </>
  )
}
