import { Box, Skeleton, Text, VStack } from '@chakra-ui/react'
import { Checkbox, Accordian } from 'components/atoms'
import { useFilterMenu } from 'hooks/useFilterMenu'
import { Fragment } from 'react'

export const CategoryFilter: React.FC = () => {
  const { gameFilter, isLoadingGames, handleCategoryFilterChange } = useFilterMenu()

  const selectedGamesCount = gameFilter.filter(({ isSelected }) => isSelected).length

  return (
    <Accordian label="Category" accordionPanelProps={{ px: 2.5, pt: 3, pb: 0 }}>
      <VStack spacing={2.5} alignItems="stretch">
        {isLoadingGames && <Skeleton w="full" h="100px" />}

        {!isLoadingGames && selectedGamesCount === 0 && (
          <Text
            size="sm"
            lineHeight="20px"
            color="whiteAlpha.800"
            fontWeight={{ base: 300, md: 400 }}
          >
            Select a game to display categories.
          </Text>
        )}

        {selectedGamesCount > 0 &&
          gameFilter
            .filter(({ isSelected }) => isSelected)
            .map(({ id, name, categories }) => (
              <Fragment key={name}>
                {selectedGamesCount > 1 && (
                  <Box bgColor="whiteAlpha.100" py={1} px={3} borderRadius={1}>
                    <Text fontSize="md" fontWeight="700">
                      {name}
                    </Text>
                  </Box>
                )}

                {categories
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))
                  .map(({ category, displayName, isSelected }) => (
                    <Checkbox
                      key={category}
                      type="label-check"
                      isChecked={isSelected}
                      onChange={(e) => handleCategoryFilterChange(id, category, e.target.checked)}
                    >
                      <Text
                        color="white"
                        fontSize="14px"
                        fontWeight="400"
                        lineHeight="1.25rem"
                        _hover={{ fontSize: '15px', fontWeight: '600', transition: 'all .5s' }}
                      >
                        {displayName}
                      </Text>
                    </Checkbox>
                  ))}
              </Fragment>
            ))}
      </VStack>
    </Accordian>
  )
}
