import { useLayoutEffect } from 'react'

interface LockBodyScrollProps {
  lock?: boolean
}

const useLockBodyScroll = ({ lock = true }: LockBodyScrollProps) => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle: string = window.getComputedStyle(document.body).overflow
    // window.innerWidth <= maxWidth

    if (lock) {
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden'
    }

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [lock])
}

export default useLockBodyScroll
