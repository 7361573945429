import { RefObject, useEffect } from 'react'

export const useIntersectionObserver = ({
  root,
  target,
  onIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
  initialInView = false,
}: {
  root?: RefObject<Element>
  target: RefObject<Element>
  onIntersect: () => void
  threshold?: number
  rootMargin?: string
  enabled?: boolean
  initialInView?: boolean
}) => {
  useEffect(() => {
    if (!enabled) {
      return
    }

    // TODO: find a better way to detect initialInView
    if (initialInView) {
      onIntersect()
    }

    const el = target && target.current

    if (!el) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onIntersect()
          }
        }),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    )

    observer.observe(el)

    // eslint-disable-next-line consistent-return
    return () => {
      observer.unobserve(el)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, target.current, enabled])
}
