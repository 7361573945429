import AuthApi from 'lib/api/AuthApi'
import { useMutation } from 'react-query'
import { ServiceError } from '../lib/api/ServiceError'
import { User } from '../types/vault/User'
import { useUpdateTOTPStatus } from './useUpdateTOTPStatus'

interface TOTPFactor {
  uri: string
  status: User['totpVerified']
}

const registerTOTPFactor = (): Promise<TOTPFactor> =>
  AuthApi.doRequest({
    method: 'POST',
    url: '/auth/upgrade/totp/register',
  }).then((response) => response.json())

export const useRegister2FAMutation = () => {
  const updateTOTPStatus = useUpdateTOTPStatus()

  return useMutation<TOTPFactor, ServiceError>(registerTOTPFactor, {
    onSuccess: (data) => {
      updateTOTPStatus(data.status)
    },
  })
}
