export class Riskified {
  constructor(deviceId: string) {
    this.addScriptToDocument(deviceId)
  }

  private addScriptToDocument = (sessionId: string): void => {
    function addScript() {
      const url = `${document.location.protocol}//beacon.riskified.com?shop=openloot.com&sid=${sessionId}`

      const e = document.createElement('script')
      e.src = url
      document.body.appendChild(e)
    }

    window.addEventListener('load', addScript, false)
  }
}
