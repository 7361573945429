import { useContext } from 'react'
import { TrackingContext } from '../context/TrackingContext'

export const useTag = () => {
  const { tagManager, userId } = useContext(TrackingContext)

  const tag = (event: { event: string } & Record<string, any>) => {
    tagManager?.dataLayer({
      dataLayer: {
        ...(userId ? { userId } : {}),
        ...event,
      },
    })
  }

  return { tag }
}
