import { Form, Formik } from 'formik'
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  ModalBody,
  Link,
} from '@chakra-ui/react'
import { useState } from 'react'
import * as Yup from 'yup'
import { allKycCountries, isValidCountry } from 'lib/countries'
import { Select, BottomAction, Option } from 'components/atoms'
import { SelectDatepicker } from 'components/atoms/SelectDatepicker'
import { isUnder18Date, subtractYearsFromCurrDate } from 'utils/globals'
import { useCountryAndDobMutation } from 'hooks/useCountryCodeAndDobMutation'

import { useToast } from 'hooks/useToast'
import { useUser } from 'hooks/useUser'
import { useIsMobile } from 'hooks/useIsMobile'
import { UNDER_18_HELP_ARTICLE_URL, MIN_USER_AGE } from '../../../constants'

const maxDate = subtractYearsFromCurrDate(MIN_USER_AGE)

const schema = Yup.object().shape({
  country: Yup.string()
    .required('Please select a country')
    .test(
      'is-valid',
      'Account creation from this country is restricted based on our Terms of Use.',
      (value) => isValidCountry(value ?? '')
    ),
  birth: Yup.date()
    .required('Please enter your birth date')
    .max(maxDate, 'You must be at least 13 years old to continue. See our Terms of Use.'),
})

export const AddCountryAndDobModal = () => {
  const { data: user } = useUser()

  const toast = useToast()

  const { mutate: mutateCountryAndDob } = useCountryAndDobMutation()

  const [step, setStep] = useState<'country' | 'birth'>('country')

  const [isMobile] = useIsMobile()

  const handleSetCountryAndDob = (
    payload: { dateOfBirth: Date; countryCode: string },
    { onSettled }: { onSettled: () => void }
  ) => {
    mutateCountryAndDob(payload, {
      onError: (err: any) => {
        toast({
          title: err.payload?.error ?? 'An error has occurred.',
          ...(!err.payload?.error && {
            description: 'We were unable to save your info.',
          }),
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
      onSettled,
    })
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={Boolean(user) && !user?.hasCountryAndDob}
      onClose={() => undefined}
      isCentered
      size={isMobile ? 'sm' : 'xl'}
    >
      <ModalOverlay />
      <ModalContent
        bgColor="container"
        w="full"
        py={5}
        px={5}
        borderRadius="10px"
        mb={{ base: 20, md: 0 }}
        mx={{ md: 5 }}
      >
        <ModalBody pb={6} px={{ base: 2 }}>
          <Formik
            initialValues={{
              country: '',
              birth: new Date(),
            }}
            validationSchema={schema}
            onSubmit={({ birth, country }, { setSubmitting }) => {
              handleSetCountryAndDob(
                { dateOfBirth: birth, countryCode: country },
                { onSettled: () => setSubmitting(false) }
              )
            }}
          >
            {({
              isSubmitting,
              submitForm,
              dirty,
              getFieldProps,
              touched,
              errors,
              setFieldValue,
              setFieldTouched,
              values,
            }) => (
              <Form>
                {step === 'country' && (
                  <Stack spacing={8} maxW="lg" w="full" px={0} align="center">
                    <Stack spacing={4} align="center">
                      <Heading
                        textAlign="center"
                        fontSize="20px"
                        color="white"
                        lineHeight="22px"
                        fontWeight="700"
                      >
                        Select your Country of Residence
                      </Heading>
                      <Divider />
                      <Text textAlign="center">
                        Welcome back to Open Loot. We'll need some new information about your
                        account.
                      </Text>
                    </Stack>
                    <FormControl
                      id="country"
                      isInvalid={Boolean(errors.country) && touched.country}
                    >
                      <Select
                        {...getFieldProps('country')}
                        title="Select Country"
                        variant="filter"
                        onChange={(e) => setFieldValue('country', e.target.value)}
                      >
                        {allKycCountries.map(({ country, countryCode }) => (
                          <Option key={countryCode} value={countryCode}>
                            {country}
                          </Option>
                        ))}
                      </Select>
                      <FormErrorMessage maxW="xs" mx="auto" textAlign="center">
                        {errors.country && (
                          <Text textAlign="center">
                            {!getFieldProps('country').value &&
                              'You must to select your country of residence in order to continue'}
                            {getFieldProps('country').value && (
                              <>
                                Accounts from this country are restricted based on our{' '}
                                <Link
                                  href="/terms-of-use"
                                  textDecor="underline"
                                  pointerEvents="auto"
                                >
                                  Terms of use
                                </Link>
                                .
                              </>
                            )}
                          </Text>
                        )}
                      </FormErrorMessage>
                    </FormControl>
                    <Flex justifyContent="center">
                      <BottomAction>
                        <Button
                          isLoading={isSubmitting}
                          w={{ base: 'full', md: '200px' }}
                          variant="light"
                          onClick={() => setStep('birth')}
                          disabled={Boolean(errors.country)}
                        >
                          <span>Continue</span>
                        </Button>
                      </BottomAction>
                    </Flex>
                  </Stack>
                )}

                {step === 'birth' && (
                  <Stack spacing="22px" maxW="lg" w="full" px={6} align="center">
                    <Stack spacing={4} align="center">
                      <Heading
                        textAlign="center"
                        fontSize="20px"
                        color="white"
                        lineHeight="22px"
                        fontWeight="700"
                      >
                        Enter your Date of Birth
                      </Heading>
                      <Divider />
                      <Text
                        textAlign="center"
                        fontSize="16px"
                        lineHeight="22px"
                        fontWeight="400"
                        color="gray.300"
                      >
                        You must be at least 13 years old to continue.
                      </Text>
                    </Stack>
                    <FormControl
                      id="birth"
                      isInvalid={Boolean(errors.birth) && Boolean(touched.birth)}
                    >
                      <SelectDatepicker
                        hideLabels
                        order="year/month/day"
                        onDateChange={(date: Date | null) => {
                          if (date) {
                            setFieldTouched('birth')
                            setFieldValue('birth', date)
                          }
                        }}
                      />
                      <FormErrorMessage maxW="xs" mx="auto" textAlign="center">
                        {errors.birth && (
                          <Text>
                            You must be at least 13 years old to continue. See our{' '}
                            <Link href="/terms-of-use" textDecor="underline" pointerEvents="auto">
                              Terms of use
                            </Link>
                            .
                          </Text>
                        )}
                      </FormErrorMessage>

                      {touched.birth && isUnder18Date(values.birth) && (
                        <Text
                          mt={2.5}
                          color="fees"
                          textAlign="center"
                          size="sm"
                          maxW="xs"
                          mx="auto"
                        >
                          Users under the age of 18 will be allowed to earn and use NFTs in-game,
                          but will not be allowed to make Marketplace transactions.{' '}
                          <Link
                            href={UNDER_18_HELP_ARTICLE_URL}
                            target="_blank"
                            rel="noreferrer"
                            textDecor="underline"
                          >
                            Read more here.
                          </Link>{' '}
                        </Text>
                      )}
                    </FormControl>
                    <Flex justifyContent="center" pt="10px">
                      <BottomAction>
                        <Button
                          w={{ base: 'full', md: '200px' }}
                          variant="light"
                          onClick={submitForm}
                          disabled={!dirty || isSubmitting}
                          isLoading={isSubmitting}
                          loadingText={<span>Submitting...</span>}
                        >
                          <span>Continue</span>
                        </Button>
                      </BottomAction>
                    </Flex>
                  </Stack>
                )}
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
