import {
  FormLabel,
  InputGroup,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormLabelProps,
  Text,
} from '@chakra-ui/react'
import { Span } from '../Span'

export interface InputProps extends ChakraInputProps {
  label?: string | JSX.Element
  labelProps?: FormLabelProps
  inputLeftElement?: JSX.Element
  inputRightElement?: JSX.Element
}

export const Input: React.FC<InputProps> = ({
  label,
  isRequired,
  labelProps = {},
  inputLeftElement,
  inputRightElement,
  ...props
}) => (
  <FormLabel m={0} borderColor="title-field" {...labelProps}>
    {!!label &&
      (typeof label === 'string' ? (
        <Text lineHeight="22px">
          {label}
          <Span color={isRequired ? 'highlight.default' : 'white'}>*</Span>
        </Text>
      ) : (
        label
      ))}
    <InputGroup>
      {inputLeftElement}
      <ChakraInput mt={label ? 2 : 0} isRequired={isRequired} prefix="$" {...props} />
      {inputRightElement}
    </InputGroup>
  </FormLabel>
)
