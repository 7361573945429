import { ModalHeader } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { User } from 'types/vault/User'
import { PhoneSetupModal } from './PhoneSetupModal'
import { PhoneVerificationModal } from './PhoneVerificationModal'
import { Flow, TwoFactorFlowProps } from './types'

export interface PhoneTwoFactorModalProps {
  allowSetup?: boolean
  onClose: () => void
  onVerified?: () => void
  onSwitch: () => void
  skippable?: boolean
  user: User
}

export const PhoneTwoFactorModal = ({ allowSetup = false, user, ...props }: TwoFactorFlowProps) => {
  const [step, setStep] = useState<'setup' | 'verify'>('verify')
  useEffect(() => {
    if ((user && !user.phoneNumber) || allowSetup) {
      setStep('setup')
    }
  }, [user, setStep, allowSetup])

  const isSetupAllowed = allowSetup || user?.phoneNumberStatus !== 'validated'

  return (
    <>
      {step === 'setup' && (
        <PhoneSetupModal
          onSuccess={() => {
            setStep('verify')
          }}
          {...props}
        />
      )}
      {step === 'verify' && (
        <PhoneVerificationModal
          isSetupAllowed={isSetupAllowed}
          onBack={() => {
            setStep('setup')
          }}
          user={user}
          {...props}
        />
      )}
    </>
  )
}
