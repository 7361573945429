import { Switch as ChakraSwitch, SwitchProps as ChakraSwitchProps } from '@chakra-ui/react'

interface SwitchProps extends ChakraSwitchProps {
  selectedBgColor?: string
}

export const Switch: React.FC<SwitchProps> = ({ selectedBgColor, ...props }) => (
  <ChakraSwitch
    size="md"
    sx={{
      '.chakra-switch__track': {
        bg: 'whiteAlpha.100',
        border: '2px solid white',
        py: '2px',
        px: '3px',
      },
      '.chakra-switch__track[data-checked]': {
        bg: selectedBgColor ?? 'whiteAlpha.500',
      },
    }}
    {...props}
  />
)
