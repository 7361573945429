import { OptionHTMLAttributes, useMemo } from 'react'
import { get } from 'lodash'
import { useTheme } from '@chakra-ui/react'

export const Option = ({ children, ...rest }: OptionHTMLAttributes<HTMLOptionElement>) => {
  const theme = useTheme()

  const color = useMemo<string>(() => {
    const colorCode = theme.styles.global({}).body.color as string
    const { color } = rest
    if (color) {
      return color
    }
    return get(theme.colors, colorCode)
  }, [theme.styles, theme.colors])

  return (
    <option {...rest} style={{ color }}>
      {children}
    </option>
  )
}
