import { Text, TextProps } from '@chakra-ui/react'

interface SpanProps extends TextProps {
  children?: React.ReactNode
}

export const Span: React.FC<SpanProps> = ({ children, ...textProps }) => (
  <Text as="span" {...textProps}>
    {children}
  </Text>
)
