import { ChangeEvent, LegacyRef } from 'react'
import { classPrefix } from '../utils/helpers'
import { Select } from '../../Select'
import { Option } from '../../Option'

interface ISelectRenderer {
  id: string
  labels: {
    hide?: boolean
    main?: string
    placeholder?: string
  }
  value: number
  disabled?: boolean
  onChangeHandler: (e: ChangeEvent<HTMLSelectElement>) => void
  selectOptions: JSX.Element
  ref?: LegacyRef<HTMLSelectElement>
}

export const SelectRenderer = ({
  id,
  labels,
  value,
  disabled,
  onChangeHandler,
  selectOptions,
  ref,
}: ISelectRenderer) => (
  <div
    style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}
    className={`${classPrefix}_select-container-${id}`}
  >
    {!labels.hide && <label htmlFor={`${classPrefix}_select-${id}`}>{labels.main}</label>}
    <Select
      id={`${classPrefix}_select-${id}`}
      value={value}
      onChange={onChangeHandler}
      ref={ref}
      className={`${classPrefix}_select-${id}`}
    >
      <Option value={-1} disabled>
        {labels.placeholder}
      </Option>
      {selectOptions}
    </Select>
  </div>
)
