interface ArrowUpProps {
  width?: string
  height?: string
  color?: string
}

export const ArrowUp = ({ width = '13', height = '8', color = 'white' }: ArrowUpProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.364 2.82783L1.414 7.77783L-6.18079e-08 6.36383L6.364 -0.000167198L12.728 6.36383L11.314 7.77783L6.364 2.82783Z"
      fill={color}
    />
  </svg>
)
