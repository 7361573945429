import { CSSProperties } from 'react'

interface CopyProps {
  width?: string
  height?: string
  color?: string
  style?: CSSProperties
  onClick?: () => void
}

export const Copy = ({
  width = 'auto',
  height = 'auto',
  color = 'currentColor',
  style,
  onClick,
}: CopyProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    role={onClick ? 'button' : 'img'}
    onClick={onClick}
    aria-label="Copy to clipboard"
  >
    <path
      d="M6.18182 19.667L6.18182 20.667C6.7341 20.667 7.18182 20.2193 7.18182 19.667H6.18182ZM7.29983 10.9158L8.02032 11.6092H8.02033L7.29983 10.9158ZM10.5297 7.55999L11.2502 8.25345L10.5297 7.55999ZM13.4112 6.33382L13.4113 7.33382L13.4112 6.33382ZM14.8182 6.33366L14.8183 7.33366C15.3705 7.3336 15.8182 6.8859 15.8182 6.33366H14.8182ZM10.8174 1.00029L10.8176 2.00029L10.8174 1.00029ZM9.55411 1.00052L9.554 2.00052L9.5543 2.00052L9.55411 1.00052ZM5.00046 1L5.00057 5.96046e-08L5.00046 1ZM5.18182 13.6896L5.18182 19.667H7.18182L7.18182 13.6896L5.18182 13.6896ZM6.57933 10.2223C5.6827 11.1539 5.18182 12.3966 5.18182 13.6896L7.18182 13.6896C7.18182 12.9138 7.48235 12.1682 8.02032 11.6092L6.57933 10.2223ZM9.80917 6.86653L6.57933 10.2223L8.02033 11.6092L11.2502 8.25345L9.80917 6.86653ZM13.4111 5.33382C12.052 5.33398 10.7516 5.88734 9.80917 6.86653L11.2502 8.25345C11.8156 7.66594 12.5959 7.33391 13.4113 7.33382L13.4111 5.33382ZM14.8181 5.33366L13.4111 5.33382L13.4113 7.33382L14.8183 7.33366L14.8181 5.33366ZM15.8182 6.33366V5.00029L13.8182 5.00029V6.33366L15.8182 6.33366ZM15.8182 5.00029C15.8182 2.2385 13.579 -0.000226855 10.8173 0.000285566L10.8176 2.00029C12.4747 1.99998 13.8182 3.34321 13.8182 5.00029L15.8182 5.00029ZM10.8173 0.000285566L9.55393 0.000519931L9.5543 2.00052L10.8176 2.00029L10.8173 0.000285566ZM9.55423 0.000519931L5.00057 5.96046e-08L5.00034 2L9.554 2.00052L9.55423 0.000519931ZM5.00057 5.96046e-08C2.23892 -0.000315309 0 2.23836 0 5H2C2 3.34301 3.34336 1.99981 5.00034 2L5.00057 5.96046e-08ZM0 5L0 15.667H2L2 5H0ZM0 15.667C0 18.4285 2.23858 20.667 5 20.667L5 18.667C3.34315 18.667 2 17.3239 2 15.667H0ZM5 20.667H6.18182L6.18182 18.667H5L5 20.667Z"
      fill={color}
    />
    <path
      d="M13.4112 6.33428L13.4111 5.33428L13.4112 6.33428ZM11.5513 6.79324L11.0994 7.68534C10.7656 7.51625 10.5541 7.1749 10.5513 6.80068C10.5485 6.42646 10.7549 6.08201 11.0862 5.90796L11.5513 6.79324ZM15.9995 6.33398L15.9994 5.33398L15.9995 6.33398ZM6.60384 11.9017L5.70933 11.4547C5.88254 11.1081 6.24035 10.8928 6.62769 10.902C7.01502 10.9113 7.36215 11.1434 7.51864 11.4978L6.60384 11.9017ZM13.4113 7.33428C12.9206 7.33434 12.4426 7.4546 12.0163 7.67851L11.0862 5.90796C11.7963 5.53489 12.5931 5.33437 13.4111 5.33428L13.4113 7.33428ZM15.9997 7.33398L13.4113 7.33428L13.4111 5.33428L15.9994 5.33398L15.9997 7.33398ZM19 10.334C19 8.677 17.6566 7.3338 15.9997 7.33398L15.9994 5.33398C18.7611 5.33367 21 7.57234 21 10.334H19ZM19 21.001L19 10.334H21L21 21.001H19ZM16 24.001C17.6569 24.001 19 22.6579 19 21.001H21C21 23.7625 18.7614 26.001 16 26.001V24.001ZM10.1818 24.001H16V26.001H10.1818V24.001ZM7.18182 21.001C7.18182 22.6579 8.52496 24.001 10.1818 24.001V26.001C7.4204 26.001 5.18182 23.7625 5.18182 21.001H7.18182ZM7.18182 13.6901V21.001H5.18182V13.6901L7.18182 13.6901ZM7.49834 12.3488C7.29196 12.7617 7.18182 13.2204 7.18182 13.6901L5.18182 13.6901C5.18182 12.9072 5.36546 12.1427 5.70933 11.4547L7.49834 12.3488ZM6.87273 13.0769C6.31174 13.0769 5.87738 12.7322 5.68903 12.3056L7.51864 11.4978C7.41796 11.2698 7.17773 11.0769 6.87273 11.0769L6.87273 13.0769ZM7.70909 13.0769H6.87273L6.87273 11.0769H7.70909L7.70909 13.0769ZM12.7091 8.07687C12.7091 10.8383 10.4705 13.0769 7.70909 13.0769L7.70909 11.0769C9.36595 11.0769 10.7091 9.73372 10.7091 8.07687H12.7091ZM12.7091 7.051V8.07687H10.7091V7.051L12.7091 7.051ZM12.0031 5.90113C12.3995 6.10189 12.7091 6.52124 12.7091 7.051L10.7091 7.051C10.7091 7.34059 10.8838 7.57614 11.0994 7.68534L12.0031 5.90113Z"
      fill={color}
    />
    <path
      d="M6.66078 11.579L7.38134 12.2724L6.66078 11.579ZM11.2112 6.85036L10.4906 6.15697L11.2112 6.85036ZM11.9317 7.54375L7.38134 12.2724L5.94022 10.8856L10.4906 6.15697L11.9317 7.54375ZM12.709 7.05099V8.07685H10.709V7.05099H12.709ZM7.70899 13.0769H6.87262V11.0769H7.70899V13.0769ZM12.709 8.07685C12.709 10.8383 10.4704 13.0769 7.70899 13.0769V11.0769C9.36584 11.0769 10.709 9.73371 10.709 8.07685H12.709ZM7.38134 12.2724C7.81296 11.8239 7.49509 11.0769 6.87262 11.0769V13.0769C5.73175 13.0769 5.14915 11.7077 5.94022 10.8856L7.38134 12.2724ZM10.4906 6.15697C11.2954 5.32064 12.709 5.89032 12.709 7.05099H10.709C10.709 7.69072 11.4881 8.00472 11.9317 7.54375L10.4906 6.15697Z"
      fill={color}
    />
  </svg>
)
