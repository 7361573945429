import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
  AccordionProps as ChakraAccordionProps,
  TextProps,
  AccordionPanelProps,
  AccordionButtonProps,
} from '@chakra-ui/react'

interface AccordionProps extends ChakraAccordionProps {
  children: React.ReactNode
  label?: string | React.ReactNode
  accordionButtonProps?: AccordionButtonProps
  accordionPanelProps?: AccordionPanelProps
  labelProps?: TextProps
}

const Accordion: React.FC<AccordionProps> = ({
  label,
  children,
  accordionPanelProps,
  accordionButtonProps,
  labelProps,
  defaultIndex = [0],
  ...props
}) => (
  <ChakraAccordion
    defaultIndex={defaultIndex}
    allowMultiple
    allowToggle
    outline="0"
    maxW={{ md: '222px' }}
    w="100%"
    {...props}
  >
    <AccordionItem borderTop="0px" borderBottom="0px">
      <h2>
        <AccordionButton
          _focus={{ ouline: 'none' }}
          _expanded={{}}
          borderBottom="1px"
          borderColor="whiteAlpha.500"
          px={0}
          pt={0}
          pb="3px"
          {...accordionButtonProps}
        >
          {typeof label === 'string' ? (
            <>
              <Text
                flex="1"
                textAlign="left"
                fontWeight="Bold"
                fontFamily="heading"
                {...labelProps}
              >
                {label}
              </Text>
              <AccordionIcon />
            </>
          ) : (
            label
          )}
        </AccordionButton>
      </h2>
      <AccordionPanel {...accordionPanelProps}>{children}</AccordionPanel>
    </AccordionItem>
  </ChakraAccordion>
)

export default Accordion
