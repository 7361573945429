export const LogoutIcon = (options: { width?: string; height?: string } = {}) => {
  const { width, height } = options

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.875 1.625C0.875 0.9375 1.4375 0.375 2.125 0.375H7.125V1.625H2.125V10.375H7.125V11.625H2.125C1.4375 11.625 0.875 11.0625 0.875 10.375V1.625ZM9.735 5.375L8.15 3.79L9.03375 2.90625L12.1275 6L9.03375 9.09375L8.15 8.21L9.735 6.625H5.61875V5.375H9.735Z"
        fill="black"
      />
    </svg>
  )
}
