import { cloneElement, useEffect, useState, useRef, ReactNode } from 'react'
import { Box, Flex } from '@chakra-ui/react'

import { ArrowDown } from 'components/atoms/Icons/ArrowDown'

interface SelectOption extends Record<any, any> {
  label: ReactNode
}

interface CustomSelectProps {
  options: SelectOption[]
  defaultSelectedIndex?: number
  position: 'right' | 'left'
  onSelect?: (option: SelectOption) => void
}

// Custom Select is a component to select options using componentes as display value and as options.
export const CustomSelect = ({
  options,
  position,
  defaultSelectedIndex,
  onSelect,
}: CustomSelectProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedKey, setSelectedKey] = useState(
    defaultSelectedIndex && defaultSelectedIndex >= 0 ? defaultSelectedIndex : 0
  )
  const ref = useRef<HTMLDivElement>(null)

  const handleSelect = (key: number) => {
    setSelectedKey(key)
    setIsOpen(false)

    onSelect?.(options[key])
  }

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Box position="relative" ref={ref}>
      <Flex justify="space-between" flex={1}>
        {options[selectedKey] &&
          cloneElement(options[selectedKey].label as React.ReactElement, { selected: true })}
        <Flex ml={3} align="center" onClick={() => setIsOpen(!isOpen)} cursor="pointer">
          <ArrowDown width="10px" />
        </Flex>
      </Flex>
      {isOpen && (
        <Box
          position="absolute"
          bg="white"
          top="100%"
          left={{
            base: `-${(ref.current?.offsetLeft ?? 0) - 4}px`,
            md: position === 'right' ? '-100px' : '-12px',
          }}
          right={{ base: `4px`, md: '-12px' }}
          px={3}
          maxHeight="200px"
          minWidth={{ base: '100px', md: '200px' }}
          width={{ base: 'calc(100vw - 8px)', md: 'auto' }}
          overflowY="auto"
          zIndex="1000"
          mt={1}
          borderRadius="6px"
        >
          {options.map((option, index) => (
            <Box
              py={1}
              cursor="pointer"
              borderBottom={index === options.length - 1 ? undefined : '1px solid'}
              borderColor="blackAlpha.200"
              key={index}
              onClick={() => handleSelect(index)}
            >
              {option.label}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}
