import { ComponentStyleConfig } from '@chakra-ui/react'

const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontFamily: 'heading',
    fontWeight: 'bold',
  },
  sizes: {
    xs: {
      // 14px
      fontSize: '0.75rem',
      lineHeight: '0.875rem',
    },
    sm: {
      // 14px
      fontSize: '0.875rem',
      lineHeight: '1rem',
    },
    base: {
      // TODO - Base should be the smaller size.
      // 16px
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    md: {
      // 18px
      fontSize: '1.125rem',
      lineHeight: '1.375rem',
    },
    md2: {
      // 20px
      fontSize: '1.25rem',
      lineHeight: '1.375rem',
    },
    lg: {
      // 22px
      fontSize: '1.375rem',
      lineHeight: '1.6875rem',
    },
    lg2: {
      // 22px
      fontSize: '1.5rem',
      lineHeight: '1.875rem',
    },
    xl: {
      // 24px, 26px, 30px
      fontSize: ['1.5rem', null, null, '1.625rem', null, '1.875rem'],
      lineHeight: ['1.8125rem', null, null, '2rem', null, '2.3125rem'],
    },
    xxl: {
      // [30px, null, 36px, null, 40px, 44px, null, 50px]
      fontSize: ['1.875rem', null, '2.25rem', null, '2.5rem', '2.75rem', null, '3.125rem'],
      lineHeight: ['2.3125rem', null, '2.5rem', null, '2.75rem', '2.85rem', null, '3.125rem'],
    },
    '3xl': {
      // 50px
      fontSize: '3.125rem',
      lineHeight: '3.4375rem', // 55px
    },
  },
}

export default Heading
