import { useState } from 'react'
import {
  Button,
  Divider,
  Flex,
  HStack,
  ModalBody,
  PinInput,
  PinInputField,
  Text,
} from '@chakra-ui/react'
import { useToast } from 'hooks/useToast'
import { FAQ_LINK } from '../../../constants'
import { useValidateTwoFactor } from '../../../hooks/useValidateTwoFactorMutation'
import { TwoFactorFlowProps } from './types'
import { TwoFactorModalContent } from './TwoFactorModalContent'

export interface AppValidationProps extends TwoFactorFlowProps {
  onClose: () => void
  onVerified?: () => void
  skippable?: boolean
  isSetupAllowed: boolean
  validationMutation?: typeof useValidateTwoFactor
}

export const AppValidation = ({
  isSetupAllowed = true,
  skippable,
  isOpen,
  onClose,
  onVerified,
  validationMutation = useValidateTwoFactor,
  isToRemove2FA,
  setFlow,
  availableFlows,
  removeType,
  setRecoveryCodes,
  skipOnCloseWhenSucceed,
}: AppValidationProps) => {
  const [authPayload, setAuthPayload] = useState('')
  const toast = useToast()
  const { mutate: validate2FAFactor, isLoading: isValidating } = validationMutation(
    isToRemove2FA ?? false
  )
  const hasRecoveryCodesValidation = availableFlows.includes('recovery')
  const hasPhoneValidation = availableFlows.includes('phone')

  const formDisabled = authPayload.length < 6 || isValidating

  const submitForm = () =>
    validate2FAFactor(
      { code: authPayload, removeType: removeType ?? 'app', authType: 'app' },
      {
        onError: (err) => {
          toast({
            title: '2FA validation failed',
            description: (err.payload?.error ?? '') as string,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },
        onSuccess: (response) => {
          if (response.recoveryCodes && setRecoveryCodes) {
            setRecoveryCodes(response.recoveryCodes)
          } else {
            onVerified?.()
            !skipOnCloseWhenSucceed && onClose()
          }
        },
      }
    )

  const captureEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !formDisabled) {
      submitForm()
    }
  }

  return (
    <TwoFactorModalContent
      onClose={onClose}
      isOpen={isOpen}
      title="Two-Factor Authentication (2FA)"
      description="Enter Authenticator Code"
      mobileMarginTop={{ base: '174px', md: '0' }}
      desktopMarginTop={{ base: '0', md: '75px', lg: '125px' }}
      innerContent={
        <ModalBody display="flex" alignItems="center" flexDirection="column" p={0}>
          <Text align="center" mt={5}>
            Enter the 6-digit code from your Authenticator App.
          </Text>
          <HStack w="full" justify="center" mt={5} mb="20px">
            <PinInput otp value={authPayload} onChange={setAuthPayload}>
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
            </PinInput>
          </HStack>
          <Flex
            w="full"
            alignItems="center"
            direction={['column', null, 'row']}
            justifyContent="center"
            mb={15}
          >
            <Button
              onClick={() => {
                setFlow('phone')
              }}
              variant="link"
              fontSize="sm"
              fontWeight="medium"
              color="orange"
              textDecoration="underline"
              display={hasPhoneValidation ? 'flex' : 'none'}
              whiteSpace="normal"
              width="300px"
              wordBreak="break-word"
            >
              Switch to Phone verification
            </Button>
            <Divider
              orientation="horizontal"
              display={
                hasRecoveryCodesValidation && hasPhoneValidation ? ['flex', null, 'none'] : 'none'
              }
              mx={4}
              my="19px"
            />
            <Divider
              orientation="vertical"
              h="5"
              display={
                hasRecoveryCodesValidation && hasPhoneValidation ? ['none', null, 'flex'] : 'none'
              }
              mx={4}
              my={0}
            />
            <Button
              onClick={() => {
                setFlow('recovery')
              }}
              variant="link"
              fontSize="sm"
              fontWeight="medium"
              color="orange"
              textDecoration="underline"
              display={hasRecoveryCodesValidation ? 'flex' : 'none'}
              px={0}
              wordBreak="break-word"
              whiteSpace="normal"
              width="auto"
            >
              Enter Recovery Code
            </Button>
          </Flex>
        </ModalBody>
      }
      buttons={
        <Flex flexDirection="row" alignItems="center" justifyContent="center" mt={5} width="full">
          {skippable ? (
            <Button flex={1} mr={1} variant="outline" onClick={onClose}>
              Skip
            </Button>
          ) : null}
          <Button
            disabled={formDisabled}
            isLoading={isValidating}
            flex={1}
            ml={1}
            variant="light"
            maxW={{ base: '100%', md: '50%' }}
            onClick={submitForm}
          >
            <span>Submit</span>
          </Button>
        </Flex>
      }
    />
  )
}
