import { Image, Box, Input, BoxProps } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

interface SearchAmbassadorProps extends BoxProps {
  searchUrl: string
}

export const SearchAmbassador: React.FC<SearchAmbassadorProps> = ({ searchUrl, ...props }) => {
  const router = useRouter()
  const [searchStr, setSearchStr] = useState('')

  const handleSearchAmbassadorChange = (searchStr: string) => {
    router.push(`${searchUrl}?q=${searchStr}`)
  }

  useEffect(() => {
    if (typeof router.query.q === 'string') {
      setSearchStr(router.query.q)
    }
  }, [router.query.q])

  return (
    <Box pos="relative" flex={1} maxW={{ base: '300px', xl: '373px' }} {...props}>
      <Image
        src="/images/icons/search.svg"
        alt="search icon"
        pos="absolute"
        top="50%"
        right="17px"
        zIndex={10}
        transform="translateY(-50%)"
        onClick={() => handleSearchAmbassadorChange(searchStr)}
        cursor="pointer"
      />
      <Input
        h="33px"
        pl={3}
        pr="42px"
        fontSize="md"
        lineHeight="16px"
        placeholder="Search by username or email"
        color="black"
        bg="white"
        borderRadius="none"
        _placeholder={{ color: 'blackAlpha.200' }}
        value={searchStr}
        onChange={(e) => {
          setSearchStr(e.target.value)
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleSearchAmbassadorChange(searchStr)
          }
        }}
      />
    </Box>
  )
}
