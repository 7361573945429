import { useState } from 'react'
import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react'
import { useClickOutside } from '../../../hooks/useClickOutside'

export const Tooltip = (props: TooltipProps) => {
  const { defaultIsOpen, children } = props
  const [isOpen, setIsOpen] = useState(defaultIsOpen)
  const [ref] = useClickOutside(() => setIsOpen(false))
  const onClick = () => {
    setIsOpen(!isOpen)
  }
  return (
    <ChakraTooltip isOpen={isOpen} onClickCapture={onClick} {...props}>
      <span style={{ display: 'flex' }} ref={ref} onClick={onClick} aria-hidden="true">
        {children}
      </span>
    </ChakraTooltip>
  )
}
