import { memoize } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

const KEY = 'x-device-id'

export const getDeviceId = memoize(() => {
  const deviceId = localStorage?.getItem(KEY)

  if (!deviceId) {
    const newDeviceId = uuidv4()
    try {
      localStorage?.setItem(KEY, newDeviceId)
    } catch (error) {
      console.log(`there was an error storing the device id ${error}`)
    }
    return newDeviceId
  }
  return deviceId
})
