interface IconChartProps {
  type: 'small' | 'large'
}

export const IconChart = ({ type }: IconChartProps) =>
  type === 'large' ? (
    <svg width="66" height="65" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <line
          x1="16.3672"
          y1="42.3667"
          x2="16.3672"
          y2="49.5"
          stroke="#CDCDCD"
          strokeWidth="5"
          strokeLinecap="square"
        />
        <line
          x1="31.0996"
          y1="37.1665"
          x2="31.0996"
          y2="49.4998"
          stroke="#CDCDCD"
          strokeWidth="5"
          strokeLinecap="square"
        />
        <line
          x1="44.9668"
          y1="48.4331"
          x2="44.9668"
          y2="49.4998"
          stroke="#CDCDCD"
          strokeWidth="5"
          strokeLinecap="square"
        />
        <line
          x1="59.6992"
          y1="29.3667"
          x2="59.6992"
          y2="49.5"
          stroke="#CDCDCD"
          strokeWidth="5"
          strokeLinecap="square"
        />
        <line x1="5.5" y1="6.5567e-08" x2="5.5" y2="61" stroke="#CDCDCD" strokeWidth="3" />
        <path
          d="M17 29.5L34.5 12.5L44 22L62 5"
          stroke="#CDCDCD"
          strokeWidth="5"
          strokeLinecap="square"
        />
        <line x1="64" y1="62.5" x2="4" y2="62.5" stroke="#CDCDCD" strokeWidth="3" />
      </g>
    </svg>
  ) : (
    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <line
          x1="8.11328"
          y1="20.5132"
          x2="8.11328"
          y2="23.2999"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeLinecap="square"
        />
        <line
          x1="15.1406"
          y1="18.0332"
          x2="15.1406"
          y2="23.2999"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeLinecap="square"
        />
        <line
          x1="21.7539"
          y1="23.4067"
          x2="21.7539"
          y2="23.3001"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeLinecap="square"
        />
        <line
          x1="28.7793"
          y1="14.3135"
          x2="28.7793"
          y2="23.3001"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeLinecap="square"
        />
        <line x1="2.4082" y1="1.04235e-08" x2="2.4082" y2="29.0923" stroke="#CDCDCD" />
        <path
          d="M8.10742 14.0694L16.4536 5.96169L20.9843 10.4925L29.569 2.38477"
          stroke="#CDCDCD"
          strokeWidth="3"
          strokeLinecap="square"
        />
        <line x1="30.5234" y1="29.5923" x2="1.90805" y2="29.5923" stroke="#CDCDCD" />
      </g>
    </svg>
  )
