import { memoize } from 'lodash'
import { v4 as uuidv4 } from 'uuid'

const SESSION_ID = 'x-session-id'

export const getSessionId = memoize(() => {
  const sessionId = sessionStorage?.getItem(SESSION_ID)

  if (!sessionId) {
    const newSessionId = uuidv4()
    try {
      sessionStorage?.setItem(SESSION_ID, newSessionId)
    } catch (error) {
      console.log(`there was an error storing the session id ${error}`)
    }
    return newSessionId
  }
  return sessionId
})
