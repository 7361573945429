import { useFeatureAccessibility } from 'hooks/useFeatureAccessibility'
import { features } from 'types/vault/Config'
import FourOhFour from '../../../pages/404'

interface Props {
  children: JSX.Element
  feature: features
  redirectFourOhFour?: boolean
}

export const RequireFeatureAccessible = ({
  children,
  feature,
  redirectFourOhFour = true,
}: Props) => {
  const { data: isAccessible, status } = useFeatureAccessibility(feature)

  if (isAccessible) {
    return children
  }

  if (redirectFourOhFour && (status === 'success' || status === 'error')) {
    return <FourOhFour />
  }

  return <></>
}
