import { useDisclosure } from '@chakra-ui/react'
import { useAnyFeature } from 'hooks/useFeature'
import { Button } from 'components/atoms'
import { AddFundsModal } from '../Modal/AddFundsModal'

const AddFundsButton = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const hasDeposit = useAnyFeature(['depositCrypto', 'depositWire'])

  if (!hasDeposit) return null

  return (
    <>
      <Button
        variant="light-outline"
        size="responsive-border"
        onClick={(e) => {
          e.stopPropagation()
          onOpen()
        }}
      >
        Add Funds
      </Button>

      <AddFundsModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default AddFundsButton
