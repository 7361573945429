import { useUser } from 'hooks/useUser'
import { Login } from 'components/templates/login'
import { useIsUnder18User } from 'hooks/useIsUnder18User'
import { useRouter } from 'next/router'
import { useFeature } from 'hooks/useFeature'

interface RequireUserProps {
  children: JSX.Element
  isBlockUnder18User?: boolean
}

export const RequireUser: React.FC<RequireUserProps> = ({
  children,
  isBlockUnder18User = false,
}) => {
  const { data: user, status } = useUser()
  const hasUnder18UserFeature = useFeature('under18User')
  const { data: isUnder18User, status: isUnder18Status } = useIsUnder18User({
    enabled: isBlockUnder18User && hasUnder18UserFeature,
  })
  const router = useRouter()

  if (isBlockUnder18User && isUnder18User && hasUnder18UserFeature) {
    router.replace('/restricted-under-18-error')
    return null
  }

  if (status === 'loading' || (isUnder18Status === 'loading' && isBlockUnder18User)) {
    return null
  }

  if ((status === 'success' || status === 'error') && !user) {
    return <Login />
  }

  return children
}
