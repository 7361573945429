import { ReactNode, useCallback } from 'react'
import { useToast } from '@chakra-ui/react'

export const useCopyToClipboard = (
  content?: string,
  toastTitle: ReactNode = 'Copied to clipboard!',
  toastDescription?: ReactNode
) => {
  const toast = useToast()
  const cb = useCallback(
    async (cbContent?: string, cbToastTitle?: ReactNode, cbToastDescription?: ReactNode) => {
      if (!navigator?.clipboard?.writeText) {
        return
      }

      await navigator.clipboard.writeText(cbContent ?? content ?? '')
      toast({
        title: cbToastTitle ?? toastTitle,
        description: cbToastDescription ?? toastDescription,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    },
    [toastTitle, toastDescription, content, toast]
  )

  return cb
}
