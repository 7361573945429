import { SaleGroup } from 'types/vault/SaleGroup'
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { SaleCardCountDown } from 'components/atoms/CountDown'
import { Link, ReactMarkdown } from 'components/atoms'
import { defaultSaleListCoverUrl, getSaleListCoverUrl } from 'lib/image'

interface SaleCardProps {
  saleGroup: SaleGroup
  active: boolean
}

export const SaleCardDesktop: React.FC<SaleCardProps> = ({ saleGroup, active }) => {
  const saleGroupLink = `/sales/${saleGroup.slug}`
  const bg = active ? 'darkGrey' : 'lightDark'
  const radius = active ? '0px' : '6px'
  const padding = active ? '20px 32px' : '10px 32px'
  return (
    <>
      <Box
        w="151px"
        mr="5px"
        h="151px"
        border="1px solid"
        sx={{
          'border-image': 'linear-gradient(to right, #FFFFFF66, #FFFFFF29) 1',
        }}
      >
        <Link href={saleGroupLink}>
          <Image
            w="100%"
            h="100%"
            objectFit="cover"
            objectPosition="center"
            src={getSaleListCoverUrl(saleGroup.name)}
            alt={saleGroup.name}
            fallbackSrc={defaultSaleListCoverUrl}
            cursor="pointer"
          />
        </Link>
      </Box>
      <Flex minH="151px" flex="1" direction="row">
        <Box flex="1" bg={active ? 'lightGrey' : 'grey'} px="20px" py="15px">
          <Box maxW="585px">
            <Link href={saleGroupLink}>
              <Heading
                color="white"
                fontSize="2xl"
                marginBottom={2}
                _hover={{ textDecoration: 'underline' }}
                cursor="pointer"
              >
                {saleGroup.name}
              </Heading>
            </Link>
            <Box display="inline-block">
              <Link href={`/games/${saleGroup?.game?.slug}`} passHref>
                <Text
                  mb={2}
                  size="md"
                  fontFamily="heading"
                  fontColor="white"
                  fontWeight="300"
                  cursor="pointer"
                  _hover={{
                    WebkitTransform: `scale(.97)`,
                    color: 'whiteAlpha.700',
                  }}
                >
                  {saleGroup?.game?.name}
                </Text>
              </Link>
            </Box>
          </Box>
          <Box maxW="585px">
            <ReactMarkdown
              content={saleGroup.description}
              boxProps={{ fontSize: 'sm', color: 'gray.300', noOfLines: 2 }}
            />
          </Box>
        </Box>
        <Flex
          direction="column"
          bg={active ? 'lightGrey' : 'grey'}
          ml="5px"
          padding="20px"
          justify="space-around"
          w="245px"
        >
          <Flex
            justify="center"
            padding={padding}
            direction="column"
            bg={bg}
            borderRadius={radius}
            mb="7px"
          >
            <SaleCardCountDown deadline={new Date(saleGroup.availableAt).getTime()} align="center">
              <Text fontWeight="bold" color="#1CBF6A">
                On Sale NOW
              </Text>
            </SaleCardCountDown>
          </Flex>
          <Flex justify="center" alignSelf="center" minWidth="130px">
            <Link href={saleGroupLink}>
              <Text
                as="a"
                color="highlight.default"
                fontWeight="600"
                cursor="pointer"
                _hover={{ textDecoration: 'underline' }}
              >
                More Details
              </Text>
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
