import { Accordian, RangeFilter, Span } from 'components/atoms'
import { useFilterMenu } from 'hooks/useFilterMenu'
import { checkIsExceedMaxDigits } from 'utils/globals'

export const PriceFilter = () => {
  const { singleSelectFilters, handleSingleSelectFilterChange } = useFilterMenu()

  return (
    <Accordian label="Price" accordionPanelProps={{ p: 0 }}>
      <RangeFilter
        inputIcon={<Span mr={1}>$</Span>}
        toInputProps={{
          value: singleSelectFilters.priceTo.value,
          onChange({ target }) {
            const value = target.value ? +target.value : ''
            if (value === '' || (!checkIsExceedMaxDigits(+value) && +value > 0)) {
              handleSingleSelectFilterChange('priceTo', value)
            }
          },
          min: 1,
        }}
        fromInputProps={{
          value: singleSelectFilters.priceFrom.value,
          onChange({ target }) {
            const value = target.value ? +target.value : ''
            if (value === '' || (!checkIsExceedMaxDigits(+value) && +value > 0)) {
              handleSingleSelectFilterChange('priceFrom', value)
            }
          },
          min: 1,
        }}
        fromPlaceholder="0"
        toPlaceholder="MAX"
      />
    </Accordian>
  )
}
