import {
  Flex,
  FlexProps,
  Image as ChakraImage,
  ImageProps,
  Skeleton as ChakraSkeleton,
  Text,
  TextProps,
} from '@chakra-ui/react'

export const Image = ({ htmlHeight, ...props }: ImageProps) => (
  <ChakraImage
    htmlHeight="172px"
    htmlWidth="172px"
    bg="black"
    width="100%"
    height="auto"
    fallback={<ChakraSkeleton width="100%" minH={`${htmlHeight ?? 172}px`} />}
    {...props}
  />
)

export const Body = ({ children, ...props }: FlexProps) => (
  <Flex p="3" direction="column" {...props}>
    {children}
  </Flex>
)

export const Title = ({ children, ...props }: TextProps) => (
  <Text fontWeight="bold" color="white" {...props}>
    {children}
  </Text>
)

export const Subtitle = ({ children, ...props }: TextProps) => (
  <Text fontSize="xs" {...props}>
    {children}
  </Text>
)

export const Root = ({ children, ...props }: FlexProps) => (
  <Flex h="full" direction="column" borderRadius="none" bg="gray.700" overflow="hidden" {...props}>
    {children}
  </Flex>
)

export const Skeleton = ({ minH = '172px' }: { minH?: string }) => (
  <Root>
    <ChakraSkeleton width="100%" minH={minH} />
    <Body>
      <ChakraSkeleton h="3" w="8" mb="3" />
      <ChakraSkeleton h="4" w="20" mb="6" />
      <ChakraSkeleton h="3" w="8" mb="3" />
    </Body>
  </Root>
)

export const Card = {
  Root,
  Image,
  Body,
  Title,
  Subtitle,
  Skeleton,
}
