import { RentalListingType } from 'types/vault/v2/Rental'
import { cloneDeep } from 'lodash'
import { ParsedUrlQueryInput } from 'querystring'
import { GameFilterType } from 'types/filter-menu'
import { ItemCategory } from 'types/vault/Game'
import { Game, NFTTag } from 'types/vault/v2/Game'
import { RARITY_FILTER_ITEMS } from '../constants'

export const FILTER_QUERY_KEYS = {
  game: 'gameId',
  category: 'category',
  tag: 'tag',
  rarity: 'rarity',
  mintedTo: 'mintedTo',
  mintedFrom: 'mintedFrom',
  priceTo: 'priceTo',
  priceFrom: 'priceFrom',
  onSale: 'onSale',
  nonOnSale: 'nonOnSale',
  rentalListingTypes: 'rentalListingTypes',
  rentalPeriods: 'rentalPeriods',
}

export function handleAddIsSelectField<T>(arr: T[]): (T & { isSelected: boolean })[] {
  return arr.map((item) => ({ ...item, isSelected: false }))
}

export const selectedFieldsToStr = (arr: any[], field = 'id') =>
  arr
    .filter(({ isSelected }) => isSelected)
    .map((obj) => obj[field])
    .join(',')

export const initialRarities = handleAddIsSelectField(RARITY_FILTER_ITEMS)

export const initialSingleSelectFilters = {
  mintedTo: {
    value: '',
    queryKey: FILTER_QUERY_KEYS.mintedTo,
  },
  mintedFrom: {
    value: '',
    queryKey: FILTER_QUERY_KEYS.mintedFrom,
  },
  priceTo: {
    value: '',
    queryKey: FILTER_QUERY_KEYS.priceTo,
  },
  priceFrom: {
    value: '',
    queryKey: FILTER_QUERY_KEYS.priceFrom,
  },
  onSale: {
    value: true,
    queryKey: FILTER_QUERY_KEYS.onSale,
  },
  nonOnSale: {
    value: false,
    queryKey: FILTER_QUERY_KEYS.nonOnSale,
  },
} as const

const buildTreeOfNFTTags = (nftTags: NFTTag[]) => {
  const map: { [tag: string]: any } = {}
  let node
  const roots = []
  let i

  for (i = 0; i < nftTags.length; i += 1) {
    map[nftTags[i].tag] = { ...nftTags[i], children: [], isSelected: false }
  }

  for (i = 0; i < nftTags.length; i += 1) {
    node = map[nftTags[i].tag]
    if (node.parentTag && map[node.parentTag]) {
      map[node.parentTag].children.push(node)
    } else {
      roots.push(node)
    }
  }
  return roots
}
// accepts gameList as argument, and convert it to GameFilterType[]
export const handleGameListToGameFilter = (list: Game[], isSelected = false): GameFilterType[] => {
  const gameFilters = list.map(({ id, name, extra }) => ({
    id,
    name,
    isSelected,
    categories: handleAddIsSelectField((extra?.itemCategories as ItemCategory[]) || []),
    nftTags: buildTreeOfNFTTags(extra?.nftTags || []),
  }))
  return gameFilters
}

export const handleGameSelect = ({
  gameFilter,
  gameId,
  isSelected,
  isSingleSelect = false,
}: {
  gameFilter: GameFilterType[]
  gameId: string
  isSelected: boolean
  isSingleSelect?: boolean
}) => {
  const gameIndex = gameFilter.findIndex(({ id }) => id === gameId)

  let updatedFilters = cloneDeep(gameFilter)
  const game = updatedFilters[gameIndex]

  if (isSingleSelect) {
    updatedFilters = updatedFilters.map((filter) => ({
      ...filter,
      isSelected: false,
      categories: handleAddIsSelectField(filter.categories),
    }))
  }

  if (game) {
    updatedFilters[gameIndex] = {
      ...game,
      isSelected,
      categories: handleAddIsSelectField(game.categories),
    }
  }

  return updatedFilters
}

export const handleCategorySelect = ({
  gameFilter,
  gameId,
  category,
  isSelectedCategory,
}: {
  gameFilter: GameFilterType[]
  gameId: string
  category: string
  isSelectedCategory: boolean
}) => {
  const gameIndex = gameFilter.findIndex(({ id }) => id === gameId)

  const filters = cloneDeep(gameFilter)
  const game = filters[gameIndex]

  if (game && game.isSelected) {
    const updatedCategories = game.categories.map(({ isSelected, ...categoryFields }) => ({
      ...categoryFields,
      isSelected: categoryFields.category === category ? isSelectedCategory : isSelected,
    }))
    filters[gameIndex] = {
      ...game,
      categories: updatedCategories,
    }
  }

  return filters
}

export const handleTagSelect = ({
  gameFilter,
  gameId,
  tag,
  isSelectedTag,
}: {
  gameFilter: GameFilterType[]
  gameId: string
  tag: string
  isSelectedTag: boolean
}) => {
  const gameIndex = gameFilter.findIndex(({ id }) => id === gameId)

  const filters = cloneDeep(gameFilter)
  const game = filters[gameIndex]

  if (game && game.isSelected) {
    const updatedNFTTags = game.nftTags.map(({ isSelected, children, ...tagFields }) => ({
      ...tagFields,
      isSelected: tagFields.tag === tag ? isSelectedTag : isSelected,
      children: children.map(({ isSelected, ...childTagFields }) => ({
        ...childTagFields,
        isSelected: childTagFields.tag === tag ? isSelectedTag : isSelected,
      })),
    }))
    filters[gameIndex] = {
      ...game,
      nftTags: updatedNFTTags,
    }
  }

  return filters
}

export const handleInitialGameCategorySelect = ({
  gameFilter,
  selectedGameIds = [],
  selectedCategories = [],
  selectedTags = [],
}: {
  gameFilter: GameFilterType[]
  selectedGameIds: string[]
  selectedCategories: string[]
  selectedTags: string[]
}) => {
  const filters = gameFilter.map(({ id, categories, nftTags, ...game }) => {
    const isGameSelected = selectedGameIds.includes(id)
    // if game is not selected, add isSelected false for every category and tag
    const updatedCategories = isGameSelected
      ? categories.map((categoryFields) => ({
          ...categoryFields,
          isSelected: selectedCategories.includes(categoryFields.category),
        }))
      : handleAddIsSelectField(categories)
    const updatedNFTTags = isGameSelected
      ? nftTags.map((nftTagFields) => ({
          ...nftTagFields,
          isSelected: selectedTags.includes(nftTagFields.tag),
          children: nftTagFields.children.map((chilNFTTagFields) => ({
            ...chilNFTTagFields,
            isSelected: selectedTags.includes(chilNFTTagFields.tag),
          })),
        }))
      : handleAddIsSelectField(nftTags)

    return {
      ...game,
      id,
      isSelected: isGameSelected,
      categories: updatedCategories,
      nftTags: updatedNFTTags,
    }
  })

  return filters
}

export const selectedGamesToStr = (gameFilter: GameFilterType[]): string => {
  const str = selectedFieldsToStr(gameFilter)

  return str
}

export const selectedCategoriesToStr = (gameFilter: GameFilterType[]): string => {
  const allCategories = gameFilter.map(({ categories }) => categories)
  const flatCategories = allCategories.flat(1)

  const str = selectedFieldsToStr(flatCategories, 'category')

  return str
}

export const getCategoryQueryKeys = (
  gameFilter: GameFilterType[]
): [ParsedUrlQueryInput, string[]] => {
  const removeQueryKeys: string[] = []
  const queryObj: Record<any, any> = {}

  const categoryStr = selectedCategoriesToStr(gameFilter)
  if (categoryStr) {
    queryObj[FILTER_QUERY_KEYS.category] = categoryStr
  } else {
    removeQueryKeys.push(FILTER_QUERY_KEYS.category)
  }

  return [queryObj, removeQueryKeys]
}

export const selectedTagsToStr = (gameFilter: GameFilterType[]): string => {
  const allNFTTags = gameFilter.flatMap(({ nftTags }) => nftTags)
  const result: string[] = []
  allNFTTags.forEach((nftTag) => {
    if (nftTag.children && nftTag.children.length > 0) {
      const b = nftTag.children
        .filter((nftTag) => nftTag.isSelected)
        .map((nftTag) => nftTag.tag)
        .join(',')
      if (b) {
        result.push(b)
      }
    } else if (nftTag.isSelected) {
      result.push(nftTag.tag)
    }
  })
  return result.join('|')
}

export const getTagQueryKeys = (gameFilter: GameFilterType[]): [ParsedUrlQueryInput, string[]] => {
  const removeQueryKeys: string[] = []
  const queryObj: Record<any, any> = {}

  const tagStr = selectedTagsToStr(gameFilter)
  if (tagStr) {
    queryObj[FILTER_QUERY_KEYS.tag] = tagStr
  } else {
    removeQueryKeys.push(FILTER_QUERY_KEYS.tag)
  }

  return [queryObj, removeQueryKeys]
}

export const getGameQueryKeys = (
  gameFilter: GameFilterType[],
  isUpdateCategories = false
): [ParsedUrlQueryInput, string[]] => {
  let queryObj: Record<any, any> = {}
  let removeQueryKeys: string[] = []

  const gameStr = selectedGamesToStr(gameFilter)
  if (gameStr) {
    queryObj[FILTER_QUERY_KEYS.game] = gameStr
  } else {
    removeQueryKeys.push(FILTER_QUERY_KEYS.game)
  }

  if (isUpdateCategories) {
    const [categoryQueryObj, categoryRemoveQueryKeys] = getCategoryQueryKeys(gameFilter)
    queryObj = { ...queryObj, ...categoryQueryObj }
    removeQueryKeys = [...removeQueryKeys, ...categoryRemoveQueryKeys]

    const [nftTagQueryObj, nftTagRemoveQueryKeys] = getTagQueryKeys(gameFilter)
    queryObj = { ...queryObj, ...nftTagQueryObj }
    removeQueryKeys = [...removeQueryKeys, ...nftTagRemoveQueryKeys]
  }

  return [queryObj, removeQueryKeys]
}

export const getRarityQueryKeys = (
  rarityFilter: typeof initialRarities
): [ParsedUrlQueryInput, string[]] => {
  const removeQueryKeys: string[] = []
  const queryObj: Record<any, any> = {}

  const rarityStr = selectedFieldsToStr(rarityFilter)
  if (rarityStr) {
    queryObj[FILTER_QUERY_KEYS.rarity] = rarityStr
  } else {
    removeQueryKeys.push(FILTER_QUERY_KEYS.rarity)
  }

  return [queryObj, removeQueryKeys]
}

export const getRentalTypesQueryKeys = (
  types: RentalListingType[]
): [ParsedUrlQueryInput, string[]] => {
  const removeQueryKeys: string[] = []
  const queryObj: Record<any, any> = {}

  const typesStr = types.length > 0 ? types.join(',') : null
  if (typesStr) {
    queryObj[FILTER_QUERY_KEYS.rentalListingTypes] = typesStr
  } else {
    removeQueryKeys.push(FILTER_QUERY_KEYS.rentalListingTypes)
  }

  return [queryObj, removeQueryKeys]
}

export const getRentalPeriodsQueryKeys = (periods: number[]): [ParsedUrlQueryInput, string[]] => {
  const removeQueryKeys: string[] = []
  const queryObj: Record<any, any> = {}

  const periodsStr = periods.length > 0 ? periods.join(',') : null
  if (periodsStr) {
    queryObj[FILTER_QUERY_KEYS.rentalPeriods] = periodsStr
  } else {
    removeQueryKeys.push(FILTER_QUERY_KEYS.rentalPeriods)
  }

  return [queryObj, removeQueryKeys]
}
