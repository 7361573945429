import { useInfiniteQuery } from 'react-query'
import { fetchOptionsV2 } from './useOptions'

type Filters = {
  tags?: string
  nftTags?: string
  order?: string
  sort?: string
  gameId?: string
  rarity?: string
  priceTo?: number
  priceFrom?: number
  mintedTo?: number
  mintedFrom?: number
  q?: string
  onSale?: boolean
}

export const useSecondaryOptions = ({
  tags,
  nftTags,
  order,
  sort,
  gameId,
  rarity,
  priceFrom,
  priceTo,
  mintedFrom,
  mintedTo,
  q,
  onSale,
}: Filters) =>
  useInfiniteQuery(
    [
      'secondary-options',
      tags,
      nftTags,
      order,
      sort,
      gameId,
      rarity,
      priceFrom,
      priceTo,
      mintedFrom,
      mintedTo,
      q,
      onSale,
    ],
    ({ pageParam = 1 }) =>
      fetchOptionsV2({
        primary: false,
        tags,
        nftTags,
        order,
        sort,
        page: pageParam,
        gameId,
        rarity,
        priceTo,
        priceFrom,
        mintedFrom,
        mintedTo,
        q,
        onSale,
      }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.totalItems === 0 || lastPage.currentPage === lastPage.totalPages
          ? null
          : lastPage.currentPage + 1,
    }
  )
