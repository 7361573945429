import {
  FormControl,
  Input,
  ModalFooter,
  Text,
  Heading,
  VStack,
  Container,
  HStack,
  FormErrorMessage,
  Divider,
  Flex,
  Link,
  ModalHeader,
  useBreakpointValue,
} from '@chakra-ui/react'
import { Form as ModalBody, Formik } from 'formik'
import { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { BottomAction, Button } from 'components/atoms'
import { usePhoneVerifiationMutation } from 'hooks/usePhoneVerificationMutation'
import { usePhoneVerifiationStartMutation } from 'hooks/usePhoneVerificationStartMutation'
import { useToast } from 'hooks/useToast'
import { User } from 'types/vault/User'
import { FAQ_LINK } from '../../../constants'
import { TwoFactorFlowProps } from './types'
import { useValidateTwoFactor } from '../../../hooks/useValidateTwoFactorMutation'
import { TwoFactorModalContent } from './TwoFactorModalContent'

const OTPSchema = Yup.object().shape({
  code: Yup.string().length(6).required('Required'),
})

export interface PhoneVerificationModalProps extends TwoFactorFlowProps {
  isSetupAllowed: boolean
  onBack?: () => void
  onSwitch?: () => void
  verificationMutation?: typeof useValidateTwoFactor
}

export const PhoneVerificationModal = ({
  isSetupAllowed = true,
  onClose,
  onBack,
  onSwitch,
  onVerified,
  skippable = false,
  user,
  isOpen,
  setFlow,
  availableFlows = ['phone'],
  verificationMutation = useValidateTwoFactor,
  isToRemove2FA,
  removeType,
  setRecoveryCodes,
  skipOnCloseWhenSucceed,
}: PhoneVerificationModalProps) => {
  const [remainingSeconds, setRemainingSeconds] = useState(0)

  const { mutate: mutateStart, isLoading: isLoadingVerification } =
    usePhoneVerifiationStartMutation()
  const { mutate: mutateValidate } = verificationMutation(isToRemove2FA ?? false)

  const toast = useToast()

  const requestOTP = useCallback(() => {
    mutateStart(undefined, {
      onSuccess: () => {
        setRemainingSeconds(60)
      },
      onError: (err: any) => {
        const msg =
          err?.payload?.error ??
          err.message ??
          "We couldn't request code. Please try again after some time."

        toast({
          title: 'Error',
          description: msg,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }, [mutateStart, setRemainingSeconds, toast])

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingSeconds(remainingSeconds - 1)
    }, 1000)
    if (remainingSeconds === 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [remainingSeconds, setRemainingSeconds])

  const hasRecoveryCodesValidation = availableFlows.includes('recovery')
  const hasTotpValidation = availableFlows.includes('app')

  return (
    <>
      <Formik
        validationSchema={OTPSchema}
        initialValues={{ code: '', authType: 'phone', removeType: removeType ?? 'phone' }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          mutateValidate(values, {
            onError: () => {
              toast({
                title: 'Error',
                description: `We couldn't validate your code. Please try again.`,
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
              resetForm()
            },
            onSuccess: (response) => {
              if (response.recoveryCodes && setRecoveryCodes) {
                setRecoveryCodes(response.recoveryCodes)
              } else {
                onVerified?.()
                !skipOnCloseWhenSucceed && onClose()
              }
            },
            onSettled() {
              setSubmitting(false)
            },
          })
        }}
      >
        {({ values, isValid, dirty, submitForm, isSubmitting, setFieldValue, errors, touched }) => (
          <TwoFactorModalContent
            mobileMarginTop={{ base: '174px', md: '0' }}
            desktopMarginTop={{ base: '0', md: '75px', lg: '125px' }}
            onClose={onClose}
            isOpen={isOpen}
            description="Enter the security code"
            title="Two-Factor Authentication (2FA)"
            innerContent={
              <ModalBody>
                <Container>
                  <VStack spacing="30px" my="8">
                    <Text lineHeight="base" textAlign="center">
                      Click{' '}
                      <Text color="white" as="span" fontWeight="bold">
                        Get Code
                      </Text>{' '}
                      below when you’re ready to receive a verification code via SMS to the phone
                      number ending in{' '}
                      <Text color="white" as="span" fontWeight="bold">
                        ...{user?.phoneNumber?.substr(-4)}
                      </Text>
                      .<br />
                      Once you have your code, enter it below.
                      <br />
                    </Text>
                    <FormControl isInvalid={!!errors.code && !!touched.code}>
                      <HStack justify="center">
                        <Input
                          maxLength={6}
                          width="auto"
                          id="code"
                          value={values.code}
                          inputMode="numeric"
                          type="tel"
                          textAlign="center"
                          autoComplete="one-time-code"
                          fontSize="18px"
                          lineHeight="22px"
                          fontWeight="400"
                          letterSpacing="0.49em"
                          onChange={(e) => setFieldValue('code', e.target.value)}
                        />
                        <Button
                          ml="2"
                          variant="ghost"
                          colorScheme="orange"
                          color="orange.400"
                          autoFocus
                          fontWeight="400"
                          fontSize="18px"
                          disabled={remainingSeconds !== 0 || isLoadingVerification}
                          onClick={() => requestOTP()}
                        >
                          Get Code
                        </Button>
                      </HStack>
                      <FormErrorMessage>{errors.code}</FormErrorMessage>
                    </FormControl>
                    {remainingSeconds > 0 && (
                      <Text fontSize="sm" color="white">
                        Didn’t receive a code?{' '}
                        <Text as="span" color="orange.400" fontSize="sm">
                          Resend in {remainingSeconds} seconds
                        </Text>
                      </Text>
                    )}
                    <Flex
                      w="full"
                      alignItems="center"
                      direction={['column', null, 'row']}
                      justifyContent="center"
                      mb={15}
                      display={isSetupAllowed ? 'none' : 'flex'}
                    >
                      <Button
                        onClick={() => {
                          setFlow('app')
                        }}
                        variant="link"
                        fontSize="sm"
                        fontWeight="medium"
                        color="orange"
                        textDecoration="underline"
                        display={hasTotpValidation ? 'flex' : 'none'}
                        whiteSpace="normal"
                        width="300px"
                        wordBreak="break-word"
                      >
                        Switch to Authenticator verification
                      </Button>
                      <Divider
                        orientation="horizontal"
                        display={
                          hasRecoveryCodesValidation && hasTotpValidation
                            ? ['flex', null, 'none']
                            : 'none'
                        }
                        mx={4}
                        my="19px"
                      />
                      <Divider
                        orientation="vertical"
                        h="5"
                        display={
                          hasRecoveryCodesValidation && hasTotpValidation
                            ? ['none', null, 'flex']
                            : 'none'
                        }
                        mx={4}
                        my={0}
                      />
                      <Button
                        onClick={() => {
                          setFlow('recovery')
                        }}
                        variant="link"
                        fontSize="sm"
                        fontWeight="medium"
                        color="orange"
                        textDecoration="underline"
                        display={hasRecoveryCodesValidation ? 'flex' : 'none'}
                        px={0}
                        wordBreak="break-word"
                        whiteSpace="normal"
                        width="auto"
                      >
                        Enter Recovery Code
                      </Button>
                    </Flex>
                  </VStack>
                </Container>
              </ModalBody>
            }
            buttons={
              <BottomAction>
                <Flex flexDirection="row" alignItems="center" justifyContent="center" width="full">
                  {skippable && !isSetupAllowed && (
                    <Button
                      alignSelf={isSetupAllowed ? 'flex-start' : 'flex-end'}
                      onClick={onClose}
                      variant="outline"
                      mr={3}
                    >
                      Skip
                    </Button>
                  )}
                  {isSetupAllowed && (
                    <Button colorScheme="gray" mr={3} onClick={onBack} variant="outline">
                      Back
                    </Button>
                  )}
                  <Button
                    isLoading={isSubmitting}
                    variant="light"
                    onClick={submitForm}
                    disabled={!isValid || !dirty || isSubmitting}
                  >
                    <span>Submit</span>
                  </Button>
                </Flex>
              </BottomAction>
            }
          />
        )}
      </Formik>
    </>
  )
}
