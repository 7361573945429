import { Flex, Text } from '@chakra-ui/react'
import { IconChart } from 'components/atoms/Icons/IconChart'

export const NotEnoughDataChart = () => (
  <Flex mt={10} direction="column" justifyContent="center" alignItems="center" gridGap="10px">
    <IconChart type="small" />
    <Text
      fontFamily="Bai Jamjuree"
      fontStyle="normal"
      fontWeight="700"
      fontSize="16px"
      lineHeight="22px"
      color="bright.default"
    >
      Not enough data
    </Text>
  </Flex>
)
