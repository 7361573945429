import { Box, Skeleton, Text, VStack, HStack } from '@chakra-ui/react'
import { Checkbox, Accordian } from 'components/atoms'
import { useFilterMenu } from 'hooks/useFilterMenu'
import { Fragment } from 'react'

export const NFTTagFilter: React.FC = () => {
  const { gameFilter, isLoadingGames, handleTagFilterChange } = useFilterMenu()

  const selectedGamesCount = gameFilter.filter(({ isSelected }) => isSelected).length

  return (
    <Accordian label="Tags" accordionPanelProps={{ px: 2.5, pt: 3, pb: 0 }}>
      <VStack spacing={2.5} alignItems="stretch">
        {isLoadingGames && <Skeleton w="full" h="100px" />}

        {!isLoadingGames && selectedGamesCount === 0 && (
          <Text
            size="sm"
            lineHeight="20px"
            color="whiteAlpha.800"
            fontWeight={{ base: 300, md: 400 }}
          >
            Select a game to display tags.
          </Text>
        )}

        {selectedGamesCount > 0 &&
          gameFilter
            .filter(({ isSelected }) => isSelected)
            .map(({ id, name, nftTags }) => (
              <Fragment key={name}>
                {selectedGamesCount > 1 && (
                  <Box bgColor="whiteAlpha.100" py={1} px={3} borderRadius={1}>
                    <Text fontSize="md" fontWeight="700">
                      {name}
                    </Text>
                  </Box>
                )}

                {nftTags
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))
                  .map(({ tag, displayName, isSelected, children }) => {
                    if (children && children.length > 0) {
                      return (
                        <>
                          <Accordian
                            label={displayName}
                            accordionPanelProps={{ px: 2.5, pt: 3, pb: 0 }}
                            accordionButtonProps={{
                              borderBottom: 'none',
                              sx: { p: { fontWeight: 'normal !important' } },
                            }}
                            maxW="none"
                          >
                            <VStack spacing={2.5} alignItems="stretch">
                              {children.map((child) => (
                                <Checkbox
                                  key={child.tag}
                                  type="label-check"
                                  isChecked={child.isSelected}
                                  onChange={(e) =>
                                    handleTagFilterChange(id, child.tag, e.target.checked)
                                  }
                                  cursor="pointer"
                                >
                                  <HStack justifyContent="space-between" flex={1} mr={2.5}>
                                    <Text size="sm" textTransform="capitalize">
                                      {child.displayName}
                                    </Text>
                                  </HStack>
                                </Checkbox>
                              ))}
                            </VStack>
                          </Accordian>
                        </>
                      )
                    }
                    return (
                      <Checkbox
                        key={tag}
                        type="label-check"
                        isChecked={isSelected}
                        onChange={(e) => handleTagFilterChange(id, tag, e.target.checked)}
                      >
                        <Text
                          color="white"
                          fontSize="14px"
                          fontWeight="400"
                          lineHeight="1.25rem"
                          _hover={{ fontSize: '15px', fontWeight: '600', transition: 'all .5s' }}
                        >
                          {displayName}
                        </Text>
                      </Checkbox>
                    )
                  })}
              </Fragment>
            ))}
      </VStack>
    </Accordian>
  )
}
