import { Box, Circle, Heading, HeadingProps, HStack, SimpleGrid } from '@chakra-ui/react'
import { Button } from 'components/atoms'

interface FeaturedItemsProps {
  withShowMoreBtn?: boolean
  titleProps?: HeadingProps
}

const card = {
  id: '68459b4b-a78b-4b70-8f76-d5654ac912f1',
  minPrice: 10000000,
  metadata: {
    archetypeId: 'BT0_CrystalBlue_Title',
    archetypeParams: '0',
    collection: 'BT0',
    createdAt: '2022-04-13T15:35:00.072Z',
    description: 'Title of Sir Cai known as the Rude Knight.',
    game: {
      id: '56a149cf-f146-487a-8a1c-58dc9ff3a15c',
      name: 'Big Time',
      description:
        'Big Time is a free-to-play, multiplayer action RPG game that combines fast-action combat and adventure through time and space.',
      status: 'active',
      extra: {
        developer: 'Big Time Studios',
        discordUrl: 'https://discord.com/invite/bigtime',
        supportUrl: 'https://bigtimestudios.zendesk.com/',
        twitterUrl: 'https://twitter.com/playbigtime',
        officialWebsite: 'https://www.bigtime.gg/',
      },
      createdAt: new Date('2022-09-18T22:55:45.061Z'),
      updatedAt: new Date('2022-09-18T22:55:45.061Z'),
      logoUrl: '',
      bannerUrl: '',
      version: 1,
    },
    gameId: '56a149cf-f146-487a-8a1c-58dc9ff3a15c',
    imageUrl: 'https://openloot-metadata.bigtime.gg/BT0/CrystalBlue_Title.png',
    maxIssuance: 3500,
    name: 'The Rude Knight',
    optionName: 'CrystalBlue_Title',
    rarity: 'uncommon',
    tags: ['cosmetic', 'crystalblue', 'title'],
    updatedAt: '2022-04-13T15:35:00.072Z',
  },
}

export const cardMock = [card, card, card, card, card]

export const FeaturedItems: React.FC<FeaturedItemsProps> = ({ withShowMoreBtn, titleProps }) => (
  <Box as="section">
    <Heading
      as="h3"
      size="xl"
      textShadow="heroText"
      textAlign="center"
      mb={[2.5, 4, 5, 6, 8, '35px']}
      {...titleProps}
    >
      Featured Items
    </Heading>

    {/* <Box w="100%" overflow="hidden" > */}
    <SimpleGrid
      // used width to add scroll
      // width={['250%', null, '166.7%', null, '125%', '100%']}
      // columns={5}
      columns={[2, null, 3, null, 4, 5]}
      gap={{ base: 3, md: 5, '2xl': 7, '3xl': '35px' }}
    >
      {/* {(cardMock as unknown as Listing[])?.map((option, index) => (
        <ItemCard key={index} {...option} />
      ))} */}
    </SimpleGrid>
    {/* </Box> */}

    {/* // TODO make reuseable component when adding carousel logic */}
    {/* {!withShowMoreBtn && ( */}
    <HStack justify="center" mt={2.5} spacing={2.5} display={{ base: 'flex', md: 'none' }}>
      <Button size="auto" variant="none">
        <Circle size="10px" bg="bright.default" />
      </Button>
      <Button size="auto" variant="none">
        <Circle size="10px" bg="bright.200" />
      </Button>
      <Button size="auto" variant="none">
        <Circle size="10px" bg="bright.200" />
      </Button>
    </HStack>
    {/* )} */}

    {withShowMoreBtn && (
      <Button mt={8} display="flex" mx="auto" variant="light-outline">
        Show More
      </Button>
    )}
  </Box>
)
