import {
  HStack,
  Text,
  Box,
  Heading,
  Modal as ChakraModal,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react'
import { BottomAction } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { useRouter } from 'next/router'

export type AddFundsModalProps = Omit<ModalProps, 'children'>

export const AddFundsModal: React.FC<AddFundsModalProps> = ({ onClose, ...props }) => {
  const router = useRouter()

  return (
    <ChakraModal onClose={onClose} {...props}>
      <ModalOverlay display="block" />
      <ModalContent
        mt={{ base: '200px', lg: '250px' }}
        mx={4}
        pt={{ base: 8, sm: 6 }}
        pb={{ base: 10, md: 6 }}
        w="full"
        maxW={{ sm: '512px' }}
        bg="gray.800"
      >
        <Box px={6} textAlign="center">
          <Heading
            size="xl"
            fontSize={{ base: '24px', sm: '20px', lg: '24px' }}
            lineHeight={{ base: '29px', sm: '22px', lg: '29px' }}
            pb={6}
            borderBottom="1px"
            borderColor="whiteAlpha.500"
            w="full"
          >
            Add Funds
          </Heading>

          <Box mt="34px" color="gray.300" maxW="80%" mx="auto">
            <Text>
              To add funds to your balance, click continue. If you’d like to continue and select
              another method of payment, click cancel.
            </Text>
            <Text mt={2.5} fontWeight="bold">
              Are you sure you would like to leave checkout page?
            </Text>
          </Box>
        </Box>

        <BottomAction mt="34px" fixedOnScreen="sm">
          <HStack justifyContent="center" w="full" px={{ sm: 6 }} spacing={6}>
            <Button
              flexShrink={1}
              variant="light-outline"
              onClick={onClose}
              size="responsive-border"
            >
              Close
            </Button>
            <Button
              onClick={() => router.push('/wallet/deposits')}
              size="responsive"
              variant="light"
            >
              Continue
            </Button>
          </HStack>
        </BottomAction>
      </ModalContent>
    </ChakraModal>
  )
}
