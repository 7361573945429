import { extendTheme } from '@chakra-ui/react'

// Global style overrides
import styles from './styles'

// Foundational style overrides
import config from './foundations/config'
import fonts from './foundations/fonts'
import colors from './foundations/colors'
import space from './foundations/space'
import shadows from './foundations/shadows'
import breakpoints from './foundations/breakpoints'
import breakpointsV2 from './foundations/breakpointsV2'

// Component style overrides
import components from './components'

const themeProps = {
  config,
  fonts,
  styles,
  shadows,
  space,
  colors,
  components,
}
const theme = extendTheme({
  breakpoints,
  ...themeProps,
})

export const themeV2 = extendTheme({
  breakpointsV2,
  ...themeProps,
})

export default theme
