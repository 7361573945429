import { Accordian, RangeFilter, Span } from 'components/atoms'
import { useFilterMenu } from 'hooks/useFilterMenu'
import { checkIsExceedMaxDigits } from 'utils/globals'

export const MaxSupplyFilter = () => {
  const { singleSelectFilters, handleSingleSelectFilterChange } = useFilterMenu()

  return (
    <Accordian label="Max Supply" accordionPanelProps={{ p: 0 }}>
      <RangeFilter
        inputIcon={<Span mr={1} />}
        toInputProps={{
          value: singleSelectFilters.mintedTo.value,
          onChange({ target }) {
            const value = target.value ? +target.value : ''
            if (value === '' || (!checkIsExceedMaxDigits(+value) && +value > 0)) {
              handleSingleSelectFilterChange('mintedTo', value)
            }
          },
          min: 1,
          max: 1_000_000_000,
        }}
        fromInputProps={{
          value: singleSelectFilters.mintedFrom.value,
          onChange({ target }) {
            const value = target.value ? +target.value : ''
            if (value === '' || (!checkIsExceedMaxDigits(+value) && +value > 0)) {
              handleSingleSelectFilterChange('mintedFrom', value)
            }
          },
          min: 1,
        }}
        fromPlaceholder="0"
        toPlaceholder="MAX"
      />
    </Accordian>
  )
}
