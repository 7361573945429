import { ComponentStyleConfig } from '@chakra-ui/react'

const Modal: ComponentStyleConfig = {
  sizes: {
    xxl: {
      dialog: {
        width: '1000px',
      },
    },
    full: {
      dialog: {
        // minHeight: '-webkit-fill-available',
      },
    },
  },
}

export default Modal
