import { User } from 'types/vault/User'
import { Role } from 'types/vault/v2/Role'
import { PartnerAccount } from 'types/vault/v2/PartnerAccount'
import { UserRoles } from '../types/auth/Permissions'

type PermissionType = 'read' | 'write' | 'all'

export const getGamesWithReadPermissions = (permissions: string[] = []) =>
  Array.from(
    new Set(
      permissions
        .filter((permission) => /^game\.[^.]+\.read$|^game\.[^.]+\.all$/.test(permission))
        .map((permission) => permission.split('.')[1])
    )
  )

export const getPartnerAccountsWithReadPermissions = (permissions: string[] = []) =>
  Array.from(
    new Set(
      permissions
        .filter((permission) => /^account\.[^.]+\.read$|^account\.[^.]+\.all$/.test(permission))
        .map((permission) => permission.split('.')[1])
    )
  )

const isAdminAccountRole = (roleName: string) => /^account\.[^.]+\.admin$/.test(roleName)
const isGameMemberRole = (roleName: string) =>
  /^game\.[^.]+\.admin$|^game\.[^.]+\.member$/.test(roleName)

export const isGameRole = (role: Role) =>
  /^game\.[^.]+\.admin$|^game\.[^.]+\.member$/.test(role.name)

export const isAccountRole = (role: Role) => isAdminAccountRole(role.name)

export const isPlatformRole = (role: Role) =>
  /^platform\.[^.]+\.admin$|^platform\.[^.]+\.member$/.test(role.name)

export const getGameFromRole = (role: Role) =>
  isGameRole(role) ? role.name.split('.')[1] : undefined

export const getGamesFromRoles = (roles: Role[] = []) =>
  Array.from(new Set(roles.filter(isGameRole).map(getGameFromRole)))

export const getGameAccessLevelFromRole = (role: Role) =>
  isGameRole(role) ? role.name.split('.')[2] : undefined

export const getAccountFromRole = (role: Role) =>
  isAccountRole(role) ? role.name.split('.')[1] : undefined

export const getAccountRoles = (partnerAccount: PartnerAccount, roles: Role[] = []) =>
  roles.filter((role) => partnerAccount.roles.includes(role.name) || isPlatformRole(role))

const hasPermission = (user: User, permission: string) => user.permissions.includes(permission)

export const hasGamePermission = (user: User, gameSlug: string, type: PermissionType) =>
  hasPermission(user, `game.${gameSlug}.${type}`) || hasPermission(user, `game.${gameSlug}.all`)

export const hasPartnerPermission = (user: User, accountSlug: string, type: PermissionType) =>
  hasPermission(user, `account.${accountSlug}.${type}`) ||
  hasPermission(user, `account.${accountSlug}.all`)

export const hasPlatformPermission = (user: User, type: PermissionType) =>
  hasPermission(user, `platform.open-loot.${type}`) || hasPermission(user, 'platform.open-loot.all')
export const isOpenlootAdmin = (userRoles: string[] = []) => userRoles.includes(UserRoles.OL_ADMIN)
export const isTeamAdmin = (userRoles: string[] = []) =>
  userRoles.some((role) => isAdminAccountRole(role))
export const isTeamMember = (userRoles: string[] = []) =>
  userRoles.some((role) => isGameMemberRole(role))

export const canAccessPartnerPortal = (user: User) =>
  getGamesWithReadPermissions(user.permissions).length > 0 ||
  getPartnerAccountsWithReadPermissions(user.permissions).length > 0
