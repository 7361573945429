import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  ModalBody,
  ModalHeader,
  PinInput,
  PinInputField,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { useCopyToClipboard } from 'hooks/useCopyToClipboard'
import { useGet2FAURL } from 'hooks/useGet2FAURL'
import { useRegister2FAMutation } from 'hooks/useRegister2FAMutation'
import { useVerify2FAMutation } from 'hooks/useVerify2FAMutation'
import { useToast } from 'hooks/useToast'
import { Copy } from 'components/atoms/Icons/Copy'
import { totpUrlToKey } from 'utils/globals'
import { TwoFactorFlowProps } from './types'
import { TwoFactorModalContent } from './TwoFactorModalContent'

export const AppVerificationSetup = ({
  user,
  setFlow,
  skippable,
  onVerified,
  isOpen,
  onClose,
  setRecoveryCodes,
}: TwoFactorFlowProps) => {
  const { totpVerified } = user
  const [step, setStep] = useState<'qr-code' | 'verify'>('qr-code')
  const [authPayload, setAuthPayload] = useState('')
  const {
    mutate: register2FAFactor,
    isLoading: isRegistering,
    error,
    data: factor,
  } = useRegister2FAMutation()
  const { mutate: verify2FAFactor, isLoading: isVerifying } = useVerify2FAMutation()
  const toast = useToast()

  const isLoaded = !!totpVerified

  const { data: retrievedTotpUri } = useGet2FAURL({
    enabled: !factor?.uri && totpVerified === 'unverified',
  })

  const totpUrl = factor?.uri || retrievedTotpUri?.uri

  const onCopyClick = useCopyToClipboard(
    totpUrlToKey(totpUrl) || '',
    'Activation code copied to clipboard!'
  )

  useEffect(() => {
    setStep('qr-code')
  }, [isOpen])

  useEffect(() => {
    if (!totpVerified && !isRegistering && !error) {
      register2FAFactor(undefined, {
        onError: () => {
          toast({
            title: 'Error',
            description: 'Failed to register 2FA. Please retry later.',
            status: 'error',
          })
          onClose()
        },
      })
    }
  }, [totpVerified, isRegistering, register2FAFactor, onClose, toast, error])

  if (step === 'qr-code') {
    return (
      <TwoFactorModalContent
        onClose={onClose}
        isOpen={isOpen}
        mobileMarginTop={{ base: '174px', md: '0' }}
        desktopMarginTop={{ base: '0', md: '75px', lg: '125px' }}
        title="Two-Factor Authentication (2FA)"
        description="Configure Authentication"
        innerContent={
          <Flex display="flex" alignItems="center" flexDirection="column" p={0} mb="22px">
            <Text align="center" mt={5} color="gray.500">
              <Skeleton isLoaded={isLoaded} fadeDuration={0}>
                &#x2022; Open or Download an Authenticate App (Authy, Google Authenticator,
                Microsoft Authenticator, Last Pass,etc).
                <br />
                &#x2022; Open the App and Scan the QR code
              </Skeleton>
            </Text>
            <Skeleton isLoaded={isLoaded} my={5} fadeDuration={0}>
              <Box bgColor="white" p={2} borderRadius="md" w="fit-content">
                <QRCode value={totpUrl ?? ''} size={150} fgColor={totpUrl ? '' : '#bbb'} />
              </Box>
            </Skeleton>
            <Box width="full">
              <Skeleton isLoaded={isLoaded} fadeDuration={0}>
                <Flex direction="column" alignItems="center" w="full">
                  <Text pr={2}>If you can’t scan the QR code, </Text>
                  <Flex color="orange.400">
                    <Button
                      color="orange.400"
                      variant="unstyled"
                      rightIcon={<Copy height="16px" />}
                      onClick={() => onCopyClick()}
                    >
                      copy and paste
                    </Button>
                  </Flex>
                  <Text>the text code instead.</Text>
                </Flex>
              </Skeleton>
            </Box>
          </Flex>
        }
        buttons={
          <Skeleton
            fadeDuration={0}
            isLoaded={isLoaded}
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            mt={5}
            width="full"
          >
            {skippable ? (
              <Button flex={1} mr={1} variant="outline" onClick={onClose}>
                Set Up Later
              </Button>
            ) : null}
            <Button
              flex={1}
              ml={1}
              variant="light"
              onClick={() => setStep('verify')}
              maxW={{ base: '100%', md: '50%' }}
            >
              Continue
            </Button>
          </Skeleton>
        }
      />
    )
  }

  return (
    <TwoFactorModalContent
      onClose={onClose}
      isOpen={isOpen}
      mobileMarginTop={{ base: '174px', md: '0' }}
      desktopMarginTop={{ base: '0', md: '75px', lg: '125px' }}
      title="Two-Factor Authentication (2FA)"
      description="Configure Authentication"
      innerContent={
        <Flex display="flex" alignItems="center" flexDirection="column" p={0}>
          <Text align="center" mt={5}>
            Enter the 6-digit code from the App.
          </Text>
          <HStack w="full" justify="center" mt={5} mb={10}>
            <PinInput otp value={authPayload} onChange={setAuthPayload}>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
        </Flex>
      }
      buttons={
        <Flex flexDirection="row" alignItems="center" justifyContent="center" mt={5} width="full">
          {skippable ? (
            <Button flex={1} mr={1} variant="outline" onClick={onClose}>
              Set Up Later
            </Button>
          ) : null}
          <Button
            disabled={authPayload.length < 6 || isVerifying}
            isLoading={isVerifying}
            flex={1}
            ml={1}
            variant="light"
            maxW={{ base: '100%', md: '50%' }}
            onClick={() =>
              verify2FAFactor(
                { authPayload },
                {
                  onError: (err) => {
                    toast({
                      title: 'App setup failed',
                      description: (err.payload?.error ?? '') as string,
                      status: 'error',
                      duration: 5000,
                      isClosable: true,
                    })
                    if (err.code === 404) {
                      onClose()
                    }
                  },
                  onSuccess: (response) => {
                    if (response.recoveryCodes && setRecoveryCodes) {
                      setRecoveryCodes(response.recoveryCodes)
                    } else {
                      onVerified?.()
                      onClose()
                    }
                  },
                }
              )
            }
          >
            <span>Submit</span>
          </Button>
        </Flex>
      }
    />
  )
}
