import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Flex,
  Heading,
  Image,
  Button,
  Text,
  Divider,
} from '@chakra-ui/react'
import { useIsMobile } from 'hooks/useIsMobile'
import {
  useAckOpenlootNotification,
  useOpenlootNotifications,
} from 'hooks/useOpenlootNotifications'
import { useRouter } from 'next/router'

const OpenlootNotifications = () => {
  const { data: notifications } = useOpenlootNotifications()
  const { mutate: ackNotification } = useAckOpenlootNotification()
  const { pathname, push } = useRouter()
  const [isMobile] = useIsMobile()

  const safeNotifications = notifications || []

  const filterByPath = safeNotifications?.filter(
    (notif) => notif.type === 'popup' && pathname.includes(notif.popupConfig?.path || '/')
  )

  if (!filterByPath?.length) return null

  const showNotification = filterByPath[0]

  const { popupConfig, id } = showNotification

  const cta = popupConfig.cta || { label: 'Ok' }

  const onAck = (id: string, fromCTA?: boolean) => {
    ackNotification(id)
    if (fromCTA && cta.redirectTo) {
      push(cta.redirectTo)
    }
  }

  return (
    <Modal isOpen onClose={() => onAck(id)} size={isMobile ? 'lg' : 'xl'} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius={['0px', null, 'sm']} my={['0', null, '14']} bg="text-cards">
        <ModalHeader backgroundColor="#F56565" px="50px">
          <Flex direction="column" alignItems="center">
            <Image
              height="48px"
              cursor="pointer"
              src="/images/icons/close-outline.svg"
              alt="close"
              onClick={() => onAck(id)}
            />

            <Heading
              mt="20px"
              color="white"
              textAlign="center"
              fontFamily="Montserrat"
              fontSize="30px"
              fontWeight={700}
              flex="1"
            >
              {popupConfig.title}
            </Heading>
          </Flex>
        </ModalHeader>
        <Flex direction="column" alignItems="center">
          <Text
            mt="23px"
            color="white"
            textAlign="center"
            fontFamily="Bai Jamjuree"
            fontSize="20px"
            fontWeight={400}
            px="50px"
            dangerouslySetInnerHTML={{ __html: popupConfig.body }}
          />
          <Divider my="23px" />

          <Button
            my="20px"
            fontFamily="Montserrat"
            variant="light"
            onClick={() => onAck(id, true)}
            fontSize="16px"
            fontWeight={700}
            lineHeight="24px"
          >
            {cta.label}
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}

export default OpenlootNotifications
