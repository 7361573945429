import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import {
  CountryCode,
  formatIncompletePhoneNumber,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js'
import * as Yup from 'yup'
import examples from 'libphonenumber-js/examples.mobile.json'
import { BottomAction, Option } from 'components/atoms'
import { usePhoneVerificationSetupMutation } from 'hooks/usePhoneVerificationSetupMutation'
import { useToast } from 'hooks/useToast'
import { useState } from 'react'
import { all2FACountries } from '../../../lib/countries'
import { TwoFactorModalContent } from './TwoFactorModalContent'

export interface PhoneSetupModalProps {
  onClose: () => void
  onSuccess: () => void
  skippable?: boolean
  isOpen: boolean
}

const countries = getCountries()
const phoneNumberSchema = Yup.object().shape({
  countryCode: Yup.string().required('Required'),
  phoneNumber: Yup.string()
    .required('Required')
    .test('is-valid', 'Invalid phone number', (value, context) =>
      isValidPhoneNumber(value ?? '', context.parent.countryCode)
    ),
  phoneDuplicated: Yup.bool().oneOf(
    [false],
    'That phone number is already being used by another account.'
  ),
})

export const PhoneSetupModal = ({
  onSuccess,
  onClose,
  skippable,
  isOpen,
}: PhoneSetupModalProps) => {
  const { mutate: mutateSetup } = usePhoneVerificationSetupMutation()
  const [isDuplicatedPhone, setDuplicatedPhone] = useState<boolean>(false)

  const toast = useToast()
  return (
    <Formik
      validationSchema={phoneNumberSchema}
      initialValues={{ countryCode: '', phoneNumber: '', phoneDuplicated: '' }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        const phoneNumber = parsePhoneNumber(values.phoneNumber, values.countryCode as any)
        setDuplicatedPhone(false)

        mutateSetup(
          { phoneNumber: phoneNumber.format('E.164') },
          {
            onError: (error: any) => {
              if (error.code === 409) {
                setFieldValue('phoneDuplicated', true)
                setSubmitting(false)
              } else {
                toast({
                  title: 'Error',
                  description: `We couldn't update your phone number. Please retry in a few days.`,
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                })
              }
            },
            onSuccess: () => {
              onSuccess?.()
              toast({
                title: 'Success',
                description: 'Phone number has been saved',
                status: 'success',
                duration: 3000,
                isClosable: true,
              })
            },
          }
        )
      }}
    >
      {({
        values,
        isValid,
        dirty,
        submitForm,
        isSubmitting,
        getFieldProps,
        setFieldValue,
        errors,
        touched,
      }) => (
        <TwoFactorModalContent
          mobileMarginTop={{ base: '174px', md: '0' }}
          desktopMarginTop={{ base: '0', md: '75px', lg: '125px' }}
          onClose={onClose}
          isOpen={isOpen}
          description="Update your phone number"
          title="Two-Factor Authentication (2FA)"
          innerContent={
            <Form>
              <ModalBody>
                <Container px="7px">
                  <VStack mt="8" mb="4">
                    <Text lineHeight="base" textAlign="center">
                      Two-Factor Authentication is an enhanced security measure. Once enabled,
                      you’ll be required to provide two forms of verification to perform
                      transactions.
                    </Text>
                    <HStack spacing="3" pt="30px" align="flex-start">
                      <FormControl
                        isRequired
                        isInvalid={!!errors.countryCode && !!touched.countryCode}
                      >
                        <FormLabel>Prefix</FormLabel>
                        <Select
                          value={values.countryCode}
                          onChange={(e) => setFieldValue('countryCode', e.target.value)}
                        >
                          <Option value={undefined}>Select Country</Option>
                          {all2FACountries
                            .filter(({ countryCode }) => countries.includes(countryCode as any))
                            .map(({ country, countryCode }) => (
                              <Option value={countryCode} key={countryCode}>
                                {country} +{getCountryCallingCode(countryCode as CountryCode)}
                              </Option>
                            ))}
                        </Select>
                        <FormErrorMessage>{errors.countryCode}</FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isRequired
                        isInvalid={!!errors.phoneNumber && !!touched.phoneNumber}
                      >
                        <FormLabel whiteSpace="nowrap">Phone number</FormLabel>
                        <Input
                          type="tel"
                          placeholder={getExampleNumber(
                            values.countryCode as any,
                            examples
                          )?.formatNational()}
                          {...getFieldProps('phoneNumber')}
                          onChange={(e) =>
                            setFieldValue(
                              'phoneNumber',
                              formatIncompletePhoneNumber(e.target.value, values.countryCode as any)
                            )
                          }
                        />
                        <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                      </FormControl>
                    </HStack>
                    <FormControl pt="10px" isInvalid={Boolean(errors.phoneDuplicated)}>
                      <FormErrorMessage justifyContent="center">
                        {errors.phoneDuplicated}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                </Container>
              </ModalBody>
            </Form>
          }
          buttons={
            <BottomAction>
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                mt={5}
                width="full"
              >
                {skippable && (
                  <Button onClick={onClose} variant="outline" flex={1} mr={1}>
                    Set up later
                  </Button>
                )}
                <Button
                  isLoading={isSubmitting}
                  flex={1}
                  ml={1}
                  maxW="50%"
                  variant="light"
                  onClick={submitForm}
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  <span>Continue</span>
                </Button>
              </Flex>
            </BottomAction>
          }
        />
      )}
    </Formik>
  )
}
