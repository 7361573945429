import { border, Tag, TagLabel } from '@chakra-ui/react'

interface SaleItemCardTagProps {
  label?: string
  fillColor?: string
  labelColor?: string
  borderColor?: string
}

export const SaleItemCardTag = ({
  label,
  fillColor,
  labelColor,
  borderColor,
}: SaleItemCardTagProps) => (
  <Tag
    size="md"
    borderRadius="full"
    align="center"
    fontSize={12}
    padding="6px 10px"
    fontWeight="Bold"
    backgroundColor={fillColor}
    variant="solid"
    position="absolute"
    marginLeft="8px"
    marginTop="12px"
    alignItems="flex-start"
    border="1px"
    borderColor={borderColor}
    zIndex="banner"
  >
    <TagLabel color={labelColor}>{label}</TagLabel>
  </Tag>
)
