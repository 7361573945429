import { ErrorPage } from 'components/templates'

const FourOhFour = () => (
  <ErrorPage
    imageSrc="/images/img_broken-clockie.png"
    heading="Page Not Found"
    content="We can't find the page you're looking for."
  />
)

export default FourOhFour
