import { Image, HStack, UnorderedList, Link, ListItem, Text, Heading, Box } from '@chakra-ui/react'
import { Button, ButtonLink } from 'components/atoms'
import { useAnyFeature } from 'hooks/useFeature'
import { useLogoutMutation } from 'hooks/useLogoutMutation'
import { formatMoney } from 'lib/format'

interface MobileUserMenuProps {
  onClose: () => void
  username: string
  balance: number
  navItems: {
    name: string
    url: string
  }[]
}

export const MobileUserMenu: React.FC<MobileUserMenuProps> = ({
  onClose,
  username,
  balance,
  navItems,
}) => {
  const { mutate } = useLogoutMutation()
  const hasDeposit = useAnyFeature(['depositCrypto', 'depositWire'])

  const handleLogout = () => {
    mutate()
    onClose()
  }

  return (
    <Box px={5} pt="13px" bg="white" pos="relative" color="menu-content">
      <Button size="auto" variant="none" pos="absolute" top="17px" right="21px" onClick={onClose}>
        <Image src="/images/icons/close.svg" alt="close icon" />
      </Button>
      <Box textAlign="center">
        <Heading as="h4" fontSize="14px" lineHeight="14px">
          {username}
        </Heading>
        <Link href="/wallet" passHref>
          <Text
            mt="7px"
            size="lg"
            cursor="pointer"
            _hover={{
              fontWeight: 500,
            }}
            onClick={onClose}
          >
            {formatMoney(balance)}
          </Text>
        </Link>
        {hasDeposit && (
          <ButtonLink
            href="/wallet/deposits"
            mt="7px"
            variant="dark"
            color="white"
            size="sm"
            py="13px"
            onClick={onClose}
            _hover={{
              color: 'whiteAlpha.700',
            }}
          >
            Add Funds
          </ButtonLink>
        )}
      </Box>
      <UnorderedList listStyleType="none" m={0} mt={3}>
        {navItems.map(({ name, url }) => (
          <Link
            href={url}
            _focus={{ boxShadow: 'none' }}
            _hover={{ fontWeight: 'bold' }}
            transition="all 0.2s"
            key={name}
            passHref
          >
            <ListItem py={3} borderTop="1px" borderColor="bright.default" onClick={onClose}>
              <Text as="a">{name}</Text>
            </ListItem>
          </Link>
        ))}
      </UnorderedList>

      <HStack justifyContent="space-between" py={3} borderTop="1px" borderColor="bright.default">
        <Button
          variant="none"
          size="auto"
          fontSize="xs"
          color="gray.500"
          onClick={handleLogout}
          _hover={{ color: 'gray.700' }}
        >
          Log Out
        </Button>
        <Button variant="none" size="auto" fontSize="xs" color="gray.500" onClick={handleLogout}>
          <Image src="/images/icons/logout.svg" alt="logout icon" />
        </Button>
      </HStack>
    </Box>
  )
}
