import { Box, Divider, Flex, Link, ModalBody, ModalHeader, Text } from '@chakra-ui/react'
import { FAQ_LINK } from '../../../constants'
import { TwoFactorFlowProps } from './types'
import BoxButton from '../../atoms/BoxButton'
import { PhoneAuth } from '../../atoms/Icons/PhoneAuth'
import { AppAuth } from '../../atoms/Icons/AppAuth'
import { TwoFactorModalContent } from './TwoFactorModalContent'

type FlowSelectorProps = Pick<TwoFactorFlowProps, 'setFlow' | 'isOpen' | 'onClose'>

export const FlowSelector = ({ setFlow, isOpen, onClose }: FlowSelectorProps) => (
  <TwoFactorModalContent
    isOpen={isOpen}
    onClose={onClose}
    title="Two-Factor Authentication (2FA)"
    mobileMarginTop={{ base: '174px', md: '0' }}
    desktopMarginTop={{ base: '0', md: '75px', lg: '125px' }}
    innerContent={
      <Flex p={{ base: 0, sm: 5 }} alignItems="stretch" direction="column">
        <Text align="center" my={5}>
          2FA adds an additional layer of security to your account. Though you can explore the
          marketplace without it, 2FA is required for purchasing, selling, and the withdrawal of
          funds.
          <br />
        </Text>
        <Link
          href={FAQ_LINK}
          textDecor="underline"
          target="_blank"
          alignSelf="center"
          textAlign="center"
          display="none"
        >
          More information about 2FA
        </Link>
        <BoxButton
          icon={<PhoneAuth height="60%" />}
          onClick={() => setFlow('phone')}
          mt={5}
          gray
          size="full"
        >
          <Text color="white">Phone Verification</Text>
        </BoxButton>
        <BoxButton
          icon={<AppAuth height="50%" />}
          onClick={() => setFlow('app')}
          mt={5}
          gray
          size="full"
        >
          <Text color="white">Authenticator App</Text>
        </BoxButton>
      </Flex>
    }
  />
)
