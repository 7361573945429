export class ServiceError {
  code: number

  message: string

  details: {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
    url: string
    fullUrl: string
  }

  payload?: Record<string, unknown>

  constructor(
    code: number,
    message: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    url: string,
    fullUrl: string,
    payload?: Record<string, unknown>
  ) {
    this.code = code
    this.message = message
    this.details = {
      method,
      url,
      fullUrl,
    }
    this.payload = payload
  }

  setPayload(payload: Record<string, unknown>) {
    this.payload = payload
  }
}
