import { Accordian, Checkbox } from 'components/atoms'
import { useFilterMenu } from 'hooks/useFilterMenu'
import { Flex, HStack, Text } from '@chakra-ui/react'
import { parseBoolean } from '../../../utils/globals'

export const OnSaleFilter: React.FC = () => {
  const { singleSelectFilters, handleSingleSelectFilterChange } = useFilterMenu()
  const onSaleChecked = parseBoolean(singleSelectFilters.onSale?.value)
  const nonOnSaleChecked = parseBoolean(singleSelectFilters.nonOnSale?.value)

  return (
    <Accordian label="Active sales" accordionPanelProps={{ p: 0 }}>
      <Flex direction="column" gridGap="6px">
        <Checkbox
          key="has-active-sales"
          type="label-check"
          isChecked={onSaleChecked}
          onChange={() => {
            handleSingleSelectFilterChange(
              'onSale',
              !parseBoolean(singleSelectFilters.onSale.value)
            )
          }}
          cursor="pointer"
        >
          <HStack justifyContent="space-between" flex={1} mr={2.5} p="10px 0px">
            <Text
              fontFamily="Bai Jamjuree"
              fontStyle="normal"
              fontWeight={onSaleChecked ? '700' : '400'}
              fontSize="16px"
              lineHeight="20px"
            >
              Has Active Sales
            </Text>
          </HStack>
        </Checkbox>
        <Checkbox
          key="non-active-sales"
          type="label-check"
          isChecked={nonOnSaleChecked}
          onChange={() => {
            handleSingleSelectFilterChange(
              'nonOnSale',
              !parseBoolean(singleSelectFilters.nonOnSale.value)
            )
          }}
          cursor="pointer"
        >
          <HStack justifyContent="space-between" flex={1} mr={2.5}>
            <Text
              fontFamily="Bai Jamjuree"
              fontStyle="normal"
              fontWeight={nonOnSaleChecked ? '700' : '400'}
              fontSize="16px"
              lineHeight="20px"
            >
              No Active Sales
            </Text>
          </HStack>
        </Checkbox>
      </Flex>
    </Accordian>
  )
}
