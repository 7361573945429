import Button from './button'
import Modal from './modal'
import Heading from './heading'
import Text from './text'

const components = {
  Modal,
  Button,
  Heading,
  Text,
}

export default components
