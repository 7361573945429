import { Heading as ChakraHeading, HeadingProps as ChakraHeadingProps } from '@chakra-ui/react'

interface HeadingProps extends ChakraHeadingProps {
  bai?: boolean
  children?: React.ReactNode
}

export const Heading: React.FC<HeadingProps> = ({ bai, children, ...props }) => (
  <ChakraHeading fontFamily={bai ? 'Bai Jamjuree' : undefined} {...props}>
    {children}
  </ChakraHeading>
)
