import {
  Box,
  MenuButtonProps,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  forwardRef,
} from '@chakra-ui/react'
import { Button } from '../Button'
import { ArrowDown } from '../Icons/ArrowDown'
import { ArrowUp } from '../Icons/ArrowUp'

const MenuButtonWrapper = forwardRef((props, ref) => (
  <Button size="auto" variant="none" ref={ref} {...props} />
))

type OptionType = {
  name: string
  value: unknown
  isSelected?: boolean
}

interface DropdownMenuProps {
  label: string
  value?: string
  options: OptionType[]
  onChange: (value: unknown) => void
  menuBtnProps?: MenuButtonProps
}

export const DropdownButtonMenu: React.FC<DropdownMenuProps> = ({
  label,
  value,
  options,
  onChange,
  menuBtnProps,
}) => {
  const activeOption = options.find(
    ({ value: optionValue, isSelected }) => optionValue === value || isSelected
  )

  return (
    <Menu>
      {({ isOpen }) => (
        <Box
          pos="relative"
          sx={{
            '> div': {
              transform: 'none !important',
              top: '100% !important',
              overflow: 'hidden',
              borderTop: '1px solid',
              borderColor: 'gray.300',
              w: 'full',
            },
          }}
        >
          <MenuButton
            sx={{ '.chakra-button__icon': { mr: 5 } }}
            color="white"
            as={MenuButtonWrapper}
            rightIcon={isOpen ? <ArrowUp width="10px" /> : <ArrowDown width="10px" />}
            border="1px solid"
            borderColor="white"
            w="full"
            h="38px"
            aria-haspopup="true"
            aria-expanded={Boolean(activeOption)}
            aria-label={activeOption ? activeOption.name : label}
            {...menuBtnProps}
          >
            <Text
              fontFamily="heading"
              fontWeight={700}
              fontSize={{ base: '0.875rem', sm: '1rem' }}
              lineHeight="1.5rem"
            >
              {activeOption ? activeOption.name : label}
            </Text>
          </MenuButton>
          <MenuList
            transform="none"
            border="none"
            borderRadius="none"
            bg="white"
            color="menu-content"
            opacity={0.9}
            px={5}
            pt={3}
            pb={2.5}
            top="38px"
            minW="unset"
            w="full"
            maxH="280px"
            overflow="auto"
          >
            {options.map(({ name, value: optionValue, isSelected }, i) => {
              const isLastOption = i === options.length - 1
              const isActive = optionValue === value || isSelected

              return (
                <MenuItem
                  key={i}
                  cursor="pointer"
                  fontSize="sm"
                  fontWeight={isActive ? 400 : 500}
                  fontFamily="heading"
                  px={0}
                  pt={0}
                  pb={isLastOption ? 0 : 3}
                  mb={isLastOption ? 0 : 3}
                  borderBottom={isLastOption ? 'none' : '1px solid'}
                  borderColor="bright.default"
                  as="a"
                  onClick={() => onChange(optionValue)}
                  aria-selected={isActive ? 'true' : 'false'}
                  aria-label={name}
                >
                  {name}
                </MenuItem>
              )
            })}
          </MenuList>
        </Box>
      )}
    </Menu>
  )
}
