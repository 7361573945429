import { Box, BoxProps, Portal } from '@chakra-ui/react'

interface BottomActionProps extends BoxProps {
  children: string | React.ReactNode
  fixedOnScreen?: 'sm' | 'md' | 'lg'
  inPortal?: boolean
}

const Main: React.FC<BottomActionProps> = ({ children, fixedOnScreen = 'md', ...rest }) => (
  <Box
    // boxShadow={{ base: '0px -4px 10px 0px #0000001A;', md: 'none' }}
    __css={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
    w="full"
    pos={{ base: 'fixed', [fixedOnScreen]: 'static' }}
    bg={{ base: 'bg-footer', [fixedOnScreen]: 'none' }}
    px={{ base: 2.5, xs: 4, [fixedOnScreen]: 0 }}
    py={{ base: 2.5, xs: 4, [fixedOnScreen]: 0 }}
    insetX={0}
    bottom={0}
    backdropFilter="blur(5px)"
    zIndex={{ base: 'banner', md: 'base' }}
    {...rest}
  >
    {children}
  </Box>
)

export const BottomAction: React.FC<BottomActionProps> = ({ inPortal, ...rest }) => (
  <>
    {inPortal ? (
      <Portal>
        <Main {...rest} />
      </Portal>
    ) : (
      <Main {...rest} />
    )}
  </>
)
