import { Box, Flex, Heading, HStack, LinkBox, LinkOverlay, Text } from '@chakra-ui/react'
import { RarityBadge, Video } from 'components/atoms'
import { getItemVideoUrl } from 'lib/image'
import Link from 'next/link'
import { ListingItemMetadata } from 'types/vault/v2/ItemMetadata'
import { formatVisualListItemPrice } from './marketplace/utils'

interface ItemCardProps {
  minPrice: number
  metadata: ListingItemMetadata
}

export const ItemCard: React.FC<ItemCardProps> = ({ minPrice, metadata }) => {
  const { imageUrl, videoUrl, rarity, name, maxIssuance, game, optionName, collection } =
    metadata || {}

  return (
    <Link href={`/items/${collection}/${optionName}`} passHref>
      <LinkBox>
        <Flex
          flexDir="column"
          h="full"
          _hover={{
            '.item-card__img': {
              transform: 'scale(1.1)', // Scale the item by 110%
            },
          }}
        >
          <Box w="100%" overflow="hidden" sx={{ aspectRatio: '1' }}>
            <Video
              src={getItemVideoUrl(collection, optionName, videoUrl)}
              poster={imageUrl}
              title={name}
              w="full"
              h="full"
              objectPosition="center"
              objectFit="cover"
              className="item-card__img"
              transition="all .3s"
            />
          </Box>
          <Flex flexDir="column" bg="text-cards" p={4} flex={1}>
            <Flex flexDir="column" justifyContent="space-between" flex={1}>
              <LinkOverlay href={`/items/${collection}/${optionName}`}>
                <Heading size="md">{name}</Heading>
              </LinkOverlay>
              {rarity && <RarityBadge rarity={rarity} title={rarity} mt={5} />}
            </Flex>
            <Box mt={5}>
              <Text size="sm" color="whiteAlpha.600">
                Starting at
              </Text>
              <Text size="md" mt={1} fontFamily="heading" fontWeight="bold">
                {formatVisualListItemPrice(minPrice, minPrice > 0)}
              </Text>
            </Box>
            <HStack mt={5} spacing={2}>
              <Text size="sm" color="whiteAlpha.600">
                Max Supply
              </Text>
              <Text size="sm" lineHeight="12px" fontFamily="heading" fontWeight="bold">
                {maxIssuance}
              </Text>
            </HStack>
            <Text mt={5} size="sm" lineHeight="12px" fontFamily="heading" fontWeight="bold">
              {game?.name}
            </Text>
          </Flex>
        </Flex>
      </LinkBox>
    </Link>
  )
}
