import {
  Text,
  useCheckbox,
  Flex,
  TextProps,
  UseCheckboxProps,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'

interface LabelCheckProps extends UseCheckboxProps, Omit<TextProps, keyof UseCheckboxProps> {
  children?: React.ReactNode
}

interface CheckBoxProps extends LabelCheckProps, Omit<ChakraCheckboxProps, keyof LabelCheckProps> {
  type?: 'label-check' | 'box-check'
}

const BoxCheck: React.FC<ChakraCheckboxProps> = ({ variant = 'dark', children, ...props }) => (
  <ChakraCheckbox
    iconColor={variant === 'light' ? 'text-cards' : 'white'}
    sx={{
      '.chakra-checkbox__control': {
        border: '2px',
        borderColor: 'gray.200',
        borderRadius: '2px',
      },
      '.chakra-checkbox__control[data-checked], .chakra-checkbox__control[data-checked][data-hover]':
        {
          bg: variant === 'light' ? 'white' : 'blackAlpha.800',
          borderColor: variant === 'light' ? 'white' : 'title-field',
        },
    }}
    {...props}
  >
    {children}
  </ChakraCheckbox>
)

const LabelCheck: React.FC<LabelCheckProps> = ({ children, ...props }) => {
  const { state, getCheckboxProps, getInputProps, htmlProps } = useCheckbox(props)

  return (
    <Text
      as="label"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      rounded="lg"
      cursor="pointer"
      fontWeight={state.isChecked ? 'bold' : 'normal'}
      _hover={{ fontWeight: 'bold' }}
      {...htmlProps}
      {...props}
    >
      <input {...getInputProps()} hidden />
      {children}
      <Flex alignItems="center" justifyContent="center" {...getCheckboxProps()} w={2.5} h={2.5}>
        {state.isChecked && <CheckIcon w={3} />}
      </Flex>
    </Text>
  )
}

export const Checkbox: React.FC<CheckBoxProps> = ({ children, type = 'box-check', ...props }) => {
  if (type === 'box-check') return <BoxCheck {...props}>{children}</BoxCheck>

  return <LabelCheck {...props}>{children}</LabelCheck>
}
