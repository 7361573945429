import {
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { TwoFactorModalContent } from './TwoFactorModalContent'

export interface RecoveryCodesModalProps {
  recoveryCodes: string[]
  onContinue: () => void
  isOpen: boolean
}

export const RecoveryCodesModal = (props: RecoveryCodesModalProps) => {
  const { recoveryCodes, onContinue, isOpen } = props

  const [url, setUrl] = useState<string>('')

  const downloadCodes = () => {
    const blob = new Blob([recoveryCodes.join('\n')], { type: 'text/plain' })
    setUrl(URL.createObjectURL(blob))
  }

  let downloadLink: HTMLAnchorElement | null = null

  useEffect(() => {
    if (url.length) {
      downloadLink = document.createElement('a')
      downloadLink.download = 'recovery-codes.txt'
      downloadLink.href = url
      downloadLink.click()
    }
  }, [url])

  return (
    <TwoFactorModalContent
      desktopMarginTop={{ base: '0', md: '80px' }}
      mobileMarginTop={{ base: '80px', md: '0' }}
      onClose={onContinue}
      isOpen={isOpen}
      title="Two-Factor Authentication (2FA)"
      innerContent={
        <Flex alignItems="center" flexDirection="column" p={0}>
          <Text align="center" mt={5} fontSize="24px" fontWeight="600" color="white">
            Account Recovery Codes
          </Text>
          <Text align="center" mt={5} mb={5}>
            Use recovery codes to log in if you lose your phone or can’t receive a verification code
            via text message or an authentication app.
          </Text>
          {recoveryCodes.map((code) => (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mt={4}
              key={`code-${code}`}
              width="full"
            >
              <Flex verticalAlign="center" flexDirection="row" alignItems="center" height="100%">
                <Text
                  align="center"
                  fontSize="20px"
                  lineHeight="30px"
                  fontWeight="400"
                  letterSpacing="0.31em"
                  height="100%"
                >
                  {code.substring(0, code.length / 2)}
                </Text>
                <Text
                  align="center"
                  ml="20px"
                  fontSize="20px"
                  lineHeight="30px"
                  fontWeight="400"
                  letterSpacing="0.31em"
                  height="100%"
                >
                  {code.substring(code.length / 2)}
                </Text>
              </Flex>
              <Divider mt={4} alignSelf="bottom" />
            </Flex>
          ))}
        </Flex>
      }
      buttons={
        <Flex flexDirection="row" alignItems="center" justifyContent="flex-end" mt={5} width="full">
          <Button
            flex={1}
            mt="32px"
            width="86px"
            py={3}
            variant="outline"
            onClick={() => downloadCodes()}
          >
            Download
          </Button>
          <Button
            flex={1}
            ml={3}
            mt="32px"
            width="86px"
            py={3}
            variant="light"
            onClick={() => onContinue()}
          >
            Continue
          </Button>
        </Flex>
      }
    />
  )
}
