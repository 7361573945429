import BackendApi from 'lib/api/BackendApi'
import { useQuery } from 'react-query'
import { Token } from 'types/vault/v2/Token'
import { QueryResult } from '../types/QueryResult'

export const fetchTokenList = (): Promise<QueryResult<Token>> =>
  BackendApi.doRequest({
    method: 'GET',
    url: '/v2/market/tokens',
  }).then((res) => res.json())

export const useTokenList = ({ options = {} }) => useQuery(['user_tokens'], fetchTokenList, options)
