/* eslint-disable */
import { Token } from '@chakra-ui/styled-system/dist/types/utils'
/* eslint-disable */
import * as CSS from 'csstype'
import { Modal } from '../Modal'

export interface TwoFactorModalContentProps {
  innerContent: JSX.Element
  buttons?: JSX.Element
  title?: string
  description?: string
  onClose: () => void
  isOpen: boolean
  children?: React.ReactNode;
  mobileMarginTop?: Token<CSS.Property.Margin | number>
  desktopMarginTop?: Token<CSS.Property.Margin | number>
}

export const TwoFactorModalContent = (props: TwoFactorModalContentProps) => {
  const { isOpen, onClose, innerContent, buttons, title, description, mobileMarginTop, desktopMarginTop } = props

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      buttons={buttons}
      title={title}
      description={description}
      marginTopForContent={mobileMarginTop}
      marginTop={desktopMarginTop}
      smallerTitle
      showOverlayInMobile
    >
      {innerContent}
    </Modal>
  )
}