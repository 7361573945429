interface ArrowDownProps {
  width?: string
  height?: string
  color?: string
}

export const ArrowDown = ({ width = '13', height = '8', color = 'white' }: ArrowDownProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.36401 4.95L11.314 0L12.728 1.414L6.36401 7.778L1.22027e-05 1.414L1.41401 0L6.36401 4.95Z"
      fill={color}
    />
  </svg>
)
