import { Text, TextProps } from '@chakra-ui/react'

interface ExternalLinkProps extends TextProps {
  href: string
  target?: string
  label?: string
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({
  href,
  target = '_blank',
  label,
  ...props
}) => (
  <Text
    as="a"
    href={href}
    target={target}
    rel={target === '_blank' ? 'noreferrer' : undefined}
    {...props}
  >
    {label ?? href}
  </Text>
)
