import { NextRouter } from 'next/router'
import { ParsedUrlQueryInput } from 'querystring'
import { InfiniteData } from 'react-query'
import { Item } from 'types/vault/v2/Item'
import { Paginated } from 'types/vault/v2/Paginated'
import { SwapQuote } from 'types/vault/v2/Swap'
import { EU_COUNTRY_CODES, MIN_USER_AGE } from '../constants'

interface HandleRouterUrlProps {
  router: NextRouter
  queryObj?: null | ParsedUrlQueryInput
  removeQueryKeys?: string[]
  shouldPush?: boolean
}

export const handleRouterUrl = ({
  router,
  queryObj = {},
  removeQueryKeys = [],
  shouldPush = true,
}: HandleRouterUrlProps) => {
  const query = { ...router.query, ...queryObj }

  removeQueryKeys.forEach((qk) => {
    delete query[qk]
  })
  const url = {
    pathname: router.pathname,
    query,
  }

  if (shouldPush) {
    router.push(url)
  }

  return url
}

export function getItemIdentifier(item: Item): string {
  return `${item.metadata.optionName}-${item.issuedId}`
}

export const formatHtmlTitle = (pageName: string) => `${pageName} | Open Loot`

export const totpUrlToKey = (url?: string) => {
  if (!url) return null

  const regex = /.*?secret=(.*?)&/
  const match = url.match(regex)
  return match ? match[1] : null
}

export const checkIsExceedMaxDigits = (num: number, maxDigits = 10) =>
  num.toString().length > maxDigits

export const truncExceedMaxLength = (word: string, maxLength = 10) =>
  word.length > maxLength ? `${word.slice(0, maxLength)}...` : word

export function parseBoolean(value: boolean | string): boolean {
  if (value && typeof value === 'string') {
    return value === 'true'
  }
  if (value && typeof value === 'boolean') {
    return value
  }
  return false
}

export function isEUCountryCode(countryCode: string): boolean {
  return EU_COUNTRY_CODES.includes(countryCode)
}

export const checkIsItemUnsellable = (item: Item) => {
  if (item?.metadata.game.id === '8c6bfff6-98fa-435d-bd18-c58a8ef02abe') return false
  if (typeof item.metadata.sellable === 'boolean' && !item.metadata.sellable) return true

  const itemSellableAtTimestamp = new Date(item.sellableAt).getTime()
  const nowTimestamp = new Date().getTime()

  return itemSellableAtTimestamp >= nowTimestamp
}

export const checkIsItemWithdrawable = (item: Item) => {
  // If withdrawableDate extra field is null, consider the item is withdrawable
  if (!item.extra?.withdrawableDate) return true

  const itemWithdrawableDateTimestamp = new Date(item.extra.withdrawableDate).getTime()
  const nowTimestamp = new Date().getTime()

  return itemWithdrawableDateTimestamp <= nowTimestamp
}

export const roundDownToDigits = (value: number, digits = 2): number => {
  const factor = 10 ** digits
  return Math.floor(value * factor) / factor
}

export const generateCode = (length = 9) => {
  const alphaCount = Math.floor(length * 0.5)
  const numericCount = Math.ceil(length * 0.3)
  const specialCharCount = length - alphaCount - numericCount

  let result = ''

  const alphaChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const numericChars = '0123456789'
  const specialChars = '!_#$%'

  // Generate alpha characters
  for (let i = 0; i < alphaCount; i += 1) {
    result += alphaChars.charAt(Math.floor(Math.random() * alphaChars.length))
  }

  // Generate numeric characters
  for (let i = 0; i < numericCount; i += 1) {
    result += numericChars.charAt(Math.floor(Math.random() * numericChars.length))
  }

  // Generate special characters
  for (let i = 0; i < specialCharCount; i += 1) {
    result += specialChars.charAt(Math.floor(Math.random() * specialChars.length))
  }

  // Shuffle the characters randomly
  let code = ''
  while (result.length > 0) {
    const randomIndex = Math.floor(Math.random() * result.length)
    code += result.charAt(randomIndex)
    result = result.substring(0, randomIndex) + result.substring(randomIndex + 1)
  }

  return code
}

export const subtractYearsFromCurrDate = (years: number) =>
  new Date(new Date().setFullYear(new Date().getFullYear() - years))

// under 18 ranges from 18 years to min age
export const isUnder18Date = (date: Date) => {
  const dateMinAgeYearsAgo = subtractYearsFromCurrDate(MIN_USER_AGE)
  const date18YearsAgo = subtractYearsFromCurrDate(18)

  // under 18 if date is after '18 years ago date' and before MIN_AGE Date
  return date > date18YearsAgo && date < dateMinAgeYearsAgo
}

export function getErrorMessage(err: any, defaultErrorMsg = 'Something went wrong') {
  let errorMessage = defaultErrorMsg

  if (err.code === 500) return errorMessage

  if (err.code === 429 && err.message?.includes('Too Many Requests'))
    return 'There was an error with your purchase, due too high load. Try again later.'

  if (err.code === 402 && err.payload?.message.includes('RiskifiedRejectedError'))
    return 'We were not able to process your payment, please try again with another payment method.'

  errorMessage = err.payload?.message ?? err.message ?? errorMessage

  return errorMessage
}

export function getRandomNumber(max: number) {
  return Math.floor(Math.random() * (max + 1))
}

export function flatPaginationData<T>(data?: InfiniteData<Paginated<T>>) {
  return data?.pages.flatMap((page) => page.items) ?? []
}

export const getDateOneMonthAgo = (date: Date) => {
  date.setMonth(date.getMonth() - 1)
  date.setHours(0, 0, 0, 0)
  return date
}

export const roundUpToTwoDigits = (value: number): number =>
  Number(`${Math.ceil(`${value}e2` as any)}e-2`)

export const isOLToken = (tokenSymbol: string) => tokenSymbol === 'OPENLOOT'

export const calculateSwapFeePercentage = (swapQuote: SwapQuote) =>
  roundUpToTwoDigits(100 * (1 - swapQuote.swapRate / swapQuote.marketRate))
