import { useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { TwoFactorModal } from '../TwoFactorModal'
import { FlowProperties, getAvailableFlowsFromUser } from '../types'
import { useUser } from '../../../../hooks/useUser'
import { useToast } from '../../../../hooks/useToast'
import { User } from '../../../../types/vault/User'

const userHas2FA = (user: User) =>
  user.totpVerified === 'verified' || user.phoneNumberStatus === 'validated'

export const Login2FAModal = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false })
  const { data: user, status } = useUser()
  const [shouldOpenModal, setShouldOpenModal] = useLocalStorage<boolean>(
    'should-open-MFA-modal',
    false
  )
  const [flowProperties, setFlowProperties] = useState<FlowProperties>({
    flow: 'phone',
    availableFlows: [],
  })
  useEffect(() => {
    if (!user && status === 'success') {
      setShouldOpenModal(true)
    }
  }, [user, status, setShouldOpenModal])

  useEffect(() => {
    if (user && shouldOpenModal && !userHas2FA(user)) {
      setShouldOpenModal(false)
      setFlowProperties(getAvailableFlowsFromUser(user))
      onOpen()
    }
  }, [user, onOpen])

  if (!user) {
    return null
  }

  return (
    <TwoFactorModal
      skippable
      isOpen={isOpen}
      user={user}
      onVerified={() => {
        toast({
          title: 'Verified',
          description: 'Two-factor authentication was successful',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        onClose()
      }}
      onClose={onClose}
      {...flowProperties}
    />
  )
}
