interface AppAuthProps {
  width?: string
  height?: string
}

export const AppAuth = ({ width = '1em', height = '1em' }: AppAuthProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.51231 8.7321C2.84703 8.23469 6.50632 7.23987 10.4657 7.23987C14.4251 7.23987 18.1176 8.23469 19.4689 8.7321C19.9331 12.6865 18.8073 21.3763 10.5901 24.5C2.38278 21.2171 1.08951 13.4078 1.51231 8.7321ZM12.7538 13.1092C12.7538 13.9102 12.3377 14.614 11.7099 15.0162L12.1571 18.3819H8.87416L9.23003 14.9892C8.62528 14.5829 8.22733 13.8925 8.22733 13.1092C8.22733 11.8593 9.24061 10.846 10.4906 10.846C11.7405 10.846 12.7538 11.8593 12.7538 13.1092Z"
      fill="white"
      fillOpacity="0.25"
    />
    <path
      d="M1.51231 8.7321L1.3377 8.26358L1.04269 8.37352L1.01434 8.68707L1.51231 8.7321ZM19.4689 8.7321L19.9655 8.6738L19.9297 8.36892L19.6416 8.26288L19.4689 8.7321ZM10.5901 24.5L10.4044 24.9643L10.5854 25.0367L10.7677 24.9674L10.5901 24.5ZM11.7099 15.0162L11.4401 14.5952L11.1724 14.7668L11.2142 15.0821L11.7099 15.0162ZM12.1571 18.3819V18.8819H12.7279L12.6527 18.316L12.1571 18.3819ZM8.87416 18.3819L8.37688 18.3297L8.31896 18.8819H8.87416V18.3819ZM9.23003 14.9892L9.7273 15.0414L9.7587 14.742L9.50886 14.5742L9.23003 14.9892ZM10.4657 6.73987C6.43615 6.73987 2.71745 7.74939 1.3377 8.26358L1.68691 9.20062C2.97661 8.71999 6.57649 7.73987 10.4657 7.73987V6.73987ZM19.6416 8.26288C18.2464 7.7493 14.4951 6.73987 10.4657 6.73987V7.73987C14.3552 7.73987 17.9888 8.72008 19.2961 9.20132L19.6416 8.26288ZM10.7677 24.9674C15.0296 23.3473 17.4553 20.2766 18.7478 17.1169C20.0348 13.9706 20.206 10.7227 19.9655 8.6738L18.9723 8.7904C19.196 10.6959 19.0364 13.7702 17.8222 16.7383C16.6136 19.693 14.3678 22.5291 10.4124 24.0327L10.7677 24.9674ZM1.01434 8.68707C0.582685 13.4607 1.89701 21.5613 10.4044 24.9643L10.7758 24.0358C2.86854 20.8729 1.59633 13.3549 2.01028 8.77713L1.01434 8.68707ZM11.9796 15.4372C12.7449 14.9469 13.2538 14.0877 13.2538 13.1092H12.2538C12.2538 13.7327 11.9305 14.281 11.4401 14.5952L11.9796 15.4372ZM12.6527 18.316L12.2055 14.9504L11.2142 15.0821L11.6614 18.4477L12.6527 18.316ZM8.87416 18.8819H12.1571V17.8819H8.87416V18.8819ZM8.73276 14.937L8.37688 18.3297L9.37143 18.434L9.7273 15.0414L8.73276 14.937ZM7.72733 13.1092C7.72733 14.0661 8.2141 14.909 8.9512 15.4042L9.50886 14.5742C9.03646 14.2568 8.72733 13.719 8.72733 13.1092H7.72733ZM10.4906 10.346C8.96447 10.346 7.72733 11.5831 7.72733 13.1092H8.72733C8.72733 12.1354 9.51675 11.346 10.4906 11.346V10.346ZM13.2538 13.1092C13.2538 11.5831 12.0166 10.346 10.4906 10.346V11.346C11.4644 11.346 12.2538 12.1354 12.2538 13.1092H13.2538Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.50148 6.76381C4.49823 6.6825 4.49658 6.60078 4.49658 6.51868C4.49658 3.19465 7.19124 0.5 10.5153 0.5C13.8393 0.5 16.5339 3.19465 16.5339 6.51868C16.5339 6.60764 16.532 6.69615 16.5282 6.78417C15.8177 6.64651 15.0504 6.52031 14.2444 6.4186C14.1914 4.40452 12.542 2.7881 10.5151 2.7881C8.49322 2.7881 6.84699 4.39661 6.78628 6.4038C5.98016 6.50303 5.21267 6.62738 4.50148 6.76381Z"
      fill="white"
    />
  </svg>
)
