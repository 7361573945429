import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { User } from '../types/vault/User'

export const useUpdateTOTPStatus = () => {
  const queryClient = useQueryClient()

  return useCallback(
    (totpVerified: User['totpVerified']) => {
      const user = queryClient.getQueryData('me') as User
      queryClient.setQueryData('me', {
        ...user,
        totpVerified,
      })
    },
    [queryClient]
  )
}
