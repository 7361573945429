import { Box, Flex, Heading, HStack, Skeleton, Text } from '@chakra-ui/react'
import { Video } from 'components/atoms'
import { getItemVideoUrl } from 'lib/image'
import NextLink from 'next/link'
import { ItemRarity } from 'types/vault/ItemRarity'
import { formatMoney, formatNumber } from '../../../lib/format'
import { ItemMetadata } from '../../../types/vault/ItemMetadata'
import { BoxSale, ConfigBox } from '../../../types/vault/v2/Sale'
import { SaleItemCardTag } from '../../atoms/Card/CardTag'
import { RarityBadge } from '../../atoms/RarityBadge'

interface SaleItemCardProps {
  sale?: BoxSale
  itemMetadata?: ItemMetadata
  slug?: string
  gameName?: string
}

export const SaleItemCard = ({ sale, itemMetadata, slug, gameName }: SaleItemCardProps) => {
  const isLoaded = Boolean(sale && itemMetadata && slug)
  const now = Date.now()
  const config = sale?.config as ConfigBox
  const isSoldOut = sale && config.remainingQuantity <= 0
  const badgeName = itemMetadata?.rarity ?? 'common'
  const badgeTitle = badgeName[0].toUpperCase() + badgeName.slice(1).toLowerCase()

  return (
    <Skeleton isLoaded={isLoaded}>
      <NextLink href={isLoaded ? `/sales/${slug}/sale/${sale?.id}` : ''} passHref>
        <Flex flexDir="column" cursor="pointer" height="100%">
          {isSoldOut && (
            <SaleItemCardTag
              label="SOLD OUT"
              fillColor="#FF3838"
              labelColor="#FFFFFF"
              borderColor="red-border"
            />
          )}
          {!isSoldOut && (new Date(config?.availableAt).getTime() || 0) > now && (
            <SaleItemCardTag
              label="COMING SOON"
              fillColor="#000000"
              labelColor="#FFFFFF"
              borderColor="gray.500"
            />
          )}
          <Box w="100%" overflow="hidden" sx={{ aspectRatio: '1' }}>
            <Video
              src={getItemVideoUrl(
                itemMetadata?.collection as string,
                itemMetadata?.optionName as string,
                itemMetadata?.videoUrl
              )}
              poster={itemMetadata?.imageUrl as string}
              title={itemMetadata?.name}
              w="full"
              h="full"
              objectPosition="center"
              objectFit="cover"
            />
          </Box>

          <Flex flexDir="column" bg="text-cards" p={3} flex={1}>
            <Flex flexDir="column" justifyContent="flex-end" flex={1}>
              <Heading size="md">{itemMetadata?.name}</Heading>
              <RarityBadge rarity={itemMetadata?.rarity as ItemRarity} title={badgeTitle} mt={5} />
            </Flex>
            <HStack mt={5} spacing={2}>
              <Text size="sm" color="whiteAlpha.600">
                Price
              </Text>
              <Text size="md" mt={1} fontFamily="heading" fontWeight="bold">
                {formatMoney(Number(sale?.price))}
              </Text>
            </HStack>
            <Box mt={5}>
              <Text size="sm" color="whiteAlpha.600">
                Remaining Amount
              </Text>
              <Text size="sm" lineHeight="12px" fontFamily="heading" fontWeight="bold" mt={1}>
                {formatNumber(config?.remainingQuantity ?? 0)} / {formatNumber(config?.maxQuantity)}
              </Text>
            </Box>
            <Text mt={5} mb={3} size="sm" lineHeight="12px" fontFamily="heading" fontWeight="bold">
              {gameName}
            </Text>
          </Flex>
        </Flex>
      </NextLink>
    </Skeleton>
  )
}
