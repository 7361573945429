import { Flex, Skeleton, Text } from '@chakra-ui/react'
import BoxButton from 'components/atoms/BoxButton'
import { DeleteButtonWithConfirmModal } from 'components/organisms/DeleteButtonWithConfirmModal'
import { CreditCard } from 'components/atoms/Icons/CreditCard'
import { useDeleteCardMutation } from 'hooks/useDeleteCardMutation'
import { useToast } from 'hooks/useToast'
import { Card } from 'types/vault/Card'
import { formatMoney } from '../../../lib/format'
import { useIsMobile } from '../../../hooks/useIsMobile'

interface CardItemProps {
  card: Card
  onClick: (id: string) => void
  onRemove: (id: string) => void
  showMinimumLegend: boolean
  minimumPrice?: number
  isLoaded?: boolean
  customRadioValue?: string
}

export const CardItem = ({
  card,
  onClick,
  onRemove,
  isLoaded,
  showMinimumLegend,
  minimumPrice,
  customRadioValue,
}: CardItemProps) => {
  const { id, network, last4, expMonth, expYear, status } = card
  const toast = useToast()
  const { mutate } = useDeleteCardMutation()

  const handleDeleteCard = () => {
    mutate(
      { cardId: id },
      {
        onError: () => {
          toast({
            title: 'Error',
            description: 'There was an error deleting your card',
            status: 'error',
          })
        },
        onSuccess: () => {
          toast({ title: 'Success', description: 'Card deleted' })
          onRemove(id)
        },
      }
    )
  }

  const isPending = status === 'pending'
  const [isMobile] = useIsMobile()

  return (
    <BoxButton
      mt="0"
      mb={3}
      w="full"
      dark
      onClick={() => {
        if (!isPending) {
          onClick(id)
        }
      }}
      radioValue={customRadioValue || 'card'}
      rightIconOverride={!isPending ? undefined : <span />}
      borderRadius="12px"
      icon={<CreditCard />}
    >
      <Flex maxW="500px" flex={1} alignItems="center" flexWrap="-moz-initial">
        <Flex direction="column" flex={1} position="relative">
          <Text color="white" fontWeight="medium" lineHeight="shorter" fontSize="md">
            {network} - ****{last4}
          </Text>
          <Skeleton isLoaded={isLoaded}>
            <Flex direction={{ base: 'column', md: 'row' }}>
              <Text fontWeight="normal" color="gray.500" fontSize="md">
                Exp: {expMonth}/{expYear}
              </Text>

              <Text
                display={showMinimumLegend && !isMobile ? 'flex' : 'none'}
                fontWeight={400}
                color="state.error"
                ml={4}
              >
                Minimum Purchase with Credit Card is {formatMoney(minimumPrice)}
              </Text>
            </Flex>
          </Skeleton>
          <Text
            display={showMinimumLegend && isMobile ? 'initial' : 'none'}
            position="absolute"
            bottom="-20px"
            fontSize={12}
            fontWeight={400}
            color="state.error"
            ml={{ base: 0, md: 4 }}
          >
            Minimum Purchase with Credit Card is {formatMoney(minimumPrice)}
          </Text>
        </Flex>
        {isPending ? (
          <Flex w="95px" justifyContent="center" alignItems="center">
            <Text
              fontFamily="Bai Jamjuree"
              fontStyle="normal"
              fontWeight="400"
              fontSize="16px"
              lineHeight="22px"
              color="gray.500"
              textTransform="capitalize"
            >
              {status}
            </Text>
          </Flex>
        ) : (
          <DeleteButtonWithConfirmModal
            onSubmit={handleDeleteCard}
            title="Deleting Credit Card"
            body="Are you sure you want to remove this credit card?"
            ariaLabel="Remove Credit card"
          />
        )}
      </Flex>
    </BoxButton>
  )
}
