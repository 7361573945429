import { useConfig } from '../../../hooks/useConfig'
import FourOhFour from '../../../pages/404'
import { features } from '../../../types/vault/Config'

interface Props {
  children: JSX.Element
  feature: features
}

export const RequireFeature = ({ children, feature }: Props) => {
  const { data, status } = useConfig()
  const hasFeature = data?.features[feature] ?? false

  if (hasFeature) {
    return children
  }

  if (status === 'success' || status === 'error') {
    return <FourOhFour />
  }

  return null
}
