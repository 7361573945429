/* eslint-disable react/display-name */
import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

export interface ButtonProps extends ChakraButtonProps {
  label?: string
  variant?:
    | string
    | 'primary'
    | 'primary-normal'
    | 'dark'
    | 'light'
    | 'light-outline'
    | 'light-normal'
    | 'link'
    | 'highlight'
    | 'highlight-hover'
    | 'none'
  size?: string | 'normal' | 'lg' | 'auto' | 'full' | 'sm'
  children?: React.ReactNode
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, variant = 'primary', size = 'normal', children, ...props }, ref) => (
    <ChakraButton
      // TODO: add shadow on keyboard events
      _focus={{ boxShadow: 'none' }}
      type="button"
      variant={variant}
      size={size}
      ref={ref}
      {...props}
    >
      {label || children}
    </ChakraButton>
  )
)
