import { Tab as ChakraTab, TabProps as ChakraTabProps } from '@chakra-ui/react'

interface TabProps extends ChakraTabProps {
  title: string
}

export const Tab: React.FC<TabProps> = ({ title, ...props }) => (
  <ChakraTab
    width="180px"
    background="rgba(255, 255, 255, 0.10)"
    _selected={{
      color: 'white',
      bg: 'whiteAlpha.300',
      height: '42px',
    }}
    _active={{}} // remove chakra active styling
    color="gray.500"
    p="10px 16px"
    h="35px"
    fontFamily="Montserrat"
    fontStyle="normal"
    fontWeight="700"
    fontSize="18px"
    lineHeight="22px"
    {...props}
  >
    {title}
  </ChakraTab>
)
