import { useState } from 'react'
import { Button, Divider, Flex, HStack, PinInput, PinInputField, Text } from '@chakra-ui/react'
import { TwoFactorFlowProps } from './types'
import { FAQ_LINK } from '../../../constants'
import { useToast } from '../../../hooks/useToast'
import { useValidateTwoFactor } from '../../../hooks/useValidateTwoFactorMutation'
import { TwoFactorModalContent } from './TwoFactorModalContent'

export interface EnterRecoveryCodeModalProps extends TwoFactorFlowProps {
  validationMutation?: typeof useValidateTwoFactor
}

export const EnterRecoveryCodeModal = (props: EnterRecoveryCodeModalProps) => {
  const {
    isOpen,
    skippable,
    onClose,
    onVerified,
    setFlow,
    availableFlows,
    validationMutation = useValidateTwoFactor,
    isToRemove2FA,
    removeType,
    skipOnCloseWhenSucceed,
  } = props

  const [authPayload, setAuthPayload] = useState('')
  const { mutate: validateRecoveryCode, isLoading: isValidating } = validationMutation(
    isToRemove2FA ?? false
  )
  const toast = useToast()

  const hasPhoneValidation = availableFlows.includes('phone')
  const hasTotpValidation = availableFlows.includes('app')

  const formDisabled = authPayload.length < 8 || isValidating

  const submitForm = () => {
    validateRecoveryCode(
      { code: authPayload, removeType: removeType ?? 'phone', authType: 'recovery' },
      {
        onError: (err) => {
          toast({
            title: 'Recovery Code validation failed',
            description: (err.payload?.error ?? '') as string,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },
        onSuccess: () => {
          onVerified?.()
          !skipOnCloseWhenSucceed && onClose()
        },
      }
    )
  }

  const captureEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !formDisabled) {
      submitForm()
    }
  }

  return (
    <TwoFactorModalContent
      isOpen={isOpen}
      title="Two-Factor Authentication (2FA)"
      description="Enter Recovery Code"
      mobileMarginTop={{ base: '174px', md: '0' }}
      desktopMarginTop={{ base: '0', md: '75px', lg: '125px' }}
      onClose={onClose}
      innerContent={
        <Flex alignItems="center" direction="column" p={0}>
          <Text align="center" mt={5}>
            Each Recovery Code can only be used once.
          </Text>
          <HStack w="full" justify="center" mt={5} mb={5}>
            <PinInput otp value={authPayload} onChange={setAuthPayload}>
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
              <PinInputField onKeyPress={captureEnterPress} />
            </PinInput>
          </HStack>
          <Flex
            w="full"
            alignItems="center"
            direction={['column', null, 'row']}
            justifyContent="center"
            mb={15}
          >
            <Button
              onClick={() => {
                setFlow('phone')
              }}
              variant="link"
              fontSize="sm"
              fontWeight="medium"
              color="orange"
              textDecoration="underline"
              display={hasPhoneValidation ? 'flex' : 'none'}
              whiteSpace="normal"
              width="300px"
              wordBreak="break-word"
            >
              Switch to Phone verification
            </Button>
            <Divider
              orientation="horizontal"
              display={hasPhoneValidation && hasTotpValidation ? ['flex', null, 'none'] : 'none'}
              mx={4}
              my="19px"
            />
            <Divider
              orientation="vertical"
              h="5"
              display={hasPhoneValidation && hasTotpValidation ? ['none', null, 'flex'] : 'none'}
              mx={4}
              my={0}
            />
            <Button
              onClick={() => {
                setFlow('app')
              }}
              variant="link"
              fontSize="sm"
              fontWeight="medium"
              color="orange"
              textDecoration="underline"
              display={hasTotpValidation ? 'flex' : 'none'}
              px={0}
              wordBreak="break-word"
              whiteSpace="normal"
              width="auto"
            >
              Enter Auth Code
            </Button>
          </Flex>
        </Flex>
      }
      buttons={
        <Flex flexDirection="row" alignItems="center" justifyContent="center" mt={5} width="full">
          {skippable ? (
            <Button flex={1} mr={1} variant="outline" onClick={onClose}>
              Skip
            </Button>
          ) : null}
          <Button
            disabled={formDisabled}
            isLoading={isValidating}
            flex={1}
            ml={1}
            maxW={{ base: '100%', md: '50%' }}
            variant="light"
            onClick={submitForm}
          >
            <span>Submit</span>
          </Button>
        </Flex>
      }
    />
  )
}
