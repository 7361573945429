import BackendApi from 'lib/api/BackendApi'
import { useMutation } from 'react-query'

const fetchValidate2FA = (): Promise<any> =>
  BackendApi.doRequest({
    method: 'GET',
    url: '/v2/market/me/2fa',
  }).then((res) => res.json())

export const useHas2FA = () => useMutation(fetchValidate2FA)
