import { Line } from 'react-chartjs-2'
import { Box } from '@chakra-ui/react'
import {
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
} from 'chart.js'
import { NotEnoughDataChart } from 'components/organisms'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler)

const options = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label(context: { parsed: { y: any } }) {
          return `$${context.parsed.y}`
        },
      },
    },
    filler: {},
  },
  scales: {
    y: {
      ticks: {
        color: 'white',
        font: {
          size: '12px',
        },
        // Include a dollar sign in the ticks
        callback(value: any) {
          return `$${value}`
        },
      },
      grid: {
        color: '#FFFFFF33',
      },
      border: {
        width: 2,
        color: '#fff',
      },
    },
    x: {
      ticks: {
        color: 'white',
        font: {
          size: '12px',
        },
      },
      grid: {
        color: '#FFFFFF33',
      },
      border: {
        width: 2,
        color: '#fff',
      },
    },
  },
}

interface PerDayLineChartProps {
  labels: string[]
  values: number[]
}

export const PerDayLineChart: React.FC<PerDayLineChartProps> = ({ labels, values }) => {
  const data = {
    labels,
    datasets: [
      {
        data: values,
        borderColor: '#ED8936',
        fill: {
          target: 'origin',
          above: '#ED893626',
        },
      },
    ],
  }

  return (
    <Box maxH="263px">
      {values.length === 0 && <NotEnoughDataChart />}
      {values.length > 0 && <Line options={options as any} data={data} />}
    </Box>
  )
}
