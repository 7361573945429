import { useRef } from 'react'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'
import { Button } from 'components/atoms'
import { Trash } from '../../atoms/Icons/Trash'

interface DeleteButtonWithConfirmModalProps {
  onSubmit: () => void
  title: string
  body: string
  ariaLabel: string
}

export const DeleteButtonWithConfirmModal = ({
  onSubmit,
  title,
  body,
  ariaLabel,
}: DeleteButtonWithConfirmModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const cancelRef = useRef(null)
  const handleSubmit = () => {
    onSubmit()
    onClose()
  }
  return (
    <>
      <IconButton
        variant="unstyled"
        p="0"
        ml={{ base: 0, md: 20 }}
        display="flex"
        alignItems="center"
        aria-label={ariaLabel}
        size="xs"
        icon={<Trash height={20} width={20} />}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          onOpen()
        }}
      />
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} size="sm">
        <AlertDialogOverlay>
          <AlertDialogContent
            bg="text-cards"
            color="white"
            textAlign="center"
            mt={{ base: '220px', lg: '270px' }}
            py="4px"
          >
            <AlertDialogHeader fontSize="xl" fontWeight="bold">
              {title}
            </AlertDialogHeader>
            <AlertDialogBody>{body}</AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                colorScheme="modal-stroke"
                color="modal-stroke"
                variant="outline"
              >
                Cancel
              </Button>
              <Button variant="highlight" ml={3} onClick={handleSubmit}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
