import { SaleGroup } from 'types/vault/SaleGroup'
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react'
import { SaleCardCountDown } from 'components/atoms/CountDown'
import { defaultSaleListCoverUrl, getSaleListCoverUrl } from 'lib/image'
import { Link, ReactMarkdown } from 'components/atoms'

interface SaleCardProps {
  saleGroup: SaleGroup
  active: boolean
}

export const SaleCardMobile: React.FC<SaleCardProps> = ({ saleGroup, active }) => {
  const saleGroupLink = `/sales/${saleGroup.slug}`
  const bg = active ? 'darkGrey' : 'lightDark'
  const radius = active ? '0px' : '6px'
  return (
    <>
      <Flex marginBottom="4">
        <Box
          w="117px"
          border="1px solid"
          sx={{
            'border-image': 'linear-gradient(to right, #FFFFFF66, #FFFFFF29) 1',
          }}
        >
          <Link href={saleGroupLink}>
            <Image
              src={getSaleListCoverUrl(saleGroup.name)}
              alt={saleGroup.name}
              fallbackSrc={defaultSaleListCoverUrl}
            />
          </Link>
        </Box>
        <Flex
          bg={bg}
          flex="1"
          marginLeft="4"
          direction="column"
          justify="center"
          alignItems="center"
          borderRadius={radius}
        >
          <SaleCardCountDown deadline={new Date(saleGroup.availableAt).getTime()} align="left">
            <Text fontWeight="bold" color="#1CBF6A">
              On Sale NOW
            </Text>
          </SaleCardCountDown>
        </Flex>
      </Flex>
      <Flex direction="column">
        <Box>
          <Link href={saleGroupLink}>
            <Heading color="white" fontSize="xl" marginTop={1}>
              {saleGroup.name}
            </Heading>
          </Link>
          <Box display="inline-block">
            <Link href={`/games/${saleGroup?.game?.slug}`} passHref>
              <Text
                mb={2}
                size="md"
                fontFamily="heading"
                fontColor="gray.500"
                fontWeight="400"
                cursor="pointer"
                _hover={{
                  WebkitTransform: `scale(.97)`,
                  color: 'whiteAlpha.700',
                }}
              >
                {saleGroup?.game?.name}
              </Text>
            </Link>
          </Box>
        </Box>
        <ReactMarkdown
          content={saleGroup.description}
          boxProps={{ fontSize: 'sm', color: 'gray.300', noOfLines: 2 }}
        />
        <Flex
          marginTop="4"
          justify="center"
          borderTop="1px solid"
          borderColor="whiteAlpha.300"
          py="7px"
        >
          <Link href={`/sales/${saleGroup.id}`}>
            <Text
              as="a"
              color="highlight.default"
              fontWeight="600"
              cursor="pointer"
              _hover={{ textDecoration: 'underline' }}
            >
              More Details
            </Text>
          </Link>
        </Flex>
      </Flex>
    </>
  )
}
