import AuthApi from 'lib/api/AuthApi'
import { useMutation, useQueryClient } from 'react-query'

const fetchPhoneNumber = ({ phoneNumber }: { phoneNumber: string }): Promise<Response> =>
  AuthApi.doRequest({
    method: 'POST',
    url: '/auth/phone-number',
    body: {
      phoneNumber,
    },
  })

export const usePhoneVerificationSetupMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(fetchPhoneNumber, {
    onSuccess: () => queryClient.invalidateQueries(['me']),
  })
}
