import AuthApi from 'lib/api/AuthApi'
import { useMutation, useQueryClient } from 'react-query'
import { ServiceError } from '../lib/api/ServiceError'

interface AuthenticateTwoFactorResponse {
  recoveryCodes: string[]
}

interface ValidateTwoFactorPayload {
  authType: string
  code: string
  removeType: string
}

const removeTwoFactor = ({
  authType,
  code,
  removeType,
}: ValidateTwoFactorPayload): Promise<AuthenticateTwoFactorResponse> =>
  AuthApi.doRequest({
    method: 'DELETE',
    url: '/auth/upgrade/twofactor',
    body: {
      authType,
      code,
      removeType,
    },
  }).then((response) => response.json())

const authenticateTwoFactor = ({
  authType,
  code,
  removeType,
}: ValidateTwoFactorPayload): Promise<AuthenticateTwoFactorResponse> =>
  AuthApi.doRequest({
    method: 'POST',
    url: '/auth/upgrade/twofactor',
    body: {
      authType,
      code,
      removeType,
    },
  }).then((response) => response.json())

export const useValidateTwoFactor = (isToRemove2FA: boolean) => {
  const queryClient = useQueryClient()

  const validateFunction = isToRemove2FA ? removeTwoFactor : authenticateTwoFactor

  return useMutation<AuthenticateTwoFactorResponse, ServiceError, ValidateTwoFactorPayload>(
    validateFunction,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['me'])
      },
    }
  )
}
