import { Box, Link, ListItem, UnorderedList } from '@chakra-ui/react'
import { Span } from 'components/atoms'
import { MobileDropdownMenu } from 'components/atoms/dropdown-menu/MobileDropdownMenu'
import { useFeature } from 'hooks/useFeature'
import { DEFAULT_MARKETPLACE_ROUTE, OPENLOOT_MEDIUM_URL } from '../../../../constants'

interface MobileNavMenuProps {
  onClose: () => void
}

export const MobileNavMenu: React.FC<MobileNavMenuProps> = ({ onClose }) => {
  const hasRentalsFeature = useFeature('rentals')
  const hasPremiumCurrency = useFeature('premiumCurrency')

  let marketplaceMenuItems
  if (!hasRentalsFeature && !hasPremiumCurrency) {
    marketplaceMenuItems = {
      name: 'Marketplace',
      url: DEFAULT_MARKETPLACE_ROUTE,
    }
  } else {
    const subItems = [
      {
        name: 'NFT Marketplace',
        url: DEFAULT_MARKETPLACE_ROUTE,
      },
    ]
    if (hasPremiumCurrency) {
      subItems.push({
        name: 'Premium Currency',
        url: '/wallet/premium-currencies',
      })
    }
    if (hasRentalsFeature) {
      subItems.push({
        name: 'Rentals',
        url: '/marketplace/rentals',
      })
    }
    marketplaceMenuItems = {
      name: 'Marketplace',
      url: DEFAULT_MARKETPLACE_ROUTE,
      subItems,
    }
  }

  const NAV_ITEMS = [
    {
      name: 'Home',
      url: '/',
    },
    marketplaceMenuItems,
    {
      name: 'Games',
      url: '/games',
    },
    {
      name: 'News',
      url: OPENLOOT_MEDIUM_URL,
      target: '_blank',
    },
  ]
  return (
    <Box color="white" px={5} alignItems="stretch">
      <UnorderedList listStyleType="none" m={0}>
        {NAV_ITEMS.map(({ name, url, target, subItems }, i) =>
          subItems ? (
            <MobileDropdownMenu label={name} options={subItems} onClose={onClose} />
          ) : (
            <Link
              href={url}
              target={target}
              _hover={{
                textDecor: 'none',
              }}
              _focus={{ boxShadow: 'none' }}
              key={name}
              passHref
            >
              <ListItem
                py={3}
                borderBottom={i === NAV_ITEMS.length - 1 ? 0 : '1px'}
                borderColor="whiteAlpha.300"
                cursor="pointer"
                onClick={onClose}
              >
                <Span as="a" fontWeight="bold" fontFamily="heading">
                  {name}
                </Span>
              </ListItem>
            </Link>
          )
        )}
      </UnorderedList>
    </Box>
  )
}
