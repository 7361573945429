import { Center, CenterProps } from '@chakra-ui/react'
import { ItemRarity } from 'types/vault/ItemRarity'
import { Span } from '../Span'

const rarityVariant: Record<ItemRarity, string> = {
  common: 'rarity.common',
  uncommon: 'rarity.uncommon',
  rare: 'rarity.rare',
  epic: 'rarity.epic',
  legendary: 'rarity.legendary',
  mythic: 'rarity.mythic',
  exalted: 'rarity.exalted',
  exotic: 'rarity.exotic',
  transcendent: 'rarity.transcendent',
  unique: 'rarity.unique',
}

export interface RarityBadgeProps extends CenterProps {
  rarity: ItemRarity
  title: string
  size?: string
  style?: React.CSSProperties
  spanStyle?: React.CSSProperties
}

export const RarityBadge: React.FC<RarityBadgeProps> = ({ rarity, title, size, ...props }) => (
  <Center
    w={size === 'lg' ? '124px' : '104px'}
    h={size === 'lg' ? '34px' : '29px'}
    border="2px solid"
    borderColor={rarityVariant[rarity]}
    borderRadius="30px"
    textTransform="capitalize"
    {...props}
  >
    <Span size="xs" fontWeight="bold" lineHeight="1.45rem">
      {title}
    </Span>
  </Center>
)
