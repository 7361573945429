import { useDisclosure } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

interface useCloseOnNavigationProps {
  defaultIsOpen?: boolean
}

export const useCloseOnNavigation = ({ defaultIsOpen = false }: useCloseOnNavigationProps = {}) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen })
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeStart', onClose)

    return () => {
      router.events.off('routeChangeStart', onClose)
    }
  }, [router, onClose])

  return { isOpen, onOpen, onClose }
}
