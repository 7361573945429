import { useConfig } from '../../../hooks/useConfig'
import { features } from '../../../types/vault/Config'

interface Props {
  children: JSX.Element
  feature: features
}

export const WithFeatureFlag = ({ children, feature }: Props) => {
  const { data } = useConfig()
  const hasFeature = data?.features[feature] ?? false
  if (hasFeature) {
    return children
  }

  return null
}
