export const Trash = ({ height, width }: { height: number; width: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={`${height}px`}
    width={`${width}px`}
    viewBox="0 0 19 25"
    fill="none"
  >
    <path
      d="M2 5.26392H16V19.5439C16 20.2137 16 20.5486 15.9206 20.8219C15.7296 21.4794 15.2155 21.9935 14.558 22.1845C14.2846 22.2639 13.9498 22.2639 13.28 22.2639H4.72C4.05024 22.2639 3.71536 22.2639 3.44202 22.1845C2.78452 21.9935 2.27043 21.4794 2.07941 20.8219C2 20.5486 2 20.2137 2 19.5439V5.26392Z"
      stroke="#CDCDCD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 5.26392H13V4.98392C13 4.31415 13 3.97927 12.9206 3.70593C12.7296 3.04844 12.2155 2.53435 11.558 2.34333C11.2846 2.26392 10.9498 2.26392 10.28 2.26392H7.72C7.05024 2.26392 6.71536 2.26392 6.44202 2.34333C5.78452 2.53435 5.27043 3.04844 5.07941 3.70593C5 3.97927 5 4.31415 5 4.98392V5.26392Z"
      stroke="#CDCDCD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="1"
      y1="5.26392"
      x2="17"
      y2="5.26392"
      stroke="#CDCDCD"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <line
      x1="6"
      y1="17.2639"
      x2="6"
      y2="10.2639"
      stroke="#CDCDCD"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="12"
      y1="17.2639"
      x2="12"
      y2="10.2639"
      stroke="#CDCDCD"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)
