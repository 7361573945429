interface PhoneAuthProps {
  width?: string
  height?: string
}

export const PhoneAuth = ({ width = '1em', height = '1em' }: PhoneAuthProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="25.8088"
      width="24.6176"
      height="16"
      rx="2"
      transform="rotate(-90 0.5 25.8088)"
      fill="#C2CCDE"
      fillOpacity="0.25"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.46777 4.5L9.35013 4.5"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.64286 11.0483C5.64286 11.244 5.66253 11.4351 5.70001 11.6197H5.5C4.94772 11.6197 4.5 12.0674 4.5 12.6197V17.4769C4.5 18.0292 4.94772 18.4769 5.5 18.4769H11.5C12.0523 18.4769 12.5 18.0292 12.5 17.4769V12.6197C12.5 12.0674 12.0523 11.6197 11.5 11.6197H11.3C11.3375 11.4351 11.3571 11.244 11.3571 11.0483C11.3571 9.47035 10.078 8.19116 8.5 8.19116C6.92204 8.19116 5.64286 9.47035 5.64286 11.0483ZM6.78575 11.0482C6.78575 11.2486 6.82013 11.441 6.88332 11.6197H10.1167C10.1799 11.441 10.2143 11.2486 10.2143 11.0482C10.2143 10.1015 9.44681 9.33395 8.50003 9.33395C7.55326 9.33395 6.78575 10.1015 6.78575 11.0482ZM9.49995 14.4769C9.49995 14.8308 9.31613 15.1418 9.03877 15.3195L9.23637 16.8067H7.78582L7.94306 15.3077C7.67581 15.1281 7.49995 14.8231 7.49995 14.4769C7.49995 13.9247 7.94766 13.4769 8.49995 13.4769C9.05223 13.4769 9.49995 13.9247 9.49995 14.4769Z"
      fill="white"
    />
    <circle cx="8.5" cy="23.1912" r="1" fill="#CBD5F4" />
  </svg>
)
