import { Box } from '@chakra-ui/react'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css' // eslint-disable-line import/no-unresolved
import 'swiper/css/pagination' // eslint-disable-line import/no-unresolved
import 'swiper/css/navigation' // eslint-disable-line import/no-unresolved

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper'

import styles from './Carousel.module.css'

interface CarouselProps {
  items: React.ReactNode[]
}

export const Carousel: React.FC<CarouselProps> = ({ items }) => (
  <Box className={styles.carousel}>
    <Swiper
      autoplay={{
        delay: 8000,
        disableOnInteraction: false,
      }}
      slidesPerView={1}
      spaceBetween={30}
      loop={items.length > 1}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwipper"
      watchOverflow
    >
      {items.map((item, i) => (
        <SwiperSlide key={i}>{item}</SwiperSlide>
      ))}
    </Swiper>
  </Box>
)
