import { getNames, registerLocale } from 'i18n-iso-countries'
import en from 'i18n-iso-countries/langs/en.json'

registerLocale(en)
export const unitedStatesIso2Code = 'US'
export const canadaIso2Code = 'CA'
export const nonUsIbanSupportedCountries = [
  'AD',
  'AT',
  'AZ',
  'BE',
  'BG',
  'HR',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GI',
  'GR',
  'GT',
  'VA',
  'HU',
  'IS',
  'IE',
  'IL',
  'IT',
  'JO',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'MC',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SM',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'TR',
  'AE',
  'GB',
  'VG',
]
export const nonUsNotIbanSupportedCountries = [
  'AO',
  'AI',
  'AR',
  'AU',
  'BJ',
  'BM',
  'BR',
  'BF',
  'KH',
  'CM',
  canadaIso2Code,
  'KY',
  'TD',
  'CL',
  'GU',
  'GG',
  'HK',
  'IN',
  'ID',
  'IM',
  'JP',
  'JE',
  'KE',
  'KR',
  'MG',
  'MW',
  'MY',
  'MX',
  'MZ',
  'NZ',
  'NE',
  'PE',
  'PH',
  'PR',
  'SN',
  'SG',
  'ZA',
  'TW',
  'TZ',
  'TH',
  'UM',
  'UZ',
  'VN',
]
export const allSupportedCountries = [
  unitedStatesIso2Code,
  ...nonUsIbanSupportedCountries,
  ...nonUsNotIbanSupportedCountries,
]
export const allCountriesISO = Object.entries(getNames('en'))

export const allSupportedCountriesISO = allCountriesISO.filter(([code]) =>
  allSupportedCountries.includes(code)
)

const allServicesCountries = [
  {
    country: 'Andorra',
    countryCode: 'AD',
  },
  {
    country: 'Angola',
    countryCode: 'AO',
  },
  {
    country: 'Anguilla',
    countryCode: 'AI',
  },
  {
    country: 'Argentina',
    countryCode: 'AR',
  },
  {
    country: 'Australia',
    countryCode: 'AU',
  },
  {
    country: 'Austria',
    countryCode: 'AT',
  },
  {
    country: 'Azerbaijan',
    countryCode: 'AZ',
  },
  {
    country: 'Belgium',
    countryCode: 'BE',
  },
  {
    country: 'Benin',
    countryCode: 'BJ',
  },
  {
    country: 'Bermuda',
    countryCode: 'BM',
  },
  {
    country: 'Brazil',
    countryCode: 'BR',
  },
  {
    country: 'Bulgaria',
    countryCode: 'BG',
  },
  {
    country: 'Burkina Faso',
    countryCode: 'BF',
  },
  {
    country: 'Cambodia',
    countryCode: 'KH',
  },
  {
    country: 'Cameroon',
    countryCode: 'CM',
  },
  {
    country: 'Canada',
    countryCode: 'CA',
  },
  {
    country: 'Cayman Islands',
    countryCode: 'KY',
  },
  {
    country: 'Chad',
    countryCode: 'TD',
  },
  {
    country: 'Chile',
    countryCode: 'CL',
  },
  {
    country: 'Croatia',
    countryCode: 'HR',
  },
  {
    country: 'Denmark',
    countryCode: 'DK',
  },
  {
    country: 'Estonia',
    countryCode: 'EE',
  },
  {
    country: 'Finland',
    countryCode: 'FI',
  },
  {
    country: 'France',
    countryCode: 'FR',
  },
  {
    country: 'Germany',
    countryCode: 'DE',
  },
  {
    country: 'Gibraltar',
    countryCode: 'GI',
  },
  {
    country: 'Greece',
    countryCode: 'GR',
  },
  {
    country: 'Guam',
    countryCode: 'GU',
  },
  {
    country: 'Guatemala',
    countryCode: 'GT',
  },
  {
    country: 'Guernsey',
    countryCode: 'GG',
  },
  {
    country: 'Holy See (Vatican City State)',
    countryCode: 'VA',
  },
  {
    country: 'Hong Kong',
    countryCode: 'HK',
  },
  {
    country: 'Hungary',
    countryCode: 'HU',
  },
  {
    country: 'Iceland',
    countryCode: 'IS',
  },
  {
    country: 'India',
    countryCode: 'IN',
  },
  {
    country: 'Indonesia',
    countryCode: 'ID',
  },
  {
    country: 'Ireland',
    countryCode: 'IE',
  },
  {
    country: 'Isle of Man',
    countryCode: 'IM',
  },
  {
    country: 'Israel',
    countryCode: 'IL',
  },
  {
    country: 'Italy',
    countryCode: 'IT',
  },
  {
    country: 'Japan',
    countryCode: 'JP',
  },
  {
    country: 'Jersey',
    countryCode: 'JE',
  },
  {
    country: 'Jordan',
    countryCode: 'JO',
  },
  {
    country: 'Kenya',
    countryCode: 'KE',
  },
  {
    country: 'Republic of Korea',
    countryCode: 'KR',
  },
  {
    country: 'Latvia',
    countryCode: 'LV',
  },
  {
    country: 'Liechtenstein',
    countryCode: 'LI',
  },
  {
    country: 'Lithuania',
    countryCode: 'LT',
  },
  {
    country: 'Luxembourg',
    countryCode: 'LU',
  },
  {
    country: 'Madagascar',
    countryCode: 'MG',
  },
  {
    country: 'Malawi',
    countryCode: 'MW',
  },
  {
    country: 'Malaysia',
    countryCode: 'MY',
  },
  {
    country: 'Malta',
    countryCode: 'MT',
  },
  {
    country: 'Mexico',
    countryCode: 'MX',
  },
  {
    country: 'Monaco',
    countryCode: 'MC',
  },
  {
    country: 'Mozambique',
    countryCode: 'MZ',
  },
  {
    country: 'Netherlands',
    countryCode: 'NL',
  },
  {
    country: 'New Zealand',
    countryCode: 'NZ',
  },
  {
    country: 'Niger',
    countryCode: 'NE',
  },
  {
    country: 'Norway',
    countryCode: 'NO',
  },
  {
    country: 'Peru',
    countryCode: 'PE',
  },
  {
    country: 'Philippines',
    countryCode: 'PH',
  },
  {
    country: 'Poland',
    countryCode: 'PL',
  },
  {
    country: 'Portugal',
    countryCode: 'PT',
  },
  {
    country: 'Puerto Rico',
    countryCode: 'PR',
  },
  {
    country: 'Romania',
    countryCode: 'RO',
  },
  {
    country: 'San Marino',
    countryCode: 'SM',
  },
  {
    country: 'Senegal',
    countryCode: 'SN',
  },
  {
    country: 'Singapore',
    countryCode: 'SG',
  },
  {
    country: 'Slovakia',
    countryCode: 'SK',
  },
  {
    country: 'Slovenia',
    countryCode: 'SI',
  },
  {
    country: 'South Africa',
    countryCode: 'ZA',
  },
  {
    country: 'Spain',
    countryCode: 'ES',
  },
  {
    country: 'Sweden',
    countryCode: 'SE',
  },
  {
    country: 'Switzerland',
    countryCode: 'CH',
  },
  {
    country: 'Taiwan',
    countryCode: 'TW',
  },
  {
    country: 'Tanzania',
    countryCode: 'TZ',
  },
  {
    country: 'Thailand',
    countryCode: 'TH',
  },
  {
    country: 'Turkey',
    countryCode: 'TR',
  },
  {
    country: 'United Arab Emirates',
    countryCode: 'AE',
  },
  {
    country: 'United Kingdom',
    countryCode: 'GB',
  },
  {
    country: 'United States',
    countryCode: 'US',
  },
  {
    country: 'Uzbekistan',
    countryCode: 'UZ',
  },
  {
    country: 'Vietnam',
    countryCode: 'VN',
  },
  {
    country: 'British Virgin Islands',
    countryCode: 'VG',
  },
  {
    country: 'U.S. Virgin Islands',
    countryCode: 'VG',
  },
]

const walletCountries = [
  {
    country: 'Albania',
    countryCode: 'AL',
  },
  {
    country: 'Algeria',
    countryCode: 'DZ',
  },
  {
    country: 'American Samoa',
    countryCode: 'AS',
  },
  {
    country: 'Antarctica',
    countryCode: 'AQ',
  },
  {
    country: 'Antigua and Barbuda',
    countryCode: 'AG',
  },
  {
    country: 'Armenia',
    countryCode: 'AM',
  },
  {
    country: 'Aruba',
    countryCode: 'AW',
  },
  {
    country: 'The Bahamas',
    countryCode: 'BS',
  },
  {
    country: 'Bahrain',
    countryCode: 'BH',
  },
  {
    country: 'Bangladesh',
    countryCode: 'BD',
  },
  {
    country: 'Barbados',
    countryCode: 'BB',
  },
  {
    country: 'Belize',
    countryCode: 'BZ',
  },
  {
    country: 'Bhutan',
    countryCode: 'BT',
  },
  {
    country: 'Bolivia',
    countryCode: 'BO',
  },
  {
    country: 'Bonaire',
    countryCode: 'BQ',
  },
  {
    country: 'Sint Eustatius and Saba',
    countryCode: 'BQ',
  },
  {
    country: 'Bosnia and Herzegovina',
    countryCode: 'BA',
  },
  {
    country: 'Botswana',
    countryCode: 'BW',
  },
  {
    country: 'Bouvet Island',
    countryCode: 'BV',
  },
  {
    country: 'British Indian Ocean Territory',
    countryCode: 'IO',
  },
  {
    country: 'Brunei Darussalam',
    countryCode: 'BN',
  },
  {
    country: 'Burundi',
    countryCode: 'BI',
  },
  {
    country: 'Cabo Verde',
    countryCode: 'CV',
  },
  {
    country: 'China',
    countryCode: 'CN',
  },
  {
    country: 'Christmas Island',
    countryCode: 'CX',
  },
  {
    country: 'Cocos (Keeling) Islands',
    countryCode: 'CC',
  },
  {
    country: 'Colombia',
    countryCode: 'CO',
  },
  {
    country: 'Comoros',
    countryCode: 'KM',
  },
  {
    country: 'Cook Islands',
    countryCode: 'CK',
  },
  {
    country: 'Costa Rica',
    countryCode: 'CR',
  },
  {
    country: 'Curaçao',
    countryCode: 'CW',
  },
  {
    country: 'Cyprus',
    countryCode: 'CY',
  },
  {
    country: 'Czech Republic',
    countryCode: 'CZ',
  },
  {
    country: 'Ivory Coast',
    countryCode: 'CI',
  },
  {
    country: 'Djibouti',
    countryCode: 'DJ',
  },
  {
    country: 'Dominica',
    countryCode: 'DM',
  },
  {
    country: 'Dominican Republic',
    countryCode: 'DO',
  },
  {
    country: 'Ecuador',
    countryCode: 'EC',
  },
  {
    country: 'Egypt',
    countryCode: 'EG',
  },
  {
    country: 'El Salvador',
    countryCode: 'SV',
  },
  {
    country: 'Equatorial Guinea',
    countryCode: 'GQ',
  },
  {
    country: 'Eritrea',
    countryCode: 'ER',
  },
  {
    country: 'Eswatini',
    countryCode: 'SZ',
  },
  {
    country: 'Ethiopia',
    countryCode: 'ET',
  },
  {
    country: 'Falkland Islands (Malvinas)',
    countryCode: 'FK',
  },
  {
    country: 'Faroe Islands',
    countryCode: 'FO',
  },
  {
    country: 'Fiji',
    countryCode: 'FJ',
  },
  {
    country: 'French Guiana',
    countryCode: 'GF',
  },
  {
    country: 'French Polynesia',
    countryCode: 'PF',
  },
  {
    country: 'French Southern Territories',
    countryCode: 'TF',
  },
  {
    country: 'Gabon',
    countryCode: 'GA',
  },
  {
    country: 'Gambia',
    countryCode: 'GM',
  },
  {
    country: 'Georgia',
    countryCode: 'GE',
  },
  {
    country: 'Ghana',
    countryCode: 'GH',
  },
  {
    country: 'Greenland',
    countryCode: 'GL',
  },
  {
    country: 'Grenada',
    countryCode: 'GD',
  },
  {
    country: 'Guadeloupe',
    countryCode: 'GP',
  },
  {
    country: 'Guinea',
    countryCode: 'GQ',
  },
  {
    country: 'Guyana',
    countryCode: 'GY',
  },
  {
    country: 'Haiti',
    countryCode: 'HT',
  },
  {
    country: 'Heard Island and McDonald Islands',
    countryCode: 'HM',
  },
  {
    country: 'Honduras',
    countryCode: 'HN',
  },
  {
    country: 'Jamaica',
    countryCode: 'JM',
  },
  {
    country: 'Kazakhstan',
    countryCode: 'KZ',
  },
  {
    country: 'Kiribati',
    countryCode: 'KI',
  },
  {
    country: 'Kuwait',
    countryCode: 'KW',
  },
  {
    country: 'Kyrgyzstan',
    countryCode: 'KG',
  },
  {
    country: "Lao People's Democratic Republic",
    countryCode: 'LA',
  },
  {
    country: 'Lebanon',
    countryCode: 'LB',
  },
  {
    country: 'Lesotho',
    countryCode: 'LS',
  },
  {
    country: 'Liberia',
    countryCode: 'LR',
  },
  {
    country: 'Macao',
    countryCode: 'MO',
  },
  {
    country: 'Maldives',
    countryCode: 'MV',
  },
  {
    country: 'Marshall Islands',
    countryCode: 'MH',
  },
  {
    country: 'Martinique',
    countryCode: 'MQ',
  },
  {
    country: 'Mauritania',
    countryCode: 'MR',
  },
  {
    country: 'Mauritius',
    countryCode: 'MU',
  },
  {
    country: 'Mayotte',
    countryCode: 'YT',
  },
  {
    country: 'Federated States of Micronesia',
    countryCode: 'FM',
  },
  {
    country: 'Republic of Moldova',
    countryCode: 'MD',
  },
  {
    country: 'Mongolia',
    countryCode: 'MN',
  },
  {
    country: 'Montenegro',
    countryCode: 'ME',
  },
  {
    country: 'Montserrat',
    countryCode: 'MS',
  },
  {
    country: 'Morocco',
    countryCode: 'MA',
  },
  {
    country: 'Namibia',
    countryCode: 'NA',
  },
  {
    country: 'Nauru',
    countryCode: 'NR',
  },
  {
    country: 'Nepal',
    countryCode: 'NP',
  },
  {
    country: 'New Caledonia',
    countryCode: 'NC',
  },
  {
    country: 'Nicaragua',
    countryCode: 'NI',
  },
  {
    country: 'Nigeria',
    countryCode: 'NG',
  },
  {
    country: 'Niue',
    countryCode: 'NU',
  },
  {
    country: 'Norfolk Island',
    countryCode: 'NF',
  },
  {
    country: 'Northern Mariana Islands',
    countryCode: 'MP',
  },
  {
    country: 'Oman',
    countryCode: 'OM',
  },
  {
    country: 'Pakistan',
    countryCode: 'PK',
  },
  {
    country: 'Palau',
    countryCode: 'PW',
  },
  {
    country: 'State of Palestine',
    countryCode: 'PS',
  },
  {
    country: 'Panama',
    countryCode: 'PA',
  },
  {
    country: 'Papua New Guinea',
    countryCode: 'PG',
  },
  {
    country: 'Paraguay',
    countryCode: 'PY',
  },
  {
    country: 'Pitcairn',
    countryCode: 'PN',
  },
  {
    country: 'Qatar',
    countryCode: 'QA',
  },
  {
    country: 'North Macedonia',
    countryCode: 'MK',
  },
  {
    country: 'Rwanda',
    countryCode: 'RW',
  },
  {
    country: 'Réunion',
    countryCode: 'RE',
  },
  {
    country: 'Saint Barthélemy',
    countryCode: 'BL',
  },
  {
    country: 'Saint Helena',
    countryCode: 'SH',
  },
  {
    country: 'Ascension and Tristan da Cunha',
    countryCode: 'SH',
  },
  {
    country: 'Saint Kitts and Nevis',
    countryCode: 'KN',
  },
  {
    country: 'Saint Lucia',
    countryCode: 'LC',
  },
  {
    country: 'Saint Martin (French part)',
    countryCode: 'MF',
  },
  {
    country: 'Saint Pierre and Miquelon',
    countryCode: 'PM',
  },
  {
    country: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
  },
  {
    country: 'Samoa',
    countryCode: 'AS',
  },
  {
    country: 'Sao Tome and Principe',
    countryCode: 'ST',
  },
  {
    country: 'Saudi Arabia',
    countryCode: 'SA',
  },
  {
    country: 'Serbia',
    countryCode: 'RS',
  },
  {
    country: 'Seychelles',
    countryCode: 'SC',
  },
  {
    country: 'Sierra Leone',
    countryCode: 'SL',
  },
  {
    country: 'Sint Maarten (Dutch part)',
    countryCode: 'SX',
  },
  {
    country: 'Solomon Islands',
    countryCode: 'SB',
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    countryCode: 'GS',
  },
  {
    country: 'Sri Lanka',
    countryCode: 'LK',
  },
  {
    country: 'Suriname',
    countryCode: 'SR',
  },
  {
    country: 'Svalbard and Jan Mayen',
    countryCode: 'SJ',
  },
  {
    country: 'Tajikistan',
    countryCode: 'TJ',
  },
  {
    country: 'Timor-Leste',
    countryCode: 'TL',
  },
  {
    country: 'Togo',
    countryCode: 'TG',
  },
  {
    country: 'Tokelau',
    countryCode: 'TK',
  },
  {
    country: 'Tonga',
    countryCode: 'TO',
  },
  {
    country: 'Trinidad and Tobago',
    countryCode: 'TT',
  },
  {
    country: 'Tunisia',
    countryCode: 'TN',
  },
  {
    country: 'Turkmenistan',
    countryCode: 'TM',
  },
  {
    country: 'Turks and Caicos Islands',
    countryCode: 'TC',
  },
  {
    country: 'Tuvalu',
    countryCode: 'TV',
  },
  {
    country: 'Uganda',
    countryCode: 'UG',
  },
  {
    country: 'Uruguay',
    countryCode: 'UY',
  },
  {
    country: 'Vanuatu',
    countryCode: 'VU',
  },
  {
    country: 'Wallis and Futuna',
    countryCode: 'WF',
  },
  {
    country: 'Western Sahara',
    countryCode: 'EH',
  },
  {
    country: 'Zambia',
    countryCode: 'ZM',
  },
  {
    country: 'Zimbabwe',
    countryCode: 'ZW',
  },
  {
    country: 'Åland Islands',
    countryCode: 'AX',
  },
]

export const forbiddenCountries = [
  {
    country: 'Afghanistan',
    countryCode: 'AF',
  },
  {
    country: 'Central African Republic',
    countryCode: 'CF',
  },
  {
    country: 'Congo',
    countryCode: 'CD',
  },
  {
    country: 'Cuba',
    countryCode: 'CU',
  },
  {
    country: 'Guinea-Bissau',
    countryCode: 'GW',
  },
  {
    country: 'Iran',
    countryCode: 'IR',
  },
  {
    country: 'Iraq',
    countryCode: 'IQ',
  },
  {
    country: 'North Korea',
    countryCode: 'KP',
  },
  {
    country: 'Libya',
    countryCode: 'LY',
  },
  {
    country: 'Mali',
    countryCode: 'ML',
  },
  {
    country: 'Somalia',
    countryCode: 'SO',
  },
  {
    country: 'South Sudan',
    countryCode: 'SS',
  },
  {
    country: 'Sudan',
    countryCode: 'SD',
  },
  {
    country: 'Syrian Arab Republic',
    countryCode: 'SY',
  },
  {
    country: 'Ukraine',
    countryCode: 'UA',
  },
  {
    country: 'Venezuela',
    countryCode: 'VE',
  },
  {
    country: 'Yemen',
    countryCode: 'YE',
  },
  {
    country: 'Belarus',
    countryCode: 'BY',
  },
  {
    country: 'Myanmar',
    countryCode: 'MM',
  },
  {
    country: 'Russian Federation',
    countryCode: 'RU',
  },
]

export function isValidCountry(value: string) {
  return !forbiddenCountries.map((c) => c.countryCode).includes(value)
}

export const all2FACountries = [...allServicesCountries, ...walletCountries].sort((a, b) =>
  a.country.localeCompare(b.country)
)

export const allKycCountries = [...all2FACountries, ...forbiddenCountries].sort((a, b) =>
  a.country.localeCompare(b.country)
)

export const allKycCountriesCodes = allKycCountries.map((country) => country.countryCode)

export const all2FACountriesCodes = all2FACountries.map((country) => country.countryCode)

export const allKycCountryCodesToNameMap = new Map(
  allKycCountries.map(({ countryCode, country }) => [countryCode, country])
)
