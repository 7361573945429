import { useMemo } from 'react'
import { Option } from '../../Option'

interface IOptionsRenderer {
  options: { value: number; label: string }[]
}

export const OptionsRenderer = ({ options }: IOptionsRenderer) => {
  const toRender = useMemo(
    () =>
      options.map((item, _) => (
        <Option key={item.value} value={item.value}>
          {item.label}
        </Option>
      )),
    [options]
  )

  return <>{toRender}</>
}
