import { memoize } from 'lodash'
import { useUserAgent } from 'next-useragent'

/**
 * Gets the user agent when it exists, otherwise returns an empty one
 */
export const getUserAgent = memoize(() => {
  let userAgent
  let isMobile
  if (typeof window !== 'undefined') {
    userAgent = window.navigator.userAgent
    isMobile = useUserAgent(userAgent).isMobile
  }
  return { isMobile, userAgent }
})
