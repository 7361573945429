import { useDesktop } from 'hooks/useDesktop'
import { useActiveGameList, useGameList } from 'hooks/useGameList'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { createContext, useEffect, useRef, useState } from 'react'
import {
  GameFilterType,
  HandleSingleFilterChangeType,
  SingleSelectFiltersType,
} from 'types/filter-menu'
import { ChildrenProp } from 'types/globals'
import { Game } from 'types/vault/v2/Game'
import { RentalListingType } from 'types/vault/v2/Rental'
import {
  FILTER_QUERY_KEYS,
  getCategoryQueryKeys,
  getGameQueryKeys,
  getRarityQueryKeys,
  getRentalPeriodsQueryKeys,
  getRentalTypesQueryKeys,
  getTagQueryKeys,
  handleCategorySelect,
  handleGameListToGameFilter,
  handleGameSelect,
  handleInitialGameCategorySelect,
  handleTagSelect,
  initialRarities,
  initialSingleSelectFilters,
} from 'utils/filter-menu'
import { handleRouterUrl } from 'utils/globals'

interface FilterMenuContextProps {
  isAnyFilterSelected: boolean
  isLoadingGames: boolean
  rarityFilter: typeof initialRarities
  gameFilter: GameFilterType[]
  singleSelectFilters: SingleSelectFiltersType
  rentalTypesFilter: RentalListingType[]
  rentalPeriodsFilter: number[]
  handleRentalPeriodsFilterChange: (periods: number[]) => void
  handleRentalTypesFilterChange: (types: RentalListingType[]) => void
  handleRarityFilterChange: (id: string, isSelected: boolean) => void
  handleSingleSelectFilterChange: HandleSingleFilterChangeType
  handleGameFilterChange: (gameId: string, isSelected: boolean, isSingleSelect?: boolean) => void
  handleCategoryFilterChange: (
    gameId: string,
    category: string,
    isSelectedCategory: boolean
  ) => void
  handleTagFilterChange: (gameId: string, tag: string, isSelectedTag: boolean) => void
  handlePushFiltersToRouter: () => void
  handleClearFilters: () => void
}

const FilterMenuContext = createContext<FilterMenuContextProps>(
  {} as unknown as FilterMenuContextProps
)

export const FilterMenuProvider: React.FC<ChildrenProp> = ({ children }) => {
  const router = useRouter()
  const [singleSelectFilters, setSingleSelectFilters] = useState<SingleSelectFiltersType>(
    initialSingleSelectFilters
  )
  const [rarityFilter, setRarityFilter] = useState(initialRarities)
  const [rentalTypesFilter, setRentalTypesFilter] = useState<RentalListingType[]>([])
  const [rentalPeriodsFilter, setRentalPeriodsFilter] = useState<number[]>([])
  const [gameFilter, setGameFilter] = useState<GameFilterType[]>([])
  const [isAnyFilterSelected, setIsAnyFilterSelected] = useState(false)
  const { data: gameList, isLoading } = useGameList({})
  const isDesktop = useDesktop()
  const gamePageId = useRef('')

  const handlePushFiltersToRouter = () => {
    let removeQueryKeys: string[] = []
    let queryObj: Record<any, any> = {}

    Object.values(singleSelectFilters).forEach(({ queryKey, value }) => {
      if (value) {
        queryObj[queryKey] = value
      }
    })

    const [gameCategoryQueryObj, gameCategoryRemoveQueryKeys] = getGameQueryKeys(gameFilter, true)
    const [rarityQueryObj, rarityRemoveQueryKeys] = getRarityQueryKeys(rarityFilter)
    const [rentalTypesQueryObj, rentalTypesRemoveQueryKeys] =
      getRentalTypesQueryKeys(rentalTypesFilter)
    const [rentalPeriodsQueryObj, rentalPeriodsRemoveQueryKeys] =
      getRentalPeriodsQueryKeys(rentalPeriodsFilter)
    const [tagQueryObj, tagRemoveQueryKeys] = getTagQueryKeys(gameFilter)

    removeQueryKeys = [
      ...removeQueryKeys,
      ...gameCategoryRemoveQueryKeys,
      ...rarityRemoveQueryKeys,
      ...tagRemoveQueryKeys,
      ...rentalTypesRemoveQueryKeys,
      ...rentalPeriodsRemoveQueryKeys,
    ]
    queryObj = {
      ...queryObj,
      ...gameCategoryQueryObj,
      ...rarityQueryObj,
      ...tagQueryObj,
      ...rentalTypesQueryObj,
      ...rentalPeriodsQueryObj,
    }

    handleRouterUrl({ router, queryObj, removeQueryKeys })
  }

  const handleClearFilters = () => {
    setRarityFilter(initialRarities)
    const initialGameFilter = handleInitialGameCategorySelect({
      gameFilter,
      selectedGameIds: [gamePageId.current],
      selectedCategories: [],
      selectedTags: [],
    })
    setGameFilter(initialGameFilter)
    setSingleSelectFilters(initialSingleSelectFilters)
    const basePath = router.asPath.split('?')[0]
    router.push(basePath)
  }

  // change handles
  const handleSingleSelectFilterChange: HandleSingleFilterChangeType = (key, value) => {
    const { queryKey } = initialSingleSelectFilters[key]

    setSingleSelectFilters((prevFilters) => ({
      ...prevFilters,
      [key]: { ...prevFilters[key], value },
    }))

    handleRouterUrl({
      router,
      queryObj: { [queryKey]: value },
      shouldPush: isDesktop,
    })
  }

  const handleRarityFilterChange = (id: string, isSelected: boolean) => {
    const updatedRarities = rarityFilter.map((rarityFields) =>
      rarityFields.id === id ? { ...rarityFields, isSelected } : rarityFields
    )

    if (isDesktop) {
      const [queryObj, removeQueryKeys] = getRarityQueryKeys(updatedRarities)

      handleRouterUrl({
        router,
        queryObj,
        removeQueryKeys,
      })
    }

    setRarityFilter(updatedRarities)
  }

  const handleRentalTypesFilterChange = (types: RentalListingType[]) => {
    const [queryObj, removeQueryKeys] = getRentalTypesQueryKeys(types)
    handleRouterUrl({
      router,
      queryObj,
      removeQueryKeys,
    })

    setRentalTypesFilter(types)
  }

  const handleRentalPeriodsFilterChange = (periods: number[]) => {
    const [queryObj, removeQueryKeys] = getRentalPeriodsQueryKeys(periods)
    handleRouterUrl({
      router,
      queryObj,
      removeQueryKeys,
    })

    setRentalPeriodsFilter(periods)
  }

  const handleCategoryFilterChange = (
    gameId: string,
    category: string,
    isSelectedCategory: boolean
  ) => {
    const updatedFilters = handleCategorySelect({
      gameFilter,
      gameId,
      category,
      isSelectedCategory,
    })

    if (isDesktop) {
      const [queryObj, removeQueryKeys] = getCategoryQueryKeys(updatedFilters)

      handleRouterUrl({
        router,
        queryObj,
        removeQueryKeys,
      })
    }

    setGameFilter(updatedFilters)
  }

  const handleTagFilterChange = (gameId: string, tag: string, isSelectedTag: boolean) => {
    const updatedFilters = handleTagSelect({
      gameFilter,
      gameId,
      tag,
      isSelectedTag,
    })
    if (isDesktop) {
      const [queryObj, removeQueryKeys] = getTagQueryKeys(updatedFilters)

      handleRouterUrl({
        router,
        queryObj,
        removeQueryKeys,
      })
    }

    setGameFilter(updatedFilters)
  }

  const handleGameFilterChange = (gameId: string, isSelected: boolean, isSingleSelect = false) => {
    const updatedFilters = handleGameSelect({ gameFilter, gameId, isSelected, isSingleSelect })

    if (isDesktop) {
      const [queryObj, removeQueryKeys] = getGameQueryKeys(updatedFilters, true)

      handleRouterUrl({
        router,
        queryObj,
        removeQueryKeys,
      })
    }

    setGameFilter(updatedFilters)
  }

  // initial setups based on router
  // setting initial rarity based on router query
  useEffect(() => {
    if (!router.isReady) return

    const rarityStr = router.query[FILTER_QUERY_KEYS.rarity]

    if (rarityStr && typeof rarityStr === 'string') {
      const selectedRarityIds = rarityStr.split(',')
      const updatedRarityItems = rarityFilter.map((rarityFields) =>
        selectedRarityIds.includes(rarityFields.id)
          ? { ...rarityFields, isSelected: true }
          : rarityFields
      )
      setRarityFilter(updatedRarityItems)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  // setting single query filters based on router query
  useEffect(() => {
    const singleSelectQuerykeys = [
      'mintedTo',
      'mintedFrom',
      'priceTo',
      'priceFrom',
      'onSale',
      'nonOnSale',
    ] as const

    let updatedSingleSelectFilters = _.cloneDeep(singleSelectFilters)

    singleSelectQuerykeys.forEach((key) => {
      const queryValue = router.query[key]

      if (queryValue && typeof queryValue === 'string') {
        updatedSingleSelectFilters = {
          ...updatedSingleSelectFilters,
          [key]: { ...updatedSingleSelectFilters[key], value: queryValue },
        }
      }
    })

    setSingleSelectFilters(updatedSingleSelectFilters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  // setting initial game and category filters
  useEffect(() => {
    if (!gameList || !router.isReady) return

    const gameSlug = router.query.slug

    // to ensure game page, check we have games in router url
    const isGamePage = gameSlug && router.asPath.includes('games')

    let initialGames: Game[] = []

    if (isGamePage) {
      initialGames = gameList.list.filter(({ slug }) => slug === gameSlug)
      gamePageId.current = initialGames[0].id
    } else {
      initialGames = gameList.list
    }
    let updatedGameFilter = handleGameListToGameFilter(initialGames, !!isGamePage)

    const gamesQueryStr = router.query?.gameId
    let selectedGameIds: string[] = [gamePageId.current]

    if (gamesQueryStr && typeof gamesQueryStr === 'string') {
      selectedGameIds = gamesQueryStr.split(',')
    }

    const categoryQueryStr = router.query?.category
    let selectedCategories: string[] = []

    if (categoryQueryStr && typeof categoryQueryStr === 'string') {
      selectedCategories = categoryQueryStr.split(',')
    }

    const tagQueryStr = router.query?.tag
    let selectedTags: string[] = []

    if (tagQueryStr && typeof tagQueryStr === 'string') {
      selectedTags = tagQueryStr.split(',').flatMap((tagString) => tagString.split('|'))
    }

    updatedGameFilter = handleInitialGameCategorySelect({
      gameFilter: updatedGameFilter,
      selectedCategories,
      selectedTags,
      selectedGameIds,
    })
    setGameFilter(updatedGameFilter)
  }, [gameList, router])

  useEffect(() => {
    if (!router.isReady) return
    const queryPath = router.asPath.split('?')[1]
    setIsAnyFilterSelected(!!queryPath)
  }, [router])

  return (
    <FilterMenuContext.Provider
      value={{
        isAnyFilterSelected,
        isLoadingGames: isLoading,
        gameFilter,
        singleSelectFilters,
        rarityFilter,
        rentalTypesFilter,
        rentalPeriodsFilter,
        handleRentalPeriodsFilterChange,
        handleRentalTypesFilterChange,
        handleRarityFilterChange,
        handleSingleSelectFilterChange,
        handlePushFiltersToRouter,
        handleClearFilters,
        handleCategoryFilterChange,
        handleTagFilterChange,
        handleGameFilterChange,
      }}
    >
      {children}
    </FilterMenuContext.Provider>
  )
}

export default FilterMenuContext
