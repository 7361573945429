import { useGameList } from 'hooks/useGameList'
import { useUser } from 'hooks/useUser'
import {
  getGamesWithReadPermissions,
  isOpenlootAdmin,
  isTeamAdmin,
  isTeamMember,
} from 'lib/permissions'
import { createContext, useEffect, useMemo, useState } from 'react'
import { ChildrenProp } from 'types/globals'
import { Game } from 'types/vault/v2/Game'

interface PartnerPortalContextProps {
  isOlAdmin: boolean
  isTeamAdmin: boolean
  isTeamMember: boolean
  allowedGames: Game[]
  selectedGame: Game | null
  setSelectedGame: (game: Game | null) => void
}

const PartnerPortalContext = createContext<PartnerPortalContextProps>(
  {} as unknown as PartnerPortalContextProps
)

export const PartnerPortalProvider: React.FC<ChildrenProp> = ({ children }) => {
  const { data: user } = useUser()
  // TODO: improve game list endpoint to allow to take all games
  const { data: games } = useGameList({})
  const [selectedGame, setSelectedGame] = useState<Game | null>(null)

  const isOlAdmin = isOpenlootAdmin(user?.roles)

  const userGamesSlug = getGamesWithReadPermissions(user?.permissions)

  const allowedGames = useMemo(
    () =>
      games
        ? isOlAdmin
          ? games.list
          : games.list.filter(({ slug }) => userGamesSlug.includes(slug))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [games, user]
  )

  useEffect(() => {
    if (allowedGames.length > 0) {
      setSelectedGame(allowedGames[0])
    }
  }, [allowedGames])

  return (
    <PartnerPortalContext.Provider
      value={{
        isOlAdmin,
        isTeamAdmin: isTeamAdmin(user?.roles),
        isTeamMember: isTeamMember(user?.roles),
        allowedGames,
        selectedGame,
        setSelectedGame,
      }}
    >
      {children}
    </PartnerPortalContext.Provider>
  )
}

export default PartnerPortalContext
