import {
  Box,
  Container as ChakraContainer,
  ContainerProps as ChakraContainerProps,
} from '@chakra-ui/react'

interface ContainerProps extends ChakraContainerProps {
  children: React.ReactNode
  extraPaddingX?: number | string
  baseMaxWidth?: number | string
  maxWidth?: number | string
}

export const Container: React.FC<ContainerProps> = ({
  children,
  extraPaddingX = 0,
  baseMaxWidth = 'none',
  maxWidth = 'unset',
  ...props
}) => (
  <ChakraContainer maxW="1920px" px={[2.5, '10px', 5, 5, 5, 32, 44]} {...props}>
    <Box maxW={{ base: baseMaxWidth, '3xl': maxWidth }} mx="auto" px={extraPaddingX}>
      {children}
    </Box>
  </ChakraContainer>
)
