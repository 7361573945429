import { useContext, useEffect } from 'react'
import { TrackableEvent, EventsWithProps, Events } from 'utils/events'
import { TrackingContext } from '../context/TrackingContext'
import { useAmplitude } from './useAmplitude'
import { useTag } from './useTag'

export const useTracking = () => {
  const { userId, setUserId } = useContext(TrackingContext)
  const amplitude = useAmplitude()
  const { tag } = useTag()

  // Logs predefined events to Amplitude
  function track<EventName extends Events>(event: EventName): void
  function track<EventName extends keyof EventsWithProps, Props extends EventsWithProps[EventName]>(
    event: EventName,
    props: Props
  ): void
  function track<
    EventName extends TrackableEvent,
    Props extends EventName extends keyof EventsWithProps ? EventsWithProps[EventName] : undefined
  >(event: EventName, props?: Props): void {
    const commonProps = {
      url: window?.location.href,
      queryString: window?.location.search,
    }
    amplitude?.logEvent(event, { ...commonProps, ...props })
    tag({ event, ...commonProps, ...props })
  }

  // Identifies the current user
  function identify(id: string | null = null): void {
    if (userId !== id) {
      setUserId(id)
      amplitude?.setUserId(id)
    }
  }

  // Opt out of tracking
  function optout(value = true): void {
    amplitude?.setOptOut(value)
  }

  return {
    track,
    identify,
    optout,
  }
}

function useTrackEffect<EventName extends Events>(event: EventName): void
function useTrackEffect<
  EventName extends keyof EventsWithProps,
  Props extends EventsWithProps[EventName]
>(event: EventName, props: Props): void
function useTrackEffect<
  EventName extends TrackableEvent,
  Props extends EventName extends keyof EventsWithProps ? EventsWithProps[EventName] : undefined
>(event: EventName, props?: Props): void {
  const { track } = useTracking()

  useEffect(() => {
    track(event as any, props)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, props])
}

export { useTrackEffect }
