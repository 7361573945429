import { Flex } from '@chakra-ui/react'
import { DEFAULT_PROVIDERS, LoginProviders } from '../../../constants'
import { LoginProvidersButtons } from './LoginProvidersButtons'

interface LoginProps {
  providers?: LoginProviders[]
}

export const Login: React.FC<LoginProps> = ({ providers = DEFAULT_PROVIDERS }) => (
  <Flex
    flexDir="column"
    justifyContent="space-between"
    flex={1}
    p={2.5}
    px={[2.5, 7, 0]}
    w="full"
    maxW={{ sm: '550px' }}
    mx="auto"
  >
    <LoginProvidersButtons />
  </Flex>
)
