import { Image, Heading, Text, VStack, Box, Divider, Flex } from '@chakra-ui/react'
import Head from 'next/head'
import { formatHtmlTitle } from 'utils/globals'
import { Container } from './layout'

interface ErrorPageProps {
  pageHeading?: string
  imageSrc: string
  heading: string
  content: string | JSX.Element
  contentMaxWidth?: string
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  pageHeading,
  imageSrc,
  heading,
  content,
  contentMaxWidth,
}) => (
  <>
    <Head>
      <title key="title">{formatHtmlTitle(pageHeading ?? heading)}</title>
    </Head>
    <Container baseMaxWidth="1280px" mb="24">
      <Box
        mx="auto"
        height="290px"
        background="linear-gradient(269deg, #000 0%, rgba(11, 11, 11, 0.00) 51.56%, #000 100%),radial-gradient(44.14% 71.93% at 50.02% 115.71%, #FFF 0%, rgba(64, 64, 64, 0.00) 100%)"
      />
      <Flex
        mx="auto"
        maxWidth="992px"
        flexDirection="column"
        alignItems="center"
        bg="text-cards"
        position="relative"
        pt="120px"
        pb="60px"
        px="3"
      >
        <Image
          src={imageSrc}
          alt="Broken Clockie"
          position="absolute"
          objectFit="contain"
          top="0"
          transform="translateY(-68%)"
        />
        <VStack spacing="4" color="white" maxW={contentMaxWidth ?? '382px'}>
          <Heading fontWeight="700" fontSize="3xl" textAlign="center">
            {heading}
          </Heading>
          <Divider maxW="382px" />
          <Text fontSize="md" textAlign="center">
            {content}
          </Text>
        </VStack>
      </Flex>
    </Container>
  </>
)
