import { AppValidation } from './AppValidation'
import { AppVerificationSetup } from './AppVerificationSetup'
import { TwoFactorFlowProps } from './types'

export const AppVerificationModal = (props: TwoFactorFlowProps) => {
  const {
    user: { totpVerified },
    allowSetup,
  } = props

  if (totpVerified !== 'verified') {
    return <AppVerificationSetup {...props} />
  }

  return <AppValidation isSetupAllowed={allowSetup ?? false} {...props} />
}
