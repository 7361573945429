export const CreditCard = () => (
  <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 21C0 9.40202 9.40202 0 21 0C32.598 0 42 9.40202 42 21C42 32.598 32.598 42 21 42C9.40202 42 0 32.598 0 21Z"
      fill="#000000"
    />
    <rect
      x="7.5"
      y="12.5"
      width="27"
      height="17"
      rx="2"
      fill="#C2CCDE"
      fillOpacity="0.25"
      stroke="#C2CCDE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="7.5"
      y="15.5"
      width="27"
      height="4"
      fill="#C2CCDE"
      fillOpacity="0.25"
      stroke="#C2CCDE"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.5 23.5H13.5" stroke="#C2CCDE" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.5 26.5H17.5" stroke="#C2CCDE" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
