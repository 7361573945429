import BackendApi from 'lib/api/BackendApi'
import { useQuery } from 'react-query'
import { features } from 'types/vault/Config'

const fetchFeatureAccessibility = (featureName: features): Promise<boolean> =>
  BackendApi.doRequest({
    method: 'GET',
    url: `/v2/market/features/${featureName}/whitelistUser`,
  }).then((res) => res.json())

export const useFeatureAccessibility = (featureName: features) =>
  useQuery([`feature-whitelist`, { featureName }], () => fetchFeatureAccessibility(featureName))
