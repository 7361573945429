import { Box, Flex, FlexProps } from '@chakra-ui/react'

export const FancyDivider = (props: FlexProps) => (
  <Flex color="white" align="center" {...props} cursor="default">
    <Box bg="white" height="1px" w="full" />
    <Flex
      fontWeight="extrabold"
      fontSize="xs"
      transform="rotate(45deg) translateX(0px) scale(1.1) translateY(-1px)"
    >
      <p>&#9744;</p>
    </Flex>
    <Box ml="-1px" bg="white" height="1px" w="full" />
  </Flex>
)
