import { Box, Flex, Text } from '@chakra-ui/react'
import { Button } from 'components/atoms/Button'
import { setCookie, getCookie } from 'react-use-cookie'
import { useState } from 'react'
import Link from 'next/link'

const COOKIE_NOTICE_ACCEPT = 'cookie_notice_accept'

export const CookieConsentBanner = () => {
  const [cookieNoticeAccept, setCookieNoticeAccept] = useState(
    getCookie(COOKIE_NOTICE_ACCEPT) === '1'
  )

  const accept3rdPartyCookkies = () => {
    setCookie(COOKIE_NOTICE_ACCEPT, '1')
    setCookieNoticeAccept(true)
  }

  if (cookieNoticeAccept) {
    return <></>
  }

  return (
    <Box
      boxShadow={{ base: '0px -4px 10px 0px #0000001A;', md: 'none' }}
      __css={{ paddingBottom: 'env(safe-area-inset-bottom)' }}
      w="full"
      pos="fixed"
      bg="rgba(45, 55, 72, 0.95)"
      px={{ base: 2.5, xs: 4, md: 0 }}
      py={{ base: 2.5, xs: 4, md: 0 }}
      insetX={0}
      bottom={0}
      backdropFilter="blur(5px)"
      zIndex="banner"
    >
      <Flex
        align="center"
        height="full"
        p="20px"
        mx="auto"
        justifyContent="center"
        alignItems="center"
        gridGap="10px"
        maxW="992px"
      >
        <Text fontSize="md" lineHeight="22px" fontFamily="Bai Jamjuree">
          We use 3rd party cookies to improve user experience and analyze website traffic. By
          clicking “Accept“, you agree to our website's cookie use as described in our{' '}
          <Link href="/privacy-policy#section-9" passHref>
            <Text color="highlight.default" as="a">
              Cookie Policy
            </Text>
          </Link>
          .
        </Text>
        <Button variant="light" size="md" maxW="213px" w="full" onClick={accept3rdPartyCookkies}>
          Accept
        </Button>
      </Flex>
    </Box>
  )
}
