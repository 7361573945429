import { Flex } from '@chakra-ui/react'
import { AddCountryAndDobModal } from 'components/organisms/AddCountryAndDob'
import { CookieConsentBanner } from 'components/organisms/CookieConsentBanner'
import { AmbassadorLayout } from 'components/templates/layout/AmbassadorLayout'
import { PartnerPortalLayout } from 'components/templates/layout/PartnerPortalLayout'
import { useRouter } from 'next/router'
import { Login2FAModal } from '../../organisms/auth/Login2FAModal'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import { SuspendedLayout } from './SuspendedLayout'

interface LayoutProps {
  children: React.ReactNode
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const router = useRouter()

  const isPartnerPortal = router.pathname.startsWith('/partner-portal')
  if (isPartnerPortal) {
    return <PartnerPortalLayout>{children}</PartnerPortalLayout>
  }

  const isAmbassadorPortal = router.pathname.startsWith('/ambassador')
  if (isAmbassadorPortal) {
    return <AmbassadorLayout>{children}</AmbassadorLayout>
  }

  const isSuspendedPage = router.pathname.startsWith('/suspended')
  if (isSuspendedPage) {
    return <SuspendedLayout>{children}</SuspendedLayout>
  }

  const isConsentPage = router.pathname === '/consent'
  const isTermOfUsePage = router.pathname === '/terms-of-use'
  return (
    <Flex direction="column" minH="100vh">
      <Navbar />
      <Flex as="main" flexGrow={1} direction="column">
        {children}
      </Flex>
      {/* <NewsLetterModal /> */}
      <Login2FAModal />
      <CookieConsentBanner />
      {!isTermOfUsePage && <AddCountryAndDobModal />}
      {!isConsentPage && <Footer />}
    </Flex>
  )
}
