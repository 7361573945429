import { Box, Flex, Skeleton } from '@chakra-ui/react'

export interface CardSkeletonProps {
  numCards?: number
}

export const CardSkeleton: React.FC<CardSkeletonProps> = ({ numCards = 5 }) => (
  <>
    {new Array(numCards).fill(null).map((_, i) => (
      <Flex flexDir="column" key={i}>
        <Skeleton
          width="100%"
          sx={{ aspectRatio: '1' }}
          startColor="gray.800"
          endColor="bright.200"
        />
        <Box bg="bright.100" p={3}>
          <Skeleton h="3" w="8" mb="3" startColor="gray.800" endColor="bright.200" />
          <Skeleton h="4" w="20" mb="6" startColor="gray.800" endColor="bright.200" />
          <Skeleton h="3" w="8" mb="3" startColor="gray.800" endColor="bright.200" />
        </Box>
      </Flex>
    ))}
  </>
)
