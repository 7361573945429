import AuthApi from 'lib/api/AuthApi'
import { useQuery } from 'react-query'

interface TOTPUri {
  uri: string
}

interface UseGet2FAURLOptions {
  enabled: boolean
}

const fetch2FAURL = (): Promise<TOTPUri> =>
  AuthApi.doRequest({
    method: 'GET',
    url: '/auth/upgrade/totp/url',
  }).then((res) => res.json())

export const useGet2FAURL = ({ enabled }: UseGet2FAURLOptions = { enabled: true }) =>
  useQuery(['2FA-url'], fetch2FAURL, { enabled })
