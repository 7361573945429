import { features } from '../types/vault/Config'
import { useConfig } from './useConfig'

export const useFeature = (feature: features | features[]) => {
  const { data } = useConfig()

  const hasFeature = Array.isArray(feature)
    ? feature.every((f) => data?.features[f])
    : data?.features[feature] ?? false

  return hasFeature
}

export const useAnyFeature = (feature: features | features[]) => {
  const { data } = useConfig()

  const hasFeature = Array.isArray(feature)
    ? feature.some((f) => data?.features[f])
    : data?.features[feature] ?? false

  return hasFeature
}

export const useGetAllFeatures = () => {
  const { data } = useConfig()

  if (!data) return []

  const activeFeatures = Object.entries(data.features)
    .filter(([_, isEnabled]) => isEnabled)
    .map(([feature]) => feature)

  return activeFeatures
}
