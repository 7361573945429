import {
  Circle,
  SquareProps,
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
  BoxProps,
} from '@chakra-ui/react'

export interface AvatarProps extends Omit<ChakraAvatarProps, 'size'> {
  size?: BoxProps['width']
  circleProps?: SquareProps
}

export const Avatar: React.FC<AvatarProps> = ({ size = '94px', circleProps, ...props }) => (
  <Circle size={size} pos="relative" overflow="hidden" {...circleProps}>
    <ChakraAvatar pos="absolute" w="full" h="full" {...props} />
  </Circle>
)
