import {
  Text,
  Box,
  Heading,
  Modal as ChakraModal,
  ModalContent,
  ModalOverlay,
  Divider,
  HStack,
  Image,
  VStack,
  ModalProps as ChakraModalProps,
  ResponsiveValue,
  Flex,
} from '@chakra-ui/react'
import { Button } from 'components/atoms/Button'
import { ReactNode } from 'react'
/* eslint-disable */
import { Token } from '@chakra-ui/styled-system/dist/types/utils'
/* eslint-disable */
import * as CSS from 'csstype'

export interface ModalProps extends ChakraModalProps {
  title?: string
  description?: string
  confirmBtn?: {
    label: string
    onClick: () => void
  }
  hideButtons?: boolean
  smallerTitle?: boolean
  hideTitleDivider?: boolean
  buttons?: JSX.Element
  marginTop?: Token<CSS.Property.Margin | number>
  marginTopForContent?: Token<CSS.Property.Margin | number>
  showOverlayInMobile?: boolean
}

export const Modal: React.FC<ModalProps> = ({
  title,
  description,
  onClose,
  confirmBtn,
  children,
  smallerTitle,
  hideButtons,
  hideTitleDivider,
  marginTop,
  marginTopForContent,
  buttons,
  ...props
}) => (
  <ChakraModal size="full" onClose={onClose} {...props}>
    <ModalOverlay display={{ base: 'none', md: 'block' }} color={'black'} />
    <ModalContent
      borderRadius={{ base: 0, md: '12px' }}
      overflow="hidden"
      h={{ base: '100vh', md: 'unset' }}
      minH={{ md: 'unset' }}
      mt={marginTop ?? { base: 0, md: '200px', lg: '250px' }}
      maxW={{ md: '550px', lg: '680px' }}
      display="flex"
      flexDirection="column"
      mb={0}
    >
      <VStack flex={1} justifyContent="space-between" bg={{ base: 'black', md: 'text-cards' }}>
        <Box
          pos="relative"
          textAlign="center"
          px={{ base: '30px', md: 14, lg: '110px' }}
          py={smallerTitle ? '20px' : { base: 10, md: 7 }}
          mt={marginTopForContent ?? '0'}
          mx="auto"
          width={{ base: '92%', md: '100%' }}
          bgColor="container"
        >
          <Button
            size="auto"
            variant="none"
            pos="absolute"
            top={smallerTitle ? '24px' : { base: 5, lg: 8 }}
            right={{ base: 5, lg: '37px' }}
            display={{ base: smallerTitle || hideTitleDivider ? 'block' : 'none', md: 'block' }}
            onClick={onClose}
          >
            <Image src="/images/icons/close.svg" alt="close icon" />
          </Button>

          {title &&
            (smallerTitle ? (
              <>
                <Text color={'gray.500'} fontSize="16px" fontWeight="400" lineHeight="22px">
                  {title}
                </Text>
                {hideTitleDivider || <Divider mt={'12px'} bg="gray.500" />}
              </>
            ) : (
              <>
                <Heading maxW={{ base: '300px', md: 'unset' }} mx="auto">
                  {title}
                </Heading>
                {hideTitleDivider || <Divider mt={{ base: '30px', md: 5 }} bg="gray.500" />}
              </>
            ))}
          {description &&
            (smallerTitle ? (
              <Text
                fontFamily="Montserrat"
                mt={'22px'}
                color={'white'}
                fontSize="20px"
                fontWeight="700"
                lineHeight="22px"
                maxW={{ base: '300px', md: 'unset' }}
                mx="auto"
              >
                {description}
              </Text>
            ) : (
              <Text
                mt={{ base: '30px', md: 5 }}
                color="gray.300"
                fontSize={{ base: '20px', md: '14px' }}
                maxW={{ base: '520px' }}
              >
                {description}
              </Text>
            ))}
          {children}
          {buttons && (
            <Flex
              w="full"
              direction="column"
              alignItems="center"
              display={{ base: 'none', md: 'block' }}
            >
              <Divider mb="35px" />
              {buttons}
            </Flex>
          )}
        </Box>

        {!hideButtons && (
          <HStack
            py={6}
            px={[2.5, 5, 10]}
            w="full"
            mt={5}
            display={{ base: 'flex', md: 'none' }}
            spacing={{ base: 2.5, sm: 5 }}
          >
            {buttons ?? (
              <>
                <Button variant="light-outline" size="full" flex={1} h="49px" onClick={onClose}>
                  Cancel
                </Button>
                {confirmBtn && (
                  <Button size="full" flex={1} h="49px" onClick={confirmBtn.onClick}>
                    {confirmBtn.label}
                  </Button>
                )}
              </>
            )}
          </HStack>
        )}
      </VStack>
    </ModalContent>
  </ChakraModal>
)
