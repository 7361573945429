import { useRef, useEffect } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

interface VideoProps extends BoxProps {
  src: string
  poster: string
}

export const Video: React.FC<VideoProps> = ({ src, poster, ...props }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null)

  // This effect is to load and play videos only when they are in viewport
  useEffect(() => {
    const currentVideo = videoRef.current

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (currentVideo && (!currentVideo.src || currentVideo.src !== src)) {
              currentVideo.src = src || ''
            }
            currentVideo?.play().catch(() => undefined)
          } else {
            currentVideo?.pause()
          }
        })
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0, // Play animation when when item starts to be visible in viewport
      }
    )

    if (currentVideo) observer.observe(currentVideo)

    return () => {
      if (currentVideo) observer.unobserve(currentVideo)
    }
  }, [src])

  return (
    <Box
      sx={{
        video: {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        },
      }}
      {...props}
    >
      <video
        ref={videoRef}
        playsInline
        loop
        muted
        style={{ pointerEvents: 'none' }}
        poster={poster}
      />
    </Box>
  )
}
