import AuthApi from 'lib/api/AuthApi'
import { useMutation, useQueryClient } from 'react-query'

const fetchCountryAndDob = ({
  dateOfBirth,
  countryCode,
}: {
  dateOfBirth: Date
  countryCode: string
}): Promise<{ user: any }> =>
  AuthApi.doRequest({
    method: 'POST',
    url: '/v2/user/country-dob',
    body: {
      dateOfBirth: dateOfBirth.toISOString(),
      countryCode,
    },
  }).then((res) => res.json())

export const useCountryAndDobMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(fetchCountryAndDob, {
    onSuccess: () => {
      queryClient.invalidateQueries(['me'])
    },
  })
}
