import Script from 'next/script'
import { ZENDESK_BOT_KEY } from '../constants'

const ZendeskBot = () => {
  if (!ZENDESK_BOT_KEY) {
    return null
  }
  return (
    <Script
      async
      id="ze-snippet"
      src={`https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_BOT_KEY}`}
    />
  )
}

export default ZendeskBot
