import { useEffect, useRef } from 'react'
import {
  Box,
  Center,
  Collapse,
  Heading,
  HStack,
  Image,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import { Avatar, Button, ButtonLink, DropdownLinkMenu } from 'components/atoms'
import AdminLoginAlert from 'components/organisms/AdminLoginAlert'
import { useClickOutside } from 'hooks/useClickOutside'
import { useCloseOnNavigation } from 'hooks/useCloseOnNavigation'
import { useDesktop } from 'hooks/useDesktop'
import { useFeature } from 'hooks/useFeature'
import { usePremiumCurrencyList } from 'hooks/usePremiumCurrencyList'
import { useTokenList } from 'hooks/useTokenList'
import { useUser } from 'hooks/useUser'
import { formatMoney } from 'lib/format'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { TokensWidget } from 'components/organisms/TokensWidget'
import { DEFAULT_MARKETPLACE_ROUTE, OPENLOOT_MEDIUM_URL } from '../../../../constants'
import { MobileNavMenu, MobileUserMenu } from '../MobileMenu'
import { UserMenu } from './UserMenu'

export const USER_NAV_ITEMS = [
  {
    name: 'Profile',
    url: '/account/profile',
  },
  {
    name: 'My Collection',
    url: '/collection',
  },
  {
    name: 'Wallet',
    url: '/wallet',
  },
  {
    name: 'Enter Invite Code',
    url: '/invite/redeem',
  },
  {
    name: 'Redemption Tool',
    url: '/redeem',
  },
]

const HIDE_SIGN_IN_UP_BUTTONS_PATHS = ['/authenticated']

export const Navbar = () => {
  const router = useRouter()
  const { data: user, isLoading } = useUser()
  const { data: tokenList } = useTokenList({})
  const { data: currencyList } = usePremiumCurrencyList({})
  const { isOpen, onOpen, onClose } = useCloseOnNavigation()

  const {
    isOpen: isMobileUserOpen,
    onToggle: onToggleMobileUser,
    onClose: onCloseMobileUser,
  } = useDisclosure()
  const {
    isOpen: isMobileNavOpen,
    onToggle: onToggleMobileNav,
    onClose: onCloseMobileNav,
  } = useDisclosure()

  const closeBtnRef = useRef(null)
  const userBtnRef = useRef(null)
  const [userMenuRef] = useClickOutside(onCloseMobileUser, [userBtnRef])
  const [navMenuRef] = useClickOutside(onCloseMobileNav, [closeBtnRef])

  const showSignInUpButtons = !HIDE_SIGN_IN_UP_BUTTONS_PATHS.includes(router.pathname)
  const isDesktop = useDesktop()
  const showPreseasonInviteInMenu = useFeature('preseasonInvite')
  const userNavItems = showPreseasonInviteInMenu
    ? USER_NAV_ITEMS
    : USER_NAV_ITEMS.filter((item) => item.url !== '/invite/redeem')
  const isConsentPage = router.pathname === '/consent'
  const hasRentalsFeature = useFeature('rentals')
  const hasPremiumCurrency = useFeature('premiumCurrency')
  let marketplaceMenuItems
  if (!hasRentalsFeature && !hasPremiumCurrency) {
    marketplaceMenuItems = {
      name: 'Marketplace',
      url: DEFAULT_MARKETPLACE_ROUTE,
    }
  } else {
    const subItems = [
      {
        name: 'NFT Marketplace',
        url: DEFAULT_MARKETPLACE_ROUTE,
      },
    ]
    if (hasPremiumCurrency) {
      subItems.push({
        name: 'Premium Currency',
        url: '/wallet/premium-currencies',
      })
    }
    if (hasRentalsFeature) {
      subItems.push({
        name: 'Rentals',
        url: '/marketplace/rentals',
      })
    }
    marketplaceMenuItems = {
      name: 'Marketplace',
      url: DEFAULT_MARKETPLACE_ROUTE,
      subItems,
    }
  }

  const NAV_ITEMS = [
    marketplaceMenuItems,
    {
      name: 'Games',
      url: '/games',
    },
    {
      name: 'News',
      url: OPENLOOT_MEDIUM_URL,
      target: '_blank',
    },
  ]

  useEffect(() => {
    if (isDesktop) {
      onCloseMobileUser()
      onCloseMobileNav()
      if (isMobileUserOpen) onOpen()
    } else {
      onClose()
      if (isOpen) onToggleMobileUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop])

  const toggleMobileNav = () => {
    onToggleMobileNav()
    if (isMobileUserOpen) onCloseMobileUser()
  }

  const toggleMobileUser = () => {
    onToggleMobileUser()
    if (isMobileNavOpen) onCloseMobileNav()
  }

  return (
    <Box pos="relative">
      <Box
        bg="blackAlpha.600"
        boxShadow="headerMenu"
        as="header"
        zIndex="popover"
        backdropFilter="blur(7px)"
        pos="sticky"
        top={0}
      >
        <HStack
          h={{ base: '72px', lg: '84px', '3xl': '105px' }}
          px={[2.5, 3, 5, 7, '15px']}
          justify={isConsentPage ? 'center' : 'space-between'}
        >
          {/* Mobile nav menu button */}

          {!isConsentPage && (
            <Button
              ref={closeBtnRef}
              size="auto"
              variant="none"
              display={{ md: 'none' }}
              onClick={toggleMobileNav}
            >
              <Center border="1px" borderRadius="base" borderColor="whiteAlpha.200" w={10} h={10}>
                <Image
                  src={isMobileNavOpen ? '/images/icons/close-bold.svg' : '/images/icons/menu.svg'}
                  alt={isMobileNavOpen ? 'close icon' : 'menu icon'}
                />
              </Center>
            </Button>
          )}

          <Link href="/" passHref>
            <Image
              width="154px"
              height="46px"
              src="/images/open-loot-logo.svg"
              alt="open loot logo"
              cursor="pointer"
            />
          </Link>

          {!isConsentPage && (
            <HStack as="nav" spacing={{ md: 10, lg: 16, xl: 20 }}>
              <UnorderedList
                color="nav"
                listStyleType="none"
                m={0}
                display={{ base: 'none', md: 'flex' }}
              >
                {NAV_ITEMS.map(({ name, url, target, subItems }, i) => {
                  const isActive = router.pathname === url

                  return (
                    <ListItem
                      key={i}
                      pr={i === NAV_ITEMS.length - 1 ? 0 : 7}
                      mr={i === NAV_ITEMS.length - 1 ? 0 : 7}
                      py="9px"
                      borderRight={i === NAV_ITEMS.length - 1 ? '' : '1px solid'}
                      borderColor="divider"
                    >
                      {subItems ? (
                        <DropdownLinkMenu label={name} options={subItems} />
                      ) : (
                        <Link href={url} passHref>
                          <Text
                            size="sm"
                            as="a"
                            target={target}
                            cursor="pointer"
                            fontWeight={isActive ? 700 : 400}
                            color={isActive ? 'navHover' : 'nav'}
                            _hover={{
                              ...(!isActive && {
                                textShadow: '-0.5px 0 #fff, 0.5px 0 #fff',
                                color: 'navHover',
                                transition: 'all .5s',
                              }),
                            }}
                          >
                            {name}
                          </Text>
                        </Link>
                      )}
                    </ListItem>
                  )
                })}
              </UnorderedList>

              {!user && !isLoading && showSignInUpButtons && (
                <HStack spacing={2}>
                  <ButtonLink
                    href="/login"
                    variant="light-outline"
                    display={{ base: 'block', md: 'none' }}
                    w={[20, null, 24, null, '118px']}
                  >
                    Sign In
                  </ButtonLink>
                  <ButtonLink
                    href="/login"
                    variant="ghost"
                    display={{ base: 'none', md: 'block' }}
                    w={[20, null, 24, null, '118px']}
                  >
                    Sign In
                  </ButtonLink>
                  <ButtonLink
                    href="/login"
                    variant="light"
                    w={[20, null, 24, null, '118px']}
                    display={{ base: 'none', md: 'block' }}
                  >
                    Sign Up
                  </ButtonLink>
                </HStack>
              )}

              {user && (
                <HStack spacing={5}>
                  {/* Desktop */}
                  <Link href="/wallet" passHref>
                    <Heading
                      fontSize="xl"
                      lineHeight="22px"
                      display={{ base: 'none', md: 'block' }}
                      cursor="pointer"
                    >
                      {formatMoney(user.balance)}
                    </Heading>
                  </Link>
                  <Box display={{ base: 'none', md: 'block' }}>
                    <Menu isOpen={isOpen} onClose={onClose} placement="bottom-end">
                      <MenuButton
                        rounded="full"
                        variant="none"
                        size="auto"
                        cursor="pointer"
                        minW={0}
                        onClick={onOpen}
                      >
                        <Avatar size={10} name={user.username} />
                      </MenuButton>
                      <MenuList maxW="202px" p={2} bg="white" borderRadius="sm">
                        <UserMenu {...user} px={2.5} navItems={userNavItems} />
                      </MenuList>
                    </Menu>
                  </Box>

                  {/* Mobile */}
                  <Button
                    ref={userBtnRef}
                    variant="none"
                    size="auto"
                    onClick={toggleMobileUser}
                    display={{ md: 'none' }}
                  >
                    <Avatar size={10} name={user.username} />
                  </Button>
                </HStack>
              )}
            </HStack>
          )}
        </HStack>
        <Collapse in={isMobileNavOpen} animateOpacity ref={navMenuRef}>
          <MobileNavMenu onClose={onCloseMobileNav} />
        </Collapse>
        <Collapse in={isMobileUserOpen} animateOpacity ref={userMenuRef}>
          <MobileUserMenu
            onClose={onCloseMobileUser}
            username={user?.username ?? ''}
            balance={user?.balance ?? 0}
            navItems={userNavItems}
          />
        </Collapse>
      </Box>
      {user && <TokensWidget user={user} tokenList={tokenList} currencyList={currencyList} />}
      {user && user.isAdminLogin && <AdminLoginAlert user={user} />}
    </Box>
  )
}
