import BackendApi from 'lib/api/BackendApi'
import { useQuery } from 'react-query'
import { GAMES_HIDDEN } from '../constants'
import ServerSideBackendApi from '../lib/api/ServerSideBackendApi'
import { GameList } from '../types/vault/Game'

export const fetchGameList = (): Promise<GameList> =>
  BackendApi.doRequest({
    method: 'GET',
    url: '/v2/market/games',
  }).then((res) => res.json())

export const fetchGameListServerSide = (): Promise<GameList> =>
  ServerSideBackendApi.doRequest({
    method: 'GET',
    url: '/v2/market/games',
  }).then((res) => res.json())

export const useGameList = ({ options = {} }) => useQuery(['games'], fetchGameList, options)

export const fetchActiveGameList = (): Promise<GameList> =>
  BackendApi.doRequest({
    method: 'GET',
    url: '/v2/market/games',
  })
    .then((res) => res.json())
    .then((gameList: GameList) => ({
      ...gameList,
      list: gameList.list.filter((game) => !GAMES_HIDDEN.includes(game.id)),
    }))

export const fetchActiveGameListServerSide = (): Promise<GameList> =>
  ServerSideBackendApi.doRequest({
    method: 'GET',
    url: '/v2/market/games',
  })
    .then((res) => res.json())
    .then((gameList: GameList) => ({
      ...gameList,
      list: gameList.list.filter((game) => !GAMES_HIDDEN.includes(game.id)),
    }))

export const useActiveGameList = ({ options = {} }) =>
  useQuery(['active-games'], fetchActiveGameList, options)
