import {
  Image,
  Popover as ChakraPopover,
  PopoverProps as ChakraPopoverProps,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'

export interface PopoverProps extends ChakraPopoverProps {
  children: React.ReactNode
  popoverIcon?: React.ReactNode
}

export const Popover: React.FC<PopoverProps> = ({ children, popoverIcon, ...props }) => (
  <ChakraPopover trigger="hover" {...props}>
    <PopoverTrigger>
      {popoverIcon || (
        <Image
          src="/images/icons/info-gray.svg"
          alt="info icon"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        />
      )}
    </PopoverTrigger>
    <PopoverContent
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <PopoverArrow bg="white" />
      <PopoverBody bg="white" p={0}>
        {children}
      </PopoverBody>
    </PopoverContent>
  </ChakraPopover>
)
