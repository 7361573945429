import AuthApi from 'lib/api/AuthApi'
import { useMutation, useQueryClient } from 'react-query'
import { ServiceError } from '../lib/api/ServiceError'
import { User } from '../types/vault/User'

interface TOTPAuthPayload {
  authPayload: string
}

interface TOTPVerifyResponse {
  status: User['totpVerified']
  recoveryCodes: string[]
}

const verifyTOTPFactor = ({ authPayload }: TOTPAuthPayload): Promise<TOTPVerifyResponse> =>
  AuthApi.doRequest({
    method: 'POST',
    url: '/auth/upgrade/totp/verify',
    body: {
      authPayload,
    },
  }).then((response) => response.json())

export const useVerify2FAMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<TOTPVerifyResponse, ServiceError, TOTPAuthPayload>(verifyTOTPFactor, {
    onSettled: () => {
      queryClient.invalidateQueries('me')
    },
  })
}
