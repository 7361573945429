import { CurrencyId } from 'types/vault/v2/Currency'
import { MAX_SAFE_NUMBER } from '../constants'

export const formatCurrency = (
  amount = 0,
  currency?: CurrencyId,
  opts: Intl.NumberFormatOptions = {}
) => {
  const isSafeNumber = amount <= MAX_SAFE_NUMBER
  const options: Intl.NumberFormatOptions = {
    style: currency === CurrencyId.USD ? 'currency' : 'decimal',
    currency: currency === CurrencyId.USD ? 'USD' : undefined,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...(!isSafeNumber && {
      notation: 'compact',
      compactDisplay: 'short',
    }),
    ...opts,
  }

  // Check if it's a clean currency amount
  if (amount - Math.floor(amount) === 0 && !opts.minimumFractionDigits) {
    options.minimumFractionDigits = 0
  }

  const formatter = Intl.NumberFormat('en-US', options)

  if (!currency || currency === CurrencyId.USD || currency === CurrencyId.USDC) {
    return formatter.format(amount)
  }
  return `${formatter.format(amount)} ${currency}`
}

export const formatMoney = (amount = 0, opts: Intl.NumberFormatOptions = {}) => {
  const isSafeNumber = amount <= MAX_SAFE_NUMBER
  const options: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    ...(!isSafeNumber && {
      notation: 'compact',
      compactDisplay: 'short',
    }),
    ...opts,
  }

  // Check if it's a clean dollar amount
  if (amount - Math.floor(amount) === 0 && !opts.minimumFractionDigits) {
    options.minimumFractionDigits = 0
  }

  const formatter = Intl.NumberFormat('en-US', options)

  return formatter.format(amount)
}

export const formatNumber = (amount = 0, opts: Intl.NumberFormatOptions = {}) => {
  const isSafeNumber = amount <= MAX_SAFE_NUMBER
  const options: Intl.NumberFormatOptions = {
    ...(!isSafeNumber && {
      notation: 'compact',
      compactDisplay: 'short',
    }),
    ...opts,
  }

  const formatter = Intl.NumberFormat('en-US', options)

  return formatter.format(amount)
}

export const calculateWithdrawFee = (amount: number, percentage: number) =>
  Number(((amount * percentage) / 100).toFixed(4))

export const calculateActualWithdrawAmount = (total: number, fee: number) =>
  Number((total - fee).toFixed(4))

export const formatDate = (date: Date, opts: Intl.DateTimeFormatOptions = {}) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    ...opts,
  }

  const formatter = Intl.DateTimeFormat('en-US', options)

  return formatter.format(date)
}

export const formatDateInUTC = (date: Date, opts: Intl.DateTimeFormatOptions = {}) =>
  formatDate(date, {
    timeZone: 'utc',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
    ...opts,
  })

export const formatTime = (date: Date, opts: Intl.DateTimeFormatOptions = {}) => {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'utc',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    ...opts,
  }

  const formatter = Intl.DateTimeFormat('en-US', options)

  return formatter.format(date)
}

export const formatShortTime = (date: Date, opts: Intl.DateTimeFormatOptions = {}) => {
  const options: Intl.DateTimeFormatOptions = {
    timeZone: 'utc',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    ...opts,
  }

  const formatter = Intl.DateTimeFormat('en-US', options)

  return formatter.format(date)
}

export const capitalize = (str = '') => str.charAt(0).toUpperCase() + str.slice(1)

export const shortenDate = (dateString: string) => {
  const date = new Date(dateString)
  const day = date.getUTCDate()
  const month = date.getUTCMonth() + 1
  return `${month}/${day}`
}

export const timeSince = (date: Date) => {
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000)

  let interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) === 1
      ? `${Math.floor(interval)} year ago`
      : `${Math.floor(interval)} years ago`
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? `${Math.floor(interval)} month ago`
      : `${Math.floor(interval)} months ago`
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? `${Math.floor(interval)} day ago`
      : `${Math.floor(interval)} days ago`
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? `${Math.floor(interval)} hour ago`
      : `${Math.floor(interval)} hours ago`
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? `${Math.floor(interval)} minute ago`
      : `${Math.floor(interval)} minutes ago`
  }
  return Math.floor(interval) === 1
    ? `${Math.floor(interval)} second ago`
    : `${Math.floor(interval)} seconds ago`
}

export const timeLeft = (date: Date) => {
  const seconds = Math.floor((date.getTime() - Date.now()) / 1000)

  let interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) === 1
      ? `${Math.floor(interval)}y left`
      : `${Math.floor(interval)}y left`
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? `${Math.floor(interval)}m left`
      : `${Math.floor(interval)}m left`
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? `${Math.floor(interval)}d left`
      : `${Math.floor(interval)}d left`
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? `${Math.floor(interval)}h left`
      : `${Math.floor(interval)}h left`
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) === 1
      ? `${Math.floor(interval)}m left`
      : `${Math.floor(interval)}m left`
  }
  return Math.floor(interval) > 1 ? `${Math.floor(interval)}s left` : `0s left`
}

export const formatPeriodInDays = (seconds: number): string => {
  const days = Math.floor(seconds / (24 * 60 * 60))
  const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60))
  const minutes = Math.floor((seconds % (60 * 60)) / 60)
  const leftSeconds = Math.floor(seconds % 60)

  const parts = []

  if (days > 0) parts.push(`${days} day${days !== 1 ? 's' : ''}`)
  if (hours > 0) parts.push(`${hours} hour${hours !== 1 ? 's' : ''}`)
  if (minutes > 0) parts.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`)
  if (leftSeconds > 0) parts.push(`${leftSeconds} second${leftSeconds !== 1 ? 's' : ''}`)

  return parts.join(', ')
}
