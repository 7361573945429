import {
  Text,
  FormLabel,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps,
} from '@chakra-ui/react'
import { LegacyRef } from 'react'
import { Option } from '../Option'

const variants = {
  filter: {
    width: 'auto',
    borderRadius: 0,
    border: 'none',
    bgColor: 'whiteAlpha.100',
  },
}

interface SelectProps extends ChakraSelectProps {
  title?: string
  variant?: keyof typeof variants
  options?: { label: string; value: string }[]
  label?: string | React.ReactNode
  ref?: LegacyRef<HTMLSelectElement>
  value?: any
}

export const Select: React.FC<SelectProps> = ({
  children,
  title,
  variant,
  label,
  options = [],
  ref,
  value,
  ...props
}) => (
  <FormLabel m={0} flex={props.flex ?? 'initial'}>
    {label &&
      (typeof label === 'string' ? (
        <Text mb={2} lineHeight="22px">
          {label}
        </Text>
      ) : (
        label
      ))}

    <ChakraSelect
      defaultValue={value ?? ''}
      fontSize="md"
      lineHeight="19px"
      cursor="pointer"
      ref={ref}
      value={value}
      {...(variant && variants[variant])}
      {...props}
    >
      {title && (
        <Option value="" disabled>
          {title}
        </Option>
      )}

      {options.map(({ label, value }, i) => (
        <Option key={`${value}-${i}`} value={value}>
          {label}
        </Option>
      ))}

      {children}
    </ChakraSelect>
  </FormLabel>
)
