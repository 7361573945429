import { Span } from 'components/atoms'
import { KycStatus } from 'types/vault/User'

interface StatusStrProps {
  status: KycStatus
  statusStr: string
}

const getKycStatusColor = (status: KycStatus) => {
  switch (status) {
    case KycStatus.NOT_STARTED: {
      return 'state.inactive'
    }
    case KycStatus.INIT: {
      return 'state.warning'
    }
    case KycStatus.PENDING: {
      return 'state.pending'
    }
    case KycStatus.SUCCESS: {
      return 'state.success'
    }
    case KycStatus.RETRY: {
      return 'state.warning'
    }
    case KycStatus.ERROR:
    default: {
      return 'state.error'
    }
  }
}

export const StatusStr: React.FC<StatusStrProps> = ({ status, statusStr }) => (
  <Span color={getKycStatusColor(status)} fontWeight="medium">
    ({statusStr})
  </Span>
)
