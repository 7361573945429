import BackendApi from 'lib/api/BackendApi'
import { UseQueryOptions } from 'react-query'
import { ItemOption } from 'types/vault/ItemOption'
import { Listing } from 'types/vault/v2/Listing'
import { QueryResult } from '../types/QueryResult'

// TODO use single interface for both useOptions and useSecondaryOptions
interface UseOptionsPropsV2 {
  primary?: boolean
  page?: number
  pageSize?: number
  rarity?: string
  tags?: string
  nftTags?: string
  options?: UseQueryOptions<QueryResult<Listing>>
  order?: string
  sort?: string
  gameId?: string
  priceTo?: number
  priceFrom?: number
  mintedTo?: number
  mintedFrom?: number
  q?: string
  onSale?: boolean
}

interface UseOptionsProps {
  primary?: boolean
  page?: number
  pageSize?: number
  rarity?: string
  tags?: string[]
  nftTags?: string[]
  options?: UseQueryOptions<QueryResult<ItemOption>>
  order?: string
  sort?: string
  gameId?: string
}

export const fetchOptions = ({
  primary,
  page = 1,
  pageSize = 100,
  tags = [],
  nftTags = [],
  order,
  sort,
  rarity,
  gameId,
}: Omit<UseOptionsProps, 'options'>): Promise<QueryResult<ItemOption>> =>
  BackendApi.doRequest({
    method: 'GET',
    url: '/market/options',
    params: {
      primary,
      page,
      pageSize,
      tags,
      nftTags,
      rarity,
      order,
      sort,
      gameId,
    },
  }).then((res) => res.json())

export const fetchOptionsV2 = ({
  page = 1,
  tags,
  nftTags,
  sort,
  rarity,
  gameId,
  priceFrom,
  priceTo,
  mintedFrom,
  mintedTo,
  q,
  onSale,
}: Omit<UseOptionsPropsV2, 'options'>): Promise<QueryResult<Listing>> =>
  BackendApi.doRequest({
    method: 'GET',
    url: '/v2/market/listings',
    params: {
      // primary,
      page,
      // pageSize,
      tags: tags || undefined,
      nftTags: nftTags || undefined,
      rarity: rarity || undefined,
      // order,
      sort,
      gameId,
      priceFrom,
      priceTo,
      mintedFrom,
      mintedTo,
      q,
      onSale,
    },
  }).then((res) => res.json())
