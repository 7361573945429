import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  NumberInput as CharkaNumberInput,
  NumberInputProps as ChakraNumberInputProps,
} from '@chakra-ui/react'

type NumberInputProps = ChakraNumberInputProps

export const NumberInput: React.FC<NumberInputProps> = (props) => (
  <CharkaNumberInput CharkaNumberInput min={0} borderColor="gray.500" fontSize="18px" {...props}>
    <NumberInputField />
    <NumberInputStepper>
      <NumberIncrementStepper />
      <NumberDecrementStepper />
    </NumberInputStepper>
  </CharkaNumberInput>
)
