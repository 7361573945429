import { Box, BoxProps } from '@chakra-ui/react'
import Markdown from 'react-markdown'

interface ReactMarkdownProps {
  content?: string
  boxProps?: BoxProps
}

export const ReactMarkdown: React.FC<ReactMarkdownProps> = ({ content = '', boxProps }) => (
  <Box
    sx={{
      a: {
        color: '#ED8936',
        fontWeight: 'bold',
      },
      'a:hover': {
        textDecoration: 'underline',
      },
      ul: { listStylePos: 'inside' },
    }}
    {...boxProps}
  >
    <Markdown>{content.replaceAll('\\n', '\n')}</Markdown>
  </Box>
)
