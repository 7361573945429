import { Box, useDisclosure, useToast, Flex } from '@chakra-ui/react'
import { RequireFeature } from 'components/atoms'
import { TwoFactorModal } from 'components/organisms/auth'
import { getAvailableFlowsFromUser } from 'components/organisms/auth/types'
import { useHas2FA } from 'hooks/useHas2FA'
import { useUser } from 'hooks/useUser'
import { ServiceError } from 'lib/api/ServiceError'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { PartnerPortalNavbar, PartnerPortalSidebar } from 'components/organisms/partner-portal'
import { PartnerPortalProvider } from 'context/PartnerPortalContext'
import { usePartnerPortal } from 'hooks/usePartnerPortal'
import { hasGamePermission, isOpenlootAdmin } from 'lib/permissions'
import { PermissionDenied } from 'components/organisms/PermissionDenied'
import Head from 'next/head'
import { formatHtmlTitle } from 'utils/globals'
import { DEFAULT_MARKETPLACE_ROUTE } from '../../../constants'

interface PartnerPortalLayoutProps {
  children: React.ReactNode
}

const PartnerPortalLayout = ({ children }: PartnerPortalLayoutProps) => {
  const router = useRouter()
  const { allowedGames, selectedGame } = usePartnerPortal()
  const { data: user } = useUser()
  const { mutate: has2FAMutation } = useHas2FA()
  const {
    isOpen: isPhoneVerificationOpen,
    onOpen: onPhoneVerificationOpen,
    onClose: onPhoneVerificationClose,
  } = useDisclosure()
  const toast = useToast()

  const [has2FA, setHas2FA] = useState(false)
  const onClose = () => {
    onPhoneVerificationClose()
    if (!has2FA) {
      router.push(DEFAULT_MARKETPLACE_ROUTE)
    }
  }

  const onComplete = () => {
    setHas2FA(true)
    onPhoneVerificationClose()
  }

  const connect = () => {
    has2FAMutation(undefined, {
      onSuccess: () => {
        setHas2FA(true)
      },
      onError: (error: any) => {
        if ((error as ServiceError).code === 403) {
          onPhoneVerificationOpen()
          return
        }
        const errorMessage =
          error instanceof ServiceError
            ? `${error?.payload?.error || error?.message}`
            : 'An error occurred'
        toast({
          title: 'Error',
          description: errorMessage,
          status: 'error',
        })
      },
    })
  }

  useEffect(connect, [onPhoneVerificationOpen, toast, has2FAMutation])

  if (allowedGames.length === 0 || !user) return null

  if (selectedGame && !isOpenlootAdmin && !hasGamePermission(user, selectedGame.slug, 'read'))
    return <PermissionDenied />

  return (
    <RequireFeature feature="partnerPortal">
      <>
        <Head>
          <title key="title">{formatHtmlTitle('Partner Portal')}</title>
        </Head>

        {has2FA && (
          <Flex h="100vh" maxW="100%" flexDir="column">
            <PartnerPortalNavbar user={user} />
            <Flex flex={1}>
              <PartnerPortalSidebar />

              <Box flex={1} py={3} px={6} maxW="100.25rem" overflow="hidden">
                {children}
              </Box>
            </Flex>
          </Flex>
        )}
        {user && (
          <TwoFactorModal
            isOpen={isPhoneVerificationOpen}
            onClose={onClose}
            user={user}
            onVerified={onComplete}
            {...getAvailableFlowsFromUser(user)}
            skipOnCloseWhenSucceed
          />
        )}
      </>
    </RequireFeature>
  )
}

function PartnerPortalLayoutWrapper({ children }: PartnerPortalLayoutProps) {
  return (
    <PartnerPortalProvider>
      <PartnerPortalLayout>{children}</PartnerPortalLayout>
    </PartnerPortalProvider>
  )
}

export { PartnerPortalLayoutWrapper as PartnerPortalLayout }
