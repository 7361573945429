import { useEffect, useState } from 'react'
import { Flow, TwoFactorModalProps } from './types'
import { FlowSelector } from './FlowSelector'
import { AppVerificationModal } from './AppVerificationModal'
import { PhoneTwoFactorModal } from './PhoneTwoFactorModal'
import { EnterRecoveryCodeModal } from './EnterRecoveryCodeModal'
import { RecoveryCodesModal } from './RecoveryCodesModal'

export const TwoFactorModal = (props: TwoFactorModalProps) => {
  const { onClose, isOpen, flow: flowProp } = props
  const [flow, setFlow] = useState<Flow | null>()
  const [recoveryCodes, setRecoveryCodes] = useState<string[] | null>(null)

  useEffect(() => {
    if (isOpen) {
      setFlow(flowProp)
    }
  }, [isOpen, flowProp])

  const onCloseRecoveryCodes = () => {
    setRecoveryCodes(null)
    props.onVerified?.()
    onClose()
  }

  let modalContent: JSX.Element

  if (recoveryCodes) {
    modalContent = (
      <RecoveryCodesModal
        recoveryCodes={recoveryCodes}
        onContinue={onCloseRecoveryCodes}
        isOpen={isOpen}
      />
    )
  } else {
    switch (flow) {
      case 'phone':
        modalContent = (
          <PhoneTwoFactorModal {...props} setFlow={setFlow} setRecoveryCodes={setRecoveryCodes} />
        )
        break
      case 'app':
        modalContent = (
          <AppVerificationModal {...props} setFlow={setFlow} setRecoveryCodes={setRecoveryCodes} />
        )
        break
      case 'recovery':
        modalContent = <EnterRecoveryCodeModal {...props} setFlow={setFlow} />
        break
      default:
        modalContent = <FlowSelector setFlow={setFlow} {...props} />
        break
    }
  }

  return modalContent
}
